import React from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { green, red } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import { Theme } from '../../../theme';
import Typography from '@material-ui/core/Typography';

interface ActionFeedbackProps {
  visible: boolean;
  onClose: () => void;
  message: string;
  type: 'success' | 'error';
}

const useStyles = makeStyles((theme: Theme) => ({
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: theme.palette.error.main
  },
  snackbarIcon: {
    fontSize: 30,
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  messageWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  message: {
    color: theme.palette.common.white
  }
}));

const ActionFeedback: React.FC<ActionFeedbackProps> = ({
  visible = false,
  onClose,
  message,
  type
}) => {
  const classes = useStyles();
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={visible}
      onClose={onClose}
    >
      <SnackbarContent
        className={classes[type]}
        aria-describedby={type}
        message={
          <div id={type} className={classes.messageWrapper}>
            {type === 'success' && (
              <CheckCircleIcon className={classes.snackbarIcon} />
            )}
            {type === 'error' && <ErrorIcon className={classes.snackbarIcon} />}
            <Typography variant="body1" className={classes.message}>
              {message}
            </Typography>
          </div>
        }
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={onClose}
          >
            <CloseIcon className={classes.snackbarIcon} />
          </IconButton>
        ]}
      />
    </Snackbar>
  );
};

export default ActionFeedback;
