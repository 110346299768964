import React, { useState } from 'react';
import Fab from '@material-ui/core/Fab';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '../../theme';
import AddIcon from '@material-ui/icons/Add';
import ActionFeedback from '../../components/patterns/ActionFeedback';
import ActionDrawer from '../../components/patterns/ActionDrawer';
import ProductCreate from './ProductCreate';
import ProductList from './ProductList';
import ProductEdit from './ProductEdit';

const useStyles = makeStyles((theme: Theme) => ({
  fab: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  }
}));

const ProductManager: React.FC = () => {
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [drawerContent, setDrawerContent] = useState<'create' | 'edit'>();
  const [activeProduct, setActiveProduct] = useState<string | undefined>(
    undefined
  );
  const [success, setSuccess] = useState<boolean>(false);
  const [refreshData, setRefreshData] = useState<boolean>(true);
  const classes = useStyles();

  const toggleDrawer = (content?: 'create' | 'edit') => {
    setDrawerOpen(!drawerOpen);
    setDrawerContent(content);
  };
  const onCloseActionSuccess = () => setSuccess(false);
  const editProduct = (productUuid: string) => {
    setDrawerOpen(true);
    setDrawerContent('edit');
    setActiveProduct(productUuid);
  };

  const onSuccess = async () => {
    setDrawerOpen(false);
    setSuccess(true);
    setRefreshData(true);
  };

  return (
    <>
      <ProductList
        refreshData={refreshData}
        setRefreshData={setRefreshData}
        editAction={editProduct}
      />
      <Fab
        className={classes.fab}
        color="secondary"
        onClick={() => toggleDrawer('create')}
      >
        <AddIcon />
      </Fab>
      <ActionDrawer open={drawerOpen} onClose={toggleDrawer} width={470}>
        {drawerContent === 'create' && <ProductCreate onSuccess={onSuccess} />}
        {drawerContent === 'edit' && (
          <ProductEdit onSuccess={onSuccess} productUuid={activeProduct!} />
        )}
      </ActionDrawer>
      <ActionFeedback
        message={
          drawerContent === 'create'
            ? 'Das Produkt erfolgreich angelegt'
            : 'Das Produkt wurde erfolgreich bearbeitet'
        }
        visible={success}
        onClose={onCloseActionSuccess}
        type="success"
      />
    </>
  );
};

export default ProductManager;
