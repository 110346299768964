import React, { useState } from 'react';
import {
  Button,
  Typography,
  TextField,
  Select,
  FormControlLabel,
  Switch,
  FormControl,
  InputLabel
} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import { Product, ProductType } from '../../../types';

export interface FormData {
  name: string;
  ean: string;
  type: string;
  price: number;
  price_nominale: number;
  logo: string;
  tax_rate: number;
  active: boolean;
}

export interface FormErrors extends FormData {
  common?: string;
}

export interface FormProps {
  onSubmit: (data: FormData) => void;
  product: Product;
  isLoading: boolean;
}

export const ProductEditForm: React.FC<FormProps> = ({
  onSubmit,
  product,
  isLoading
}) => {
  const [data, setData] = useState<FormData>({
    name: product.name,
    ean: product.ean,
    type: product.type,
    price: product.price,
    price_nominale: product.price_nominale,
    logo: product.logo,
    tax_rate: product.tax_rate,
    active: product.active
  });

  const handleSubmit = (event: any) => {
    event.preventDefault();
    onSubmit(data);
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormControlLabel
        control={
          <Switch
            checked={data.active}
            onChange={() => setData({ ...data, active: !data.active })}
            name="active"
            color="primary"
          />
        }
        label="Aktiv"
      />
      <TextField
        required
        id="name"
        label="Produktname"
        defaultValue={data.name}
        variant="outlined"
        fullWidth
        margin="normal"
        onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
          setData({ ...data, name: event.target.value as string })
        }
      />
      <TextField
        id="ean"
        label="EAN"
        defaultValue={data.ean}
        variant="outlined"
        fullWidth
        margin="normal"
        onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
          setData({ ...data, ean: event.target.value as string })
        }
      />
      <TextField
        id="logo"
        label="Logo URL"
        defaultValue={data.logo}
        variant="outlined"
        fullWidth
        margin="normal"
        onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
          setData({ ...data, logo: event.target.value as string })
        }
      />
      <TextField
        id="price_nominale"
        label="Nominale"
        defaultValue={String(data.price_nominale / 100).replace('.', ',')}
        variant="outlined"
        fullWidth
        margin="normal"
        onChange={(event: React.ChangeEvent<{ value: string }>) =>
          setData({
            ...data,
            price_nominale: Math.round(
              Number(event.target.value.replace(',', '.')) * 100
            )
          })
        }
      />
      <TextField
        id="price"
        label="EK Preis Händler"
        defaultValue={String(data.price / 100).replace('.', ',')}
        variant="outlined"
        fullWidth
        margin="normal"
        onChange={(event: React.ChangeEvent<{ value: string }>) =>
          setData({
            ...data,
            price: Math.round(
              Number(event.target.value.replace(',', '.')) * 100
            )
          })
        }
      />
      <FormControl variant="outlined" fullWidth margin="normal">
        <InputLabel id="type_label">Produkttype</InputLabel>
        <Select
          name="type"
          fullWidth
          id="type"
          value={data.type}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
            setData({ ...data, type: event.target.value as string })
          }
        >
          <MenuItem key={ProductType.SIMCARD} value={ProductType.SIMCARD}>
            Simkarte
          </MenuItem>
          <MenuItem key={ProductType.VOUCHER} value={ProductType.VOUCHER}>
            Voucher
          </MenuItem>
          <MenuItem key={ProductType.RISKFREE} value={ProductType.RISKFREE}>
            Riskfree-Simkarte
          </MenuItem>
        </Select>
      </FormControl>
      <FormControl variant="outlined" fullWidth margin="normal">
        <InputLabel id="tax_rate_label">Steuersatz</InputLabel>
        <Select name="tax_rate" fullWidth id="tax_rate" value={data.tax_rate}>
          <MenuItem value={'0'}>0%</MenuItem>
          <MenuItem value={'10'}>10%</MenuItem>
          <MenuItem value={'20'}>20%</MenuItem>
        </Select>
      </FormControl>
      <Button
        variant="contained"
        color="primary"
        type="submit"
        fullWidth
        disabled={isLoading}
      >
        Speichern
      </Button>
    </form>
  );
};
