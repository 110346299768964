import React, { useState, useEffect } from 'react';
import { IconButton } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import {
  FormattedDate,
  Currency,
  DataTable
} from '../../components/primitives';
import { CreditNote, Customer } from '../../types';
import {
  server,
  defaultErrorMessage,
  stringifyQuery
} from '../../utils/server';
import { Loader } from '../../components/common/Loader';
import { CloudDownload as CloudDownloadIcon } from '@material-ui/icons';

interface FetchDataOptions {
  filter?: CreditNotesListFilter;
}

interface CreditNotesListProps {
  isAdmin: boolean;
}

interface CreditNotesListFilter {
  customerId?: string;
}

interface CreditNotesListResult {
  total: number;
  rows: Array<CreditNote>;
}

export const CreditNotesList: React.FC<CreditNotesListProps> = ({
  isAdmin
}) => {
  const [data, setData] = useState<Array<CreditNote>>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [page, setPage] = useState<number>(0);
  const [filter, setFilter] = useState<CreditNotesListFilter>({});
  const [count, setCount] = useState<number>(0);
  const [loadingError, setLoadingError] = useState<string | undefined>(
    undefined
  );
  const [customers, setCustomers] = useState<Array<Customer>>([]);

  if (isAdmin) {
    useEffect(() => {
      fetchCustomers().then(setCustomers);
    }, []);
    const fetchCustomers = async (): Promise<Array<Customer>> => {
      setLoading(true);
      try {
        const res = await server.get('/customers?limit=1000');
        console.log({ data: res.data });
        setLoading(false);
        return res.data.rows;
      } catch (error) {
        setLoading(false);
        return [];
      }
    };
  }

  useEffect(() => {
    fetchData({ filter }).then(data => {
      setData(data.rows);
      setCount(data.total);
    });
  }, [page, filter]);

  const fetchData = async ({
    filter = {}
  }: FetchDataOptions): Promise<CreditNotesListResult> => {
    setLoading(true);
    try {
      const queryString = stringifyQuery(filter);
      const res = await server.get(
        `/creditnotes?page=${queryString ? 0 : page}${queryString &&
          '&' + queryString}`
      );
      setLoading(false);
      console.log(res.data);
      return res.data;
    } catch (error) {
      setLoading(false);
      setLoadingError(defaultErrorMessage);
      return { total: 0, rows: [] };
    }
  };

  const handleFilterSubmit = (applyFilters: () => Array<any>): void => {
    const filterList = applyFilters();
    const filter: CreditNotesListFilter = {};
    if (filterList[1].length) {
      const customer = customers.find(
        customer => customer.business_name === filterList[1][0]
      );
      if (customer) filter.customerId = customer.uuid;
    }
    setFilter(filter);
    setPage(0);
  };

  const _base64ToArrayBuffer = (base64: string) => {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  };

  const downloadAction = async (id: number) => {
    const { data } = await server.get(`/creditnotes/${id}/download`);

    console.log('content', data.content); // here at console if i copy the code and use online tool(https://base64.guru/converter/decode/pdf) it shows the correct pdf
    const blob = new Blob([_base64ToArrayBuffer(data.content)], {
      type: 'application/pdf'
    });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = data.filename;
    link.click();

    /*
    const invoice = await server.get(`/invoices/342/download`)
    console.log(invoice.data)


    const res = await server.get(
      `/creditnotes/${id}/download`,
      {
        responseType: 'blob'
      }
    );
    setLoading(false);
    const url = window.URL.createObjectURL(res.data);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `creditnote_${id}.pdf`);
    document.body.appendChild(link);
    link.click();
    */
  };

  const getColumns = (customers: Array<Customer>) => [
    {
      name: 'date',
      label: 'Erstellt am',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'customer',
      label: 'Kunde',
      options: {
        filter: isAdmin,
        filterType: 'dropdown' as any,
        filterOptions: {
          names: customers.map(c => c.business_name),
          fullWidth: true
        },
        customFilterListOptions: { render: (v: string) => `Kunde: ${v}` },
        sort: true
      }
    },
    {
      name: 'title',
      label: 'Titel',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'amount',
      label: 'Betrag',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'action',
      label: '',
      options: {
        filter: false,
        sort: true
      }
    }
  ];

  const prepareRows = (rows: Array<CreditNote>): Array<Array<any>> => {
    const optimizedRows = rows.map(row => ({
      date: <FormattedDate date={row.created_at} wrapped={false} />,
      customer: row.customer ? row.customer.business_name : '-',
      title: row.external_number,
      amount: <Currency value={row.total_price} wrapped={false} />,
      action: (
        <IconButton
          size="small"
          aria-label="download"
          color="secondary"
          onClick={() => downloadAction(row.id)}
        >
          <CloudDownloadIcon />
        </IconButton>
      )
    }));
    return optimizedRows.map(row => Object.values(row));
  };

  return (
    <>
      {loadingError && <Alert severity="error">{loadingError}</Alert>}
      {isLoading && <Loader active={isLoading} width={100} />}
      <DataTable
        title={''}
        data={prepareRows(data)}
        columns={getColumns(customers)}
        handleFilterSubmit={handleFilterSubmit}
        filterEnabled={true}
        searchEnabled={false}
        options={{
          page,
          count,
          onChangePage: async (page: number) => {
            setPage(page);
          }
        }}
      />
    </>
  );
};
