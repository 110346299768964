import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';

interface QuantitySelectionProps {
  open: boolean;
  maxQuantity?: number;
  handleSelect: (quantity: number) => void;
  handleClose: () => void;
}

export const QuantitySelection: React.FC<QuantitySelectionProps> = ({
  open,
  maxQuantity = 5,
  handleSelect,
  handleClose
}) => {
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">Menge auswählen</DialogTitle>
      <List>
        {[...Array(maxQuantity)].map((x, i) => (
          <ListItem button onClick={() => handleSelect(i + 1)} key={i}>
            <ListItemText primary={i + 1} />
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
};
