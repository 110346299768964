import React, { useState } from 'react';
import {
  ResetPasswordConfirmForm,
  ResetPasswordConfirmParameter
} from './components/ResetPasswordConfirmForm';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';
import { Alert, AlertType } from '../../components/primitives/Alert';
import { server } from '../../utils/server';
import { Loader } from '../../components/common/Loader';
import { Button, Typography } from '@material-ui/core';

export const ResetPasswordConfirm: React.FC<
  RouteComponentProps<{ uuid: string }>
> = ({ history, match }) => {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [success, setSuccess] = useState(false);
  const { uuid } = match.params;

  const LoginLink = (props: any) => <Link to="/" {...props} />;

  const isValidPassword = (password: string): boolean => {
    if (password.length >= 8) {
      return true;
    }
    return false;
  };

  const validateInput = ({
    password,
    passwordConfirmation
  }: ResetPasswordConfirmParameter) => {
    if (
      !isValidPassword(password) ||
      !isValidPassword(passwordConfirmation) ||
      password !== passwordConfirmation
    ) {
      setError(
        'Du musst zweimal das gleiche valide Passwort (mindestens 8 Zeichen) eingeben.'
      );
      setLoading(false);
      return false;
    }
    return true;
  };

  const onSubmit = async ({
    password,
    passwordConfirmation
  }: ResetPasswordConfirmParameter) => {
    setLoading(true);
    setSuccess(false);
    setError(undefined);

    if (validateInput({ password, passwordConfirmation })) {
      try {
        const res = await server.put(`/reset/${uuid}`, { password });
        console.log(res);
        setLoading(false);
        setSuccess(true);
        return;
      } catch (error) {
        setLoading(false);
        setError(error.common);
        return;
      }
    }
  };

  return (
    <>
      {error && (
        <Alert type={AlertType.ERROR}>
          <Typography variant="body2" color="inherit">
            {error}
          </Typography>
        </Alert>
      )}
      {success ? (
        <>
          <Alert
            type={AlertType.SUCCESS}
            headline="Passwort erfolgreich geändert"
          >
            <Typography variant="body2" color="inherit">
              Du kannst dich jetzt mit deinem neuen Passwort anmelden.
            </Typography>
          </Alert>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            component={LoginLink}
          >
            Jetzt anmelden
          </Button>
        </>
      ) : (
        <>
          <ResetPasswordConfirmForm onSubmit={onSubmit} loading={isLoading} />
          <Button color="primary" component={LoginLink}>
            Zurück zum Login
          </Button>
          <Loader active={isLoading} />
        </>
      )}
    </>
  );
};

export default withRouter(ResetPasswordConfirm);
