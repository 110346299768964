import React, { useState } from 'react';
import { server } from '../../utils/server';
import { Typography } from '@material-ui/core';
import { Loader } from '../../components/common/Loader';
import {
  EmployeeForm,
  EmployeeFormErrors,
  FormData
} from './components/EmployeeForm';

interface EmployeeCreateProps {
  onSuccess: () => void;
}

const EmployeeCreate: React.FC<EmployeeCreateProps> = ({ onSuccess }) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<EmployeeFormErrors | undefined>(undefined);

  const validateInput = (data: FormData) => {
    const errors: EmployeeFormErrors = {};
    const requiredFields = ['email', 'firstname', 'lastname'];
    for (const field of requiredFields) {
      // @ts-ignore
      if (data[field] === '') {
        // @ts-ignore
        errors[field] = 'Dieses Feld ist ein Pflichtfeld !';
      }
    }
    if (Object.keys(errors).length === 0) {
      return true;
    }
    setLoading(false);
    setError(errors);
    return false;
  };

  const onSubmit = async (data: FormData) => {
    setLoading(true);
    if (validateInput(data)) {
      console.log('input valid');
      try {
        const res = await server.post('/users', { ...data, role: 'employee' });
        console.log(res);
        setLoading(false);
        onSuccess();
      } catch (error) {
        setLoading(false);
        setError(error);
      }
    }
  };

  return (
    <>
      <Typography variant="h3">Mitarbeiter anlegen</Typography>
      {isLoading ? (
        <Loader active={isLoading} width={100} />
      ) : (
        <>
          <Typography paragraph>
            Beim Anlegen eines Mitarbeiters wird automatisch eine E-Mail mit
            einer Anleitung zum Setzen des Passwortes versendet.
          </Typography>
          <EmployeeForm
            onSubmit={onSubmit}
            loading={isLoading}
            errors={error}
          />
        </>
      )}
    </>
  );
};

export default EmployeeCreate;
