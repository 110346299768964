import React, { useState, useEffect } from 'react';
import { Topup, TopupState } from './types';
import { Table, TableCell } from '../../components/primitives/Table';
import { TableRow, IconButton } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { TableLoader } from '../../components/primitives/Loader';
import { server } from '../../utils/server';
import { FormattedDate, Currency } from '../../components/primitives';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '../../theme';
import {
  Section,
  SectionHeader,
  SectionContent,
  SectionFooter
} from '../../components/primitives/Section';
import { grey } from '@material-ui/core/colors';
import { Chip } from '../../components/primitives/Chip';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

interface TopupListProps {
  handleSelectTopup: (topup: Topup) => void;
  refreshData: boolean;
  setRefreshData: (status: boolean) => void;
  selectedTopup?: Topup;
  selectTopup: (topup: Topup) => void;
  setError: (error: string) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  selectedRow: {
    backgroundColor: grey[100],
    fontWeight: 600
  },
  selectableRow: {
    cursor: 'pointer'
  }
}));

const TopupList: React.FC<TopupListProps> = ({
  refreshData,
  setRefreshData,
  setError,
  selectTopup,
  selectedTopup
}) => {
  const [topups, setTopups] = useState<Array<Topup>>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const classes = useStyles();

  useEffect(() => {
    if (refreshData) {
      setRefreshData(false);
      getTopups().then(topups => {
        setTopups(topups);
        selectTopup(topups[0]);
      });
    }
  }, [[refreshData]]);

  const getTopups = async (): Promise<Array<Topup>> => {
    setLoading(true);
    try {
      const res = await server.get('/topups');
      console.log({ res });
      setLoading(false);
      return res.data;
    } catch (error) {
      console.log({ error });
      setLoading(false);
      setError(error);
      return [];
    }
  };

  const renderRow = (row: Topup) => {
    const rowClassnames = classNames(
      classes.selectableRow,
      selectedTopup && row.uuid === selectedTopup.uuid && classes.selectedRow
    );
    return (
      <TableRow
        key={row.uuid}
        className={rowClassnames}
        onClick={() => selectTopup(row)}
      >
        <TableCell>
          <FormattedDate wrapped={false} showTime={true} date={row.date} />
        </TableCell>
        <TableCell>
          <Currency
            wrapped={false}
            value={row.transactions.reduce(
              (sum, transaction) => sum + transaction.amount,
              0
            )}
          />
        </TableCell>
        <TableCell>
          {row.state === TopupState.ABORTED && (
            <Chip label="abgebrochen" type="error" />
          )}
          {row.state === TopupState.APPROVED && (
            <Chip label="bestätigt" type="success" />
          )}
          {row.state === TopupState.PENDING && (
            <Chip label="nicht freigegeben" type="warning" />
          )}
          {row.state === TopupState.IN_PROGRESS && (
            <Chip label="wird erstellt" type="info" />
          )}
        </TableCell>
        <TableCell align="right">
          <IconButton
            size="small"
            aria-label="bearbeiten"
            color="secondary"
            component={Link}
            to={`/topups/${row.uuid}`}
          >
            <InfoIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <>
      <Section>
        <SectionContent noPadding={true}>
          {isLoading ? (
            <TableLoader />
          ) : (
            <Table
              headers={[
                { label: 'Datum' },
                { label: 'Provisionen' },
                { label: 'Status' },
                { label: '' }
              ]}
              rows={topups}
              // @ts-ignore
              renderRow={renderRow}
              noBorder={true}
            />
          )}
        </SectionContent>
      </Section>
    </>
  );
};

export default TopupList;
