import React, { useState, useEffect } from 'react';
import {
  Typography,
  FormControl,
  InputAdornment,
  MenuItem,
  TextField
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import { Loader } from '../../../components/common/Loader';
import { server } from '../../../utils/server';
import Button from '../../../components/primitives/Button';
import { Product } from '../../../types';

interface SimcardImportProps {
  onSuccess: () => void;
}

interface FormData {
  product: string;
  imeiFrom: string;
  imeiUntil: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1)
      }
    },
    btn: {
      margin: theme.spacing(1),
      width: '100%'
    }
  })
);

export const SimcardImport: React.FC<SimcardImportProps> = ({ onSuccess }) => {
  const [error, setError] = useState<string | undefined>(undefined);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [products, setProducts] = useState<Array<Product>>([]);
  const [formData, setFormData] = useState<FormData>({
    product: '',
    imeiFrom: '',
    imeiUntil: ''
  });
  const classes = useStyles();

  useEffect(() => {
    fetchProducts().then(products => setProducts(products));
  }, []);
  const fetchProducts = async (): Promise<Array<Product>> => {
    setLoading(true);
    try {
      const res = await server.get('/products?type=SIMCARD');
      setLoading(false);
      return res.data;
    } catch (error) {
      setLoading(false);
      return [];
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setError(undefined);

    if (!formData.product || !formData.imeiFrom || !formData.imeiUntil) {
      setError('Du musst ein Produkt auswählen');
      return;
    }

    setLoading(true);

    try {
      const res = await server.post(
        '/stock/simcards',
        {
          ...formData,
          imeiFrom: `894310${formData.imeiFrom}`,
          imeiUntil: `894310${formData.imeiUntil}`
        },
        {
          timeout: 600000
        }
      );
      setLoading(false);
      console.log({ res });
      onSuccess();
    } catch (error) {
      setLoading(false);
      setError(error.common);
    }
  };

  const handleProductSelect = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setFormData({ ...formData, product: event.target.value as string });
  };

  return (
    <>
      <Typography variant="h3">Simkarten importieren</Typography>
      {error && <Alert severity="error">{error}</Alert>}
      {isLoading ? (
        <Loader active={isLoading} width={100} />
      ) : (
        <form onSubmit={handleSubmit} className={classes.root}>
          <FormControl />
          <TextField
            id="product-select"
            select
            label="Produkt auswählen"
            value={formData.product}
            onChange={handleProductSelect}
            variant="outlined"
            fullWidth
          >
            {products.map(product => (
              <MenuItem key={product.uuid} value={product.uuid}>
                {product.name}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            variant="outlined"
            name="imeiFrom"
            type="text"
            label="IMEI Start"
            defaultValue={formData.imeiFrom}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">894310</InputAdornment>
              )
            }}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
              setFormData({
                ...formData,
                imeiFrom: event.target.value as string
              })
            }
            required
            fullWidth
          />
          <TextField
            variant="outlined"
            name="imeiUntil"
            type="text"
            label="IMEI Ende"
            defaultValue={formData.imeiUntil}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">894310</InputAdornment>
              )
            }}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
              setFormData({
                ...formData,
                imeiUntil: event.target.value as string
              })
            }
            required
            fullWidth
          />
          <Button
            variant="outlined"
            color="primary"
            type="submit"
            className={classes.btn}
          >
            Simkarten importieren
          </Button>
        </form>
      )}
    </>
  );
};
