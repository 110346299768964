import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

interface PageHeaderProps {
  headline: string;
  subHeadline?: string;
}

const useStyles = makeStyles(() => ({
  root: {
    paddingBottom: 20,
    marginBottom: 40,
    borderBottomStyle: 'solid',
    borderBottomColor: '#ccc',
    borderBottomWidth: 1
  }
}));

export const PageHeader: React.FC<PageHeaderProps> = ({
  headline,
  subHeadline
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography variant="h1">{headline}</Typography>
      {subHeadline && <Typography variant="h3">{subHeadline}</Typography>}
    </div>
  );
};
