import React, { useState } from 'react';
import { server } from '../../utils/server';
import { Typography } from '@material-ui/core';
import { Loader } from '../../components/common/Loader';
import { CustomerForm, FormErrors, FormData } from './components/CustomerForm';
import { sanitize } from '../../utils/helper';

interface CustomersCreateProps {
  onSuccess: () => void;
}

const CustomersCreate: React.FC<CustomersCreateProps> = ({ onSuccess }) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<FormErrors | undefined>(undefined);
  const [formData, setFormData] = useState<FormData>({
    business_name: '',
    uid: '',
    address_street: '',
    address_zip: '',
    address_city: '',
    contact_email: '',
    contact_phone: '',
    contact_firstname: '',
    contact_lastname: ''
  });

  const onSubmit = async (data: FormData) => {
    setLoading(true);
    try {
      const res = await server.post('/customers', {
        // @ts-ignore
        ...sanitize(data),
        origin: 'ONLINESIM'
      });
      setLoading(false);
      onSuccess();
    } catch (error) {
      setLoading(false);
      setFormData(data);
      setError(error);
    }
  };

  return (
    <>
      <Typography variant="h3">Kunden anlegen</Typography>
      {isLoading && <Loader active={isLoading} width={100} />}
      <>
        <CustomerForm
          onSubmit={onSubmit}
          loading={isLoading}
          errors={error}
          data={formData}
        />
      </>
    </>
  );
};

export default CustomersCreate;
