import React, { useState, useEffect } from 'react';
import { server, defaultErrorMessage } from '../../utils/server';
import { Typography } from '@material-ui/core';
import { Loader } from '../../components/common/Loader';
import { Alert, AlertType } from '../../components/primitives/Alert';
import Fab from '@material-ui/core/Fab';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '../../theme';
import AddIcon from '@material-ui/icons/Add';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ActionFeedback from '../../components/patterns/ActionFeedback';
import ActionDrawer from '../../components/patterns/ActionDrawer';
import EmployeeCreate from './EmployeeCreate';
import { Table, TableCell } from '../../components/primitives/Table';
import TableRow from '@material-ui/core/TableRow';
import Chip from '@material-ui/core/Chip';

interface Employee {
  uuid: string;
  firstname: string;
  lastname: string;
  email: string;
  active: boolean;
  last_auth?: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  fab: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  }
}));

const EmployeeList: React.FC = () => {
  const [data, setData] = useState<Array<Employee>>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [loadingError, setLoadingError] = useState<string | undefined>(
    undefined
  );
  const [actionDrawerOpen, setActionDrawerOpen] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const classes = useStyles();

  useEffect(() => {
    fetchData().then(data => setData(data));
  }, []);

  const fetchData = async (): Promise<Array<Employee>> => {
    setLoading(true);
    try {
      const res = await server.get('/users', {
        params: {
          role: 'employee'
        }
      });
      console.log(res);
      setLoading(false);
      return res.data;
    } catch (error) {
      setLoading(false);
      setLoadingError(defaultErrorMessage);
      return [];
    }
  };

  const toggleActionDrawer = () => {
    if (actionDrawerOpen) {
      return setActionDrawerOpen(false);
    }
    return setActionDrawerOpen(true);
  };

  const onActionSuccess = async () => {
    setActionDrawerOpen(false);
    setSuccess(true);
    setData(await fetchData());
  };

  const onCloseActionSuccess = () => {
    setSuccess(false);
  };

  const renderRow = (row: Employee) => {
    return (
      <TableRow key={row.uuid}>
        <TableCell component="th" scope="row">
          {row.firstname} {row.lastname}
        </TableCell>
        <TableCell>{row.email}</TableCell>
        <TableCell>
          {!row.active ? (
            <Chip
              icon={<ErrorIcon />}
              label="Noch nicht verifziert"
              color="secondary"
            />
          ) : (
            <Chip
              icon={<CheckCircleIcon />}
              label="verifziert"
              color="primary"
            />
          )}
        </TableCell>
        <TableCell>{row.last_auth && <span>{row.last_auth}</span>}</TableCell>
      </TableRow>
    );
  };

  return (
    <>
      <Typography variant="h2" gutterBottom>
        Mitarbeiter
      </Typography>
      {loadingError && (
        <Alert type={AlertType.ERROR} headline="Unerwarteter Fehler">
          <Typography paragraph color="inherit">
            {loadingError}
          </Typography>
        </Alert>
      )}
      {isLoading ? (
        <Loader active={isLoading} width={100} />
      ) : (
        <>
          <Table
            headers={[
              { label: 'Name' },
              { label: 'E-Mail' },
              { label: 'Status' },
              { label: 'letzer Login', align: 'right' }
            ]}
            rows={data}
            // @ts-ignore
            renderRow={renderRow}
          />
          <Fab
            className={classes.fab}
            color="primary"
            onClick={toggleActionDrawer}
          >
            <AddIcon />
          </Fab>
          <ActionDrawer open={actionDrawerOpen} onClose={toggleActionDrawer}>
            <EmployeeCreate onSuccess={onActionSuccess} />
          </ActionDrawer>
          <ActionFeedback
            message="Mitarbeiter erfolgreich angelegt"
            visible={success}
            onClose={onCloseActionSuccess}
            type="success"
          />
        </>
      )}
    </>
  );
};

export default EmployeeList;
