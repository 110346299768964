import React, { useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  Product,
  RiskfreeTransactionState,
  RiskfreeTransactionType
} from '../../types';
import { server } from '../../utils/server';
import { Typography, InputAdornment, TextField } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Loader } from '../../components/common/Loader';
import Button from '../../components/primitives/Button';
import { Currency } from '../../components/primitives';

interface ActivateRiskfreeProps {
  onSuccess: () => void;
  selectedProduct: Product;
}

interface FormData {
  serial: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1)
      }
    },
    btn: {
      margin: theme.spacing(1),
      width: '100%'
    }
  })
);

export const ActivateRiskfree: React.FC<ActivateRiskfreeProps> = ({
  onSuccess,
  selectedProduct
}) => {
  const [error, setError] = useState<string | undefined>(undefined);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<FormData>({ serial: '' });
  const classes = useStyles();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setError(undefined);

    if (!formData.serial) {
      setError('Du musst eine Seriennummer angeben');
    }

    setLoading(true);

    try {
      const res = await server.post('/riskfree-transactions', {
        serial: `894310${formData.serial}`,
        onlinesim_product_id: selectedProduct.uuid,
        type: RiskfreeTransactionType.ACTIVATION
      });
      setLoading(false);
      const { data } = res;
      if (data.state === RiskfreeTransactionState.FAILED) {
        setError(data.error);
      } else {
        onSuccess();
      }
    } catch (error) {
      setLoading(false);
      // @ts-ignore
      setError(error.common);
    }
  };

  return (
    <>
      <Typography variant="h3">Riskfree-Simkarte aktivieren</Typography>
      <Typography variant="body1" style={{ marginTop: 20 }}>
        Um eine Riskfree Simkarte des Produktes
        <b>{selectedProduct.name}</b> zu aktivieren, gib bitte die IMEI Nummer
        deiner Simkarte ein. wieder stornieren willst ?
      </Typography>
      <Typography variant="body1" style={{ marginTop: 20 }}>
        Nach der Aktivierung wird{' '}
        <b>
          <Currency value={selectedProduct.price_nominale} wrapped={false} />
        </b>{' '}
        von deinem Guthaben abgezogen.
      </Typography>
      {error && <Alert severity="error">{error}</Alert>}
      {isLoading ? (
        <Loader active={isLoading} width={100} />
      ) : (
        <form onSubmit={handleSubmit} className={classes.root}>
          <div style={{ marginTop: 20 }}>
            <TextField
              variant="outlined"
              name="imei"
              type="text"
              label="IMEI"
              defaultValue={formData.serial}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">894310</InputAdornment>
                )
              }}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                setFormData({
                  ...formData,
                  serial: event.target.value as string
                })
              }
              required
              fullWidth
            />
            <Button
              variant="outlined"
              color="primary"
              type="submit"
              className={classes.btn}
            >
              Aktivieren
            </Button>
          </div>
        </form>
      )}
    </>
  );
};
