import React from 'react';
import { Money } from '@material-ui/icons';
import { AppConsumer } from '../AppContext';
import { AuthConsumer } from '../auth/AuthContext';
import { UserRole } from '../auth/types';
import { Chip, Currency } from '../../components/primitives';

export const CurrentBalance: React.FC = () => {
  return (
    <AuthConsumer>
      {({ user, isFetching }) => (
        <AppConsumer>
          {({ balance, isFetching: isAppContextFetching }) =>
            !isFetching &&
            !isAppContextFetching &&
            user.role === UserRole.CUSTOMER &&
            balance && (
              <Chip
                style={{ backgroundColor: '#0F254B' }}
                type={balance.amount < 0 ? 'error' : 'success'}
                label={<Currency value={balance.amount} wrapped={false} />}
              />
            )
          }
        </AppConsumer>
      )}
    </AuthConsumer>
  );
};
