import React from 'react';
import MUIDataTable, {
  MUIDataTableColumn,
  MUIDataTableOptions
} from 'mui-datatables';
import { Button } from '@material-ui/core';

interface DataTableProps {
  columns: Array<MUIDataTableColumn>;
  title?: string;
  data: (object | string[] | number[])[];
  searchText?: string;
  onSearchChange?: (term: string) => void;
  handleFilterSubmit?: (applyFilters: () => Array<any>) => void;
  searchEnabled?: boolean;
  filterEnabled?: boolean;
  options?: MUIDataTableOptions;
}

export const DataTable: React.FC<DataTableProps> = ({
  data,
  columns,
  title,
  options,
  searchText,
  onSearchChange,
  handleFilterSubmit,
  filterEnabled = false,
  searchEnabled = false
}) => {
  return (
    <>
      <MUIDataTable
        title={title}
        data={data}
        columns={columns}
        options={{
          ...options,
          selectableRows: 'none',
          filter: filterEnabled,
          filterType: 'dropdown',
          // @ts-ignore
          confirmFilters: true,
          // @ts-ignore
          customFilterDialogFooter: (newFilters, applyFilters) => {
            return (
              <div style={{ marginTop: '40px' }}>
                <Button
                  variant="outlined"
                  onClick={() =>
                    handleFilterSubmit && handleFilterSubmit(applyFilters)
                  }
                >
                  Filter anwenden
                </Button>
              </div>
            );
          },
          onFilterChange: (column, filterList, type) => {
            if (type === 'chip') {
              var newFilters = () => filterList;
              if (handleFilterSubmit) {
                handleFilterSubmit(newFilters);
              }
            }
          },
          serverSide: true,
          rowsPerPage: 20,
          elevation: 0,
          rowsPerPageOptions: [20, 50, 100],
          print: false,
          download: false,
          search: searchEnabled,
          searchText: searchText,
          searchPlaceholder: 'Suchbegriff eingeben',
          onSearchChange,
          viewColumns: false,
          textLabels: {
            body: {
              noMatch: 'Keine Daten gefunden.',
              toolTip: 'tooltipp'
            },
            filter: {
              all: 'Alle',
              title: 'Filter',
              reset: 'zurücksetzen'
            },
            pagination: {
              next: 'Nächste Seite',
              previous: 'Vorherige Seite',
              rowsPerPage: 'Datensätze pro Seite',
              displayRows: 'von'
            }
          }
        }}
      />
    </>
  );
};
