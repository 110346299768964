import React, { useState, useEffect } from 'react';
import { Typography, IconButton } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import {
  Edit as EditIcon,
  Cancel as CancelIcon,
  Print as PrintIcon
} from '@material-ui/icons';
import {
  server,
  defaultErrorMessage,
  stringifyQuery
} from '../../../utils/server';
import {
  StockTransaction,
  StockTransactionState,
  Product,
  ProductType
} from '../../../types';
import {
  DataTable,
  FormattedDate,
  Chip,
  ChipType
} from '../../../components/primitives';
import { Loader } from '../../../components/common/Loader';

interface StockTransactionListProps {
  isAdmin?: boolean;
  refreshData: boolean;
  setRefreshData: (status: boolean) => void;
  type: ProductType;
  printAction?: (stockTransactionUuid: string) => void;
  requestCancellationAction?: (stockTransactionUuid: string) => void;
  cancelAction?: (stockTransaction: StockTransaction) => void;
  selectedState?: StockTransactionState;
}

interface FetchDataOptions {
  filter?: StockTransactionListFilter;
}

interface StockTransactionListFilter {
  productName?: string;
  serialSearch?: string;
  type?: ProductType;
  state?: StockTransactionState;
}

export const StockTransactionList: React.FC<StockTransactionListProps> = ({
  refreshData,
  setRefreshData,
  isAdmin = false,
  type,
  printAction,
  requestCancellationAction,
  cancelAction,
  selectedState
}) => {
  const [data, setData] = useState<Array<StockTransaction>>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [hpp, setHpp] = useState<number>(20);
  const [loadingError, setLoadingError] = useState<string | undefined>(
    undefined
  );
  const [products, setProducts] = useState<Array<Product>>([]);

  useEffect(() => {
    fetchData({}).then(setData);
  }, []);

  useEffect(() => {
    console.log({ refreshData });
    if (refreshData) {
      setRefreshData(false);
      fetchData({}).then(setData);
    }
  }, [[refreshData]]);

  /*
  const fetchProducts = async (type: ProductType): Promise<Array<Product>> => {
    setLoading(true);
    try {
      const res = await server.get(`/products${type ? '?type=' + type : ''}`);
      setLoading(false);
      return res.data;
    } catch (error) {
      setLoading(false);
      return [];
    }
  };
  */

  const fetchData = async ({
    filter = {}
  }: FetchDataOptions): Promise<Array<StockTransaction>> => {
    setLoading(true);
    try {
      filter.type = type;
      if (selectedState) filter.state = selectedState;
      const queryString = stringifyQuery(filter);
      const res = await server.get(
        `/stock/transactions${queryString && '?' + queryString}`
      );
      setLoading(false);
      return res.data;
    } catch (error) {
      setLoading(false);
      setLoadingError(defaultErrorMessage);
      return [];
    }
  };

  const handleFilterSubmit = (applyFilters: () => Array<any>): void => {
    const filterList = applyFilters();
    const filter: StockTransactionListFilter = {};
    filter.serialSearch = filterList[0].length ? filterList[0][0] : undefined;
    filter.productName = filterList[2].length ? filterList[2][0] : undefined;
    fetchData({ filter }).then(setData);
  };

  const getColumns = (products: Array<Product>) => [
    {
      name: 'date',
      label: 'Gekauft am',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'serial',
      label: type === ProductType.SIMCARD ? 'IMEI' : 'SERIENNUMMER',
      options: {
        filter: true,
        filterType: 'textField' as any,
        filterOptions: {
          fullWidth: true
        },
        sort: true
      }
    },
    {
      name: 'product',
      label: 'Produkt',
      options: {
        filter: true,
        filterType: 'dropdown' as any,
        filterOptions: { names: products.map(p => p.name), fullWidth: true },
        customFilterListOptions: { render: (v: string) => `Produkt: ${v}` },
        sort: true
      }
    },
    {
      name: 'state',
      label: 'Status',
      options: {
        filter: true,
        filterType: 'dropdown' as any,
        filterOptions: {
          names: [
            StockTransactionState.CONFIRMED,
            StockTransactionState.CANCELLED,
            StockTransactionState.PENDING
          ],
          fullWidth: true
        },
        customFilterListOptions: {
          render: (v: StockTransactionState) => `Status: ${v}`
        },
        sort: true
      }
    },
    {
      name: 'action',
      label: 'Aktionen',
      options: {
        filter: false,
        sort: false
      }
    }
  ];

  const prepareRows = (
    rows: Array<StockTransaction>,
    isAdmin: boolean
  ): Array<Array<any>> => {
    const optimizedRows = rows.map(row => ({
      date: <FormattedDate date={row.created_at} wrapped={false} />,
      serial: row.voucher ? row.voucher.serial : row.simcard!.imei,
      product: row.voucher
        ? row.voucher.product.name
        : row.simcard!.product.name,
      state: (
        <Chip
          size="small"
          label={mapTransactionStateToReadable(row.state)}
          type={mapTransactionStateToChipType(row.state)}
        />
      ),
      action:
        type === ProductType.VOUCHER ? (
          <>
            {printAction && row.state === StockTransactionState.CONFIRMED && (
              <IconButton
                size="small"
                aria-label="voucher drucken"
                color="secondary"
                onClick={() => printAction(row.uuid)}
              >
                <PrintIcon />
              </IconButton>
            )}
            {requestCancellationAction &&
              row.state === StockTransactionState.CONFIRMED && (
                <IconButton
                  size="small"
                  aria-label="Stornierung beantragen"
                  color="secondary"
                  onClick={() => requestCancellationAction(row.uuid)}
                >
                  <CancelIcon />
                </IconButton>
              )}
            {isAdmin && cancelAction && (
              <IconButton
                size="small"
                aria-label="Stornierung beantragen"
                color="secondary"
                onClick={() => cancelAction(row)}
              >
                <CancelIcon />
              </IconButton>
            )}
          </>
        ) : (
          <></>
        )
    }));
    return optimizedRows.map(row => Object.values(row));
  };

  const mapTransactionStateToChipType = (
    state: StockTransactionState
  ): ChipType => {
    switch (state) {
      case StockTransactionState.CONFIRMED:
        return 'success';
      case StockTransactionState.CANCELLED:
        return 'error';
      case StockTransactionState.PENDING:
        return 'info';
      case StockTransactionState.CANCELLATION_REQUESTED:
        return 'warning';
      default:
        return 'error';
    }
  };

  const mapTransactionStateToReadable = (
    state: StockTransactionState
  ): string => {
    switch (state) {
      case StockTransactionState.CONFIRMED:
        return 'VERKAUFT';
      case StockTransactionState.CANCELLED:
        return 'STORNIERT';
      case StockTransactionState.CANCELLATION_REQUESTED:
        return 'STORNIERUNG BEANTRAGT';
      case StockTransactionState.PENDING:
        return 'IN BEARBEITUNG';
      default:
        return 'unknown';
    }
  };

  return (
    <>
      {loadingError && <Alert severity="error">{loadingError}</Alert>}
      {isLoading && <Loader active={isLoading} width={100} />}
      <DataTable
        title={''}
        data={prepareRows(data, isAdmin)}
        columns={getColumns(products)}
        handleFilterSubmit={handleFilterSubmit}
        filterEnabled={true}
        searchEnabled={false}
        options={{
          page,
          count: hpp,
          onChangePage: async (page: number) => {
            setPage(page);
          }
        }}
      />
    </>
  );
};
