import React, { useState } from 'react';
import { ShopProvider, ShopConsumer } from './ShopContext';
import { makeStyles } from '@material-ui/styles';
import ActionDrawer from '../../components/patterns/ActionDrawer';
import ActionFeedback from '../../components/patterns/ActionFeedback';
import { Cart } from './components/Cart';
import Fab from '@material-ui/core/Fab';
import CartIcon from '@material-ui/icons/ShoppingBasket';
import { Theme } from '../../theme';
import Badge from '@material-ui/core/Badge';

const useStyles = makeStyles((theme: Theme) => ({
  fab: {
    position: 'absolute',
    top: theme.spacing(10),
    right: theme.spacing(2)
  }
}));

interface ShopWrapperProps {
  showCart?: boolean;
}

const ShopWrapper: React.FC<ShopWrapperProps> = ({
  children,
  showCart = true
}) => {
  const classes = useStyles();

  return (
    <ShopProvider>
      <ShopConsumer>
        {({ cart, feedback, setFeedback, cartOpen, toggleCart }) => (
          <>
            <div>{children}</div>
            {showCart && (
              <>
                <div>
                  <ActionDrawer
                    open={cartOpen}
                    onClose={toggleCart}
                    width={470}
                  >
                    <Cart onClose={toggleCart} />
                  </ActionDrawer>
                </div>
                <Fab
                  className={classes.fab}
                  color="secondary"
                  onClick={() => toggleCart()}
                >
                  <Badge badgeContent={cart.items.size} color="primary">
                    <CartIcon />
                  </Badge>
                </Fab>
              </>
            )}
            <ActionFeedback
              message={feedback!}
              visible={Boolean(feedback)}
              onClose={() => setFeedback(undefined)}
              type="success"
            />
          </>
        )}
      </ShopConsumer>
    </ShopProvider>
  );
};

export default ShopWrapper;
