import { blueGrey } from '@material-ui/core/colors';

export default {
  primary: {
    main: '#1C2D54',
    light: '#CF5E38',
    dark: '#D96C4D',
    contrastText: '#fff'
  },
  secondary: blueGrey,
  text: {
    primary: '#444',
    secondary: '#bbb'
  },
  border: '#DFE3E8',
  divider: '#DFE3E8'
};
