import React from 'react';
import { Typography } from '@material-ui/core';

interface CheckoutDeliveryAddressProps {
  street: string;
  zip: number;
  city: string;
  country?: string;
}

export const CheckoutDeliveryAddress: React.FC = () => {
  return (
    <div>
      <Typography variant="h3">Lieferadresse</Typography>
    </div>
  );
};
