import React, { useState, useEffect } from 'react';
import { Topup, TopupState } from './types';
import {
  Section,
  SectionHeader,
  SectionContent,
  SectionFooter,
  SectionHeaderState
} from '../../components/primitives/Section';
import { FormattedDate, Currency } from '../../components/primitives';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '../../theme';
import Button from '../../components/primitives/Button';
import TopupTransactionList from './TopupTransactionList';
import ContentLoader from 'react-content-loader';
import { Loader } from '../../components/common/Loader';
import { Typography } from '@material-ui/core';
import { server, defaultErrorMessage } from '../../utils/server';
import ApproveDialog from './components/ApproveTopupDialog';
import AbortDialog from './components/AbortTopupDialog';
import ActionFeedback from '../../components/patterns/ActionFeedback';
import { withRouter, RouteComponentProps } from 'react-router-dom';

type PathParamsType = {
  uuid: string;
};

// Your component own properties
type TopupDetailsProps = RouteComponentProps<PathParamsType> & {
  someString: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    margin: theme.spacing(1)
  }
}));

const TopupDetails: React.FC<TopupDetailsProps> = ({ match }) => {
  const classes = useStyles();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [topup, setTopup] = useState<Topup | undefined>(undefined);
  const [error, setError] = useState<string | undefined>(undefined);
  const [success, setSuccess] = useState<boolean>(false);
  const [abortDialogOpen, setAbortDialogOpen] = useState<boolean>(false);
  const [approveDialogOpen, setApproveDialogOpen] = useState<boolean>(false);

  useEffect(() => {
    fetchTopup(match.params.uuid).then(setTopup);
  }, []);

  const handleCloseDialog = () => {
    setAbortDialogOpen(false);
    setApproveDialogOpen(false);
  };

  const onCloseActionSuccess = () => setSuccess(false);
  const onSuccess = async () => setSuccess(true);

  const abortTopup = async () => {
    if (topup) {
      setLoading(true);
      try {
        setLoading(true);
        const res = await server.put(`/topups/${topup.uuid}`, {
          state: 'ABORTED'
        });
        setTopup(res.data);
        setSuccess(true);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setError(error.common || error.message);
      }
      setAbortDialogOpen(false);
    }
  };

  const confirmTopup = async () => {
    if (topup) {
      setLoading(true);
      try {
        setLoading(true);
        const res = await server.put(`/topups/${topup.uuid}`, {
          state: 'APPROVED'
        });
        setTopup(res.data);
        setSuccess(true);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setError(error.common || error.message);
      }
      setApproveDialogOpen(false);
    }
  };

  const fetchTopup = async (uuid: string): Promise<Topup | undefined> => {
    setLoading(true);
    try {
      const res = await server.get(`/topups/${uuid}`);
      setLoading(false);
      return res.data;
    } catch (error) {
      setLoading(false);
      setError(defaultErrorMessage);
      return;
    }
  };

  const renderHeader = (topup: Topup) => {
    const headerState: SectionHeaderState = topup.state.toLowerCase() as SectionHeaderState;
    return <SectionHeader state={headerState} />;
  };

  return topup ? (
    <>
      <Typography variant="h2" gutterBottom>
        Topup Details
      </Typography>
      <Section>
        {renderHeader(topup)}
        {isLoading && <Loader active={isLoading} width={100} />}
        <SectionContent noPadding={true}>
          {topup.state === TopupState.PENDING && (
            <div style={{ borderBottom: '1px solid #eee', padding: 10 }}>
              <Button
                className={classes.button}
                variant="outlined"
                color="primary"
                onClick={() => confirmTopup()}
              >
                <Currency
                  wrapped={false}
                  value={topup.transactions.reduce(
                    (sum, transaction) => sum + transaction.amount,
                    0
                  )}
                />
                Freigeben
              </Button>
              <Button
                className={classes.button}
                variant="outlined"
                color="secondary"
                onClick={() => abortTopup()}
              >
                Abbrechen
              </Button>
            </div>
          )}
          <TopupTransactionList topup={topup} />
        </SectionContent>
      </Section>
      <ApproveDialog
        open={approveDialogOpen}
        handleClose={handleCloseDialog}
        handleAgree={confirmTopup}
        topup={topup}
      />
      <AbortDialog
        open={abortDialogOpen}
        handleClose={handleCloseDialog}
        handleAgree={abortTopup}
        topup={topup}
      />
      <ActionFeedback
        message={`Der Status des Topups wurde erfolgreich bearbeitet`}
        visible={success}
        onClose={onCloseActionSuccess}
        type="success"
      />
    </>
  ) : (
    <>{isLoading && <SkeletonLoader />}</>
  );
};

const SkeletonLoader: React.FC = () => {
  return (
    <Section>
      <SectionContent>
        <ContentLoader ariaLabel="Topup Details werden geladen" height={180}>
          <rect x="0" y="0" rx="4" ry="4" width="400" height="20" />
          <rect x="0" y="30" rx="4" ry="4" width="400" height="60" />
          <rect x="0" y="100" rx="4" ry="4" width="200" height="15" />
          <rect x="210" y="100" rx="4" ry="4" width="100" height="15" />
          <rect x="320" y="100" rx="4" ry="4" width="70" height="15" />
          <rect x="0" y="120" rx="4" ry="4" width="200" height="15" />
          <rect x="210" y="120" rx="4" ry="4" width="100" height="15" />
          <rect x="320" y="120" rx="4" ry="4" width="70" height="15" />
          <rect x="0" y="140" rx="4" ry="4" width="200" height="15" />
          <rect x="210" y="140" rx="4" ry="4" width="100" height="15" />
          <rect x="320" y="140" rx="4" ry="4" width="70" height="15" />
        </ContentLoader>
      </SectionContent>
    </Section>
  );
};

export default withRouter(TopupDetails);
