import React, { useState } from 'react';
import { Typography, Button } from '@material-ui/core';
import { server } from '../../utils/server';
import { CreditNotesList } from './CreditNotesList';
import { Loader } from '../../components/common/Loader';
import ActionFeedback from '../../components/patterns/ActionFeedback';
import ActionDrawer from '../../components/patterns/ActionDrawer';
import { Alert } from '@material-ui/lab';
import { UserRole } from '../auth/types';
import { AuthConsumer } from '../auth/AuthContext';
import CreditNotesExport from './CreditNotesExport';

enum CreditNoteAction {
  EXPORT = 'export'
}

const CreditNotesManager: React.FC = () => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [success, setSuccess] = useState<boolean>(false);
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [drawerContent, setDrawerContent] = useState<CreditNoteAction>();

  const toggleDrawer = (content?: CreditNoteAction) => {
    setDrawerOpen(!drawerOpen);
    setDrawerContent(content);
  };

  const onCloseActionSuccess = () => setSuccess(false);

  const onSuccess = async () => {
    setDrawerOpen(false);
    setSuccess(true);
  };

  const createCreditNotes = async () => {
    try {
      setLoading(true);
      await server.post(`/creditnotes`);
      setSuccess(true);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error.common || error.data.message);
    }
  };

  return (
    <AuthConsumer>
      {({ user, isFetching }) => (
        <>
          <Loader active={isFetching || isLoading} />
          <Typography gutterBottom variant="h3">
            Gutschriften
          </Typography>
          {user.role === UserRole.ADMIN && (
            <>
              <div style={{ marginTop: 20, marginBottom: 20 }}>
                <Button
                  variant="outlined"
                  size="small"
                  color="primary"
                  onClick={() => createCreditNotes()}
                >
                  Gutschriften erstellen
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  color="primary"
                  onClick={() => toggleDrawer(CreditNoteAction.EXPORT)}
                >
                  Gutschriften exportieren
                </Button>
              </div>
              <ActionDrawer
                open={drawerOpen}
                onClose={toggleDrawer}
                width={470}
              >
                {drawerContent === CreditNoteAction.EXPORT && (
                  <CreditNotesExport onSuccess={onSuccess} />
                )}
              </ActionDrawer>

              <ActionFeedback
                message="Gutschriften wurden erfolgreich erstellt"
                visible={success}
                onClose={onCloseActionSuccess}
                type="success"
              />
            </>
          )}
          <CreditNotesList isAdmin={user.role !== UserRole.CUSTOMER} />
        </>
      )}
    </AuthConsumer>
  );
};

export default CreditNotesManager;
