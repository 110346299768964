import { User } from './pages/auth/types';

export interface Employee {
  id: number;
  uuid: string;
  firstname: string;
  lastname: string;
  email: string;
  active: boolean;
  last_auth?: number;
}

export interface Customer {
  uuid: string;
  business_name: string;
  active: boolean;
  address_street: string;
  address_zip: string;
  address_city: string;
  address_country: string;
  user: {
    uuid: string;
    firstname: string;
    lastname: string;
    email: string;
    active: boolean;
    last_auth: string;
  };
  contact_firstname?: string;
  contact_lastname?: string;
  contact_phone?: string;
  contact_email?: string;
  salesforce_id?: number;
  provision_fixed: number;
  provision_topup_one: number;
  provision_topup_two: number;
  provision_topup_three: number;
  balance: Balance;
  employee?: Employee;
}

export enum OrderState {
  CANCELLED = 'CANCELLED',
  RECEIVED = 'RECEIVED',
  IN_PROGRESS = 'IN_PROGRESS',
  SHIPPED = 'SHIPPED',
  DELIVERED = 'DELIVERED',
  ABORTED = 'ABORTED'
}

export interface Order {
  uuid: string;
  number: number;
  state: OrderState;
  state_transistions: Array<OrderStateTransition>;
  customer: {
    uuid: string;
    name: string;
    address: {
      street: string;
      city: string;
      zip: number;
      country?: string;
    };
  };
  amount_gross: number;
  amount_net: number;
  taxes: Array<Taxes>;
  items: Array<OrderItem>;
  created_at: string;
}

export interface Taxes {
  rate: number;
  gross: number;
  net: number;
  tax: number;
}

export interface OrderItem {
  uuid: string;
  state: OrderState;
  number: number;
  name: string;
  amount_gross: number;
  amount_net: number;
  amount_tax: number;
  tax_rate: number;
  stock_transactions: Array<StockTransaction>;
  product: Pick<Product, 'uuid' | 'name' | 'type'>;
  order: Pick<Order, 'uuid' | 'created_at' | 'customer'>;
}

export interface OrderStateTransition {
  uuid: string;
  invoked_by?: {
    uuid: string;
    name: string;
  };
  from: OrderState;
  to: OrderState;
  created_at: Date;
}

export interface Balance {
  id: number;
  amount: number;
}

export interface BalanceTransaction {
  date: string;
  uuid: string;
  amount: number;
  description: string;
  customer: Customer;
  type: BalanceTransactionType;
}

export enum BalanceTransactionType {
  ORDER = 'order',
  TOPUP = 'topup'
}

export enum ProductType {
  SIMCARD = 'SIMCARD',
  VOUCHER = 'VOUCHER',
  RISKFREE = 'RISKFREE'
}

export interface Product {
  active: boolean;
  uuid: string;
  ean: string;
  name: string;
  type: ProductType;
  price: number;
  price_nominale: number;
  tax_rate: number;
  created_at: string;
  logo: string;
  stock_count: number;
}

export enum StockItemState {
  AVAILABLE = 'AVAILABLE',
  RESERVED = 'RESERVED',
  SOLD = 'SOLD'
}

export interface StockTransaction {
  uuid: string;
  customer?: {
    uuid: string;
    name: string;
  };
  state: StockTransactionState;
  created_at: string;
  order?: {
    uuid: string;
  };
  cancellation_reason?: string;
  cancelled_at?: string;
  simcard?: Simcard;
  voucher?: Voucher;
}

export enum StockTransactionState {
  PENDING = 'PENDING',
  CONFIRMED = 'CONFIRMED',
  ABORTED = 'ABORTED',
  CANCELLED = 'CANCELLED',
  CANCELLATION_REQUESTED = 'CANCELLATION_REQUESTED'
}

export interface TopupTransaction {
  uuid: string;
}

export interface Voucher {
  uuid: string;
  product: Product;
  serial: string;
  pin: string;
  state: StockItemState;
  created_at: string;
  stock_transactions: Array<StockTransaction>;
}

export interface Simcard {
  uuid: string;
  product: Product;
  msisdn: string;
  imei: string;
  state: StockItemState;
  customer?: Pick<Customer, 'uuid' | 'business_name'>;
  created_at: string;
  stock_transactions: Array<StockTransaction>;
  topup_transaction: Array<TopupTransaction>;
}

export interface SimcardAssignment {
  uuid: string;
  imei_from: string;
  imei_until: string;
  created_at: string;
  state: SimcardAssignmentState;
  product: Pick<Product, 'uuid' | 'name'>;
  customer: Pick<Customer, 'uuid' | 'business_name'>;
  created_by: Pick<User, 'id' | 'firstname' | 'lastname'>;
  cancelled_by?: Pick<User, 'id' | 'firstname' | 'lastname'>;
  cancelled_at?: string;
}

export enum SimcardAssignmentState {
  ASSIGNED = 'ASSIGNED',
  CANCELLED = 'CANCELLED'
}

export interface CreditNote {
  id: number;
  external_id: string;
  external_number: string;
  title: string;
  created_at: string;
  total_price: number;
  customer: {
    id: string;
    business_name: string;
  };
}

export const mapOrderState = (state: OrderState): string => {
  switch (state) {
    case OrderState.RECEIVED:
    case OrderState.IN_PROGRESS:
      return 'IN BEARBEITUNG';
    case OrderState.DELIVERED:
      return 'ABGESCHLOSSEN';
    case OrderState.SHIPPED:
      return 'LIEFERUNG';
    case OrderState.CANCELLED:
    case OrderState.ABORTED:
      return 'STORNIERT';
    default:
      return 'IN BEARBEITUNG';
  }
};

export interface Image {
  id: number;
  uuid: string;
  name: string;
  mime: string;
  type: 'CAMPAIGN_IMAGE' | 'PRODUCT_IMAGE';
  path: string;
  key: string;
  location: string;
  created_at: string;
  updated_at: string;
}

export interface Campaign {
  id: number;
  uuid: string;
  active: boolean;
  image: Image;
  title: string;
  text?: string;
  created_at: string;
  updated_at: string;
}

export enum RiskfreeTransactionState {
  IN_PROGRESS = 'IN_PROGRESS',
  SUCCEEDED = 'SUCCEEDED',
  FAILED = 'FAILED'
}

export enum RiskfreeTransactionType {
  ACTIVATION = 'ACTIVATION',
  CANCELLATION = 'CANCELLATION'
}

export interface RiskfreeTransaction {
  id: string;
  state: RiskfreeTransactionState;
  type: RiskfreeTransactionType;
  simcard_serial: string;
  metadata?: { [key: string]: any };
  product: {
    id: string;
    name: string;
    ean: string;
  };
  api_client: {
    name: string;
  };
  onlinesim_customer?: {
    id: string;
    business_name: string;
  };
  error: string | null;
  created_at: string;
}
