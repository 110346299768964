import React, { useState } from 'react';
import { Typography, TableRow, Button } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { FormattedDate, Table, TableCell } from '../../components/primitives';
import ActionFeedback from '../../components/patterns/ActionFeedback';
import ActionDrawer from '../../components/patterns/ActionDrawer';
import InvoiceExport from './InvoicesExport';
import { UserRole } from '../auth/types';
import { AuthConsumer } from '../auth/AuthContext';
import { Loader } from '../../components/common/Loader';

enum InvoiceAction {
  EXPORT = 'export'
}

export const Invoices: React.FC = () => {
  const [invoices, setInvoices] = useState<Array<any>>([]);
  const [success, setSuccess] = useState<boolean>(false);
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [drawerContent, setDrawerContent] = useState<InvoiceAction>();

  const toggleDrawer = (content?: InvoiceAction) => {
    setDrawerOpen(!drawerOpen);
    setDrawerContent(content);
  };

  const onCloseActionSuccess = () => setSuccess(false);

  const onSuccess = async () => {
    setDrawerOpen(false);
    setSuccess(true);
  };

  const renderRow = (row: any) => (
    <TableRow key={row.id}>
      <TableCell component="th" scope="row">
        <FormattedDate date={row.date} showTime={true} />
      </TableCell>
      <TableCell>{row.description}</TableCell>
    </TableRow>
  );

  return (
    <AuthConsumer>
      {({ user, isFetching }) => (
        <div>
          <Loader active={isFetching} />
          <Typography variant="h3">Rechnungen</Typography>
          {invoices.length ? (
            <Table
              headers={[
                { label: 'Datum', align: 'left' },
                { label: 'Beschreibung', align: 'left' },
                { label: 'Betrag', align: 'right' }
              ]}
              rows={invoices}
              // @ts-ignore
              renderRow={renderRow}
              noBorder={true}
            />
          ) : (
            <></>
          )}

          {user.role === UserRole.ADMIN && (
            <>
              <div style={{ marginTop: 20, marginBottom: 20 }}>
                <Button
                  variant="outlined"
                  size="small"
                  color="primary"
                  onClick={() => toggleDrawer(InvoiceAction.EXPORT)}
                >
                  Rechnungen exportieren
                </Button>
              </div>
              <ActionDrawer
                open={drawerOpen}
                onClose={toggleDrawer}
                width={470}
              >
                {drawerContent === InvoiceAction.EXPORT && (
                  <InvoiceExport onSuccess={onSuccess} />
                )}
              </ActionDrawer>

              <ActionFeedback
                message="Gutschriften wurden erfolgreich erstellt"
                visible={success}
                onClose={onCloseActionSuccess}
                type="success"
              />
            </>
          )}
        </div>
      )}
    </AuthConsumer>
  );
};
