import React, { useState, useEffect } from 'react';
import { server } from '../../utils/server';
import { Loader } from '../../components/common/Loader';
import { ProductEditForm, FormData } from './components/ProductEditForm';
import { Alert, AlertType } from '../../components/primitives/Alert';
import { sanitize } from '../../utils/helper';
import { Typography } from '@material-ui/core';
import { Product, ProductType } from '../../types';

interface ProductEditProps {
  productUuid: string;
  onSuccess: () => void;
}

const ProductEdit: React.FC<ProductEditProps> = ({
  onSuccess,
  productUuid
}) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [loadingError, setLoadingError] = useState<string | undefined>(
    undefined
  );
  const [error, setError] = useState<string | undefined>(undefined);
  const [product, setProduct] = useState<Product | undefined>(undefined);

  useEffect(() => {
    fetchData().then(setProduct);
  }, []);

  const fetchData = async (): Promise<Product | undefined> => {
    setLoading(true);
    try {
      const res = await server.get(`/products/${productUuid}`);
      setLoading(false);
      return res.data;
    } catch (error) {
      setLoading(false);
      setLoadingError('Fehler beim Ladens des Produktes');
      return undefined;
    }
  };

  const onSubmit = async (data: FormData) => {
    setLoading(true);
    try {
      console.log(data);
      const res: { data: Product } = await server.put(
        `/products/${productUuid}`,
        {
          // @ts-ignore
          ...sanitize(data, ['price', 'price_nominale', 'tax_rate']),
          price: Math.round(data.price),
          price_nominale: Math.round(data.price_nominale),
          tax_rate: Number(data.tax_rate)
        }
      );
      setLoading(false);
      setProduct(res.data);
      onSuccess();
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  return product ? (
    <>
      <Typography variant="h3">Produkt bearbeiten</Typography>
      {loadingError && (
        <Alert type={AlertType.ERROR} headline="Unerwarteter Fehler">
          <Typography paragraph color="inherit">
            {loadingError}
          </Typography>
        </Alert>
      )}
      {isLoading && <Loader active={isLoading} width={100} />}
      <>
        <ProductEditForm
          onSubmit={onSubmit}
          isLoading={isLoading}
          product={product}
        />
      </>
    </>
  ) : (
    <Loader active={isLoading} width={100} />
  );
};

export default ProductEdit;
