import React from 'react';
import PyroForm from 'pyro-form';
import { TextField } from '../../../components/primitives';
import { Button, Typography } from '@material-ui/core';

export interface ResetPasswordParameter {
  email: string;
}

export interface ResetPasswordFormProps {
  onSubmit: (data: ResetPasswordParameter) => void;
  loading: boolean;
}

interface InitialValues {
  email: string;
}

const initialValues: InitialValues = { email: '' };

export const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({
  onSubmit,
  loading
}) => {
  return (
    <PyroForm initialValues={initialValues} onSubmit={onSubmit}>
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Typography variant="h2" gutterBottom style={{ fontWeight: 700 }}>
            Passwort zurücksetzen
          </Typography>
          <Typography variant="body1" gutterBottom>
            Gib deine E-Mail Adresse ein und wir senden dir eine Anleitung zum
            Zurücksetzen deines Passworts.
          </Typography>
          <TextField<InitialValues>
            name="email"
            type="email"
            label="E-Mail Adresse"
            fullWidth
            margin="normal"
          />
          <Button
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
            disabled={loading}
          >
            Passwort zurücksetzen beantragen
          </Button>
        </form>
      )}
    </PyroForm>
  );
};
