import React from 'react';
import {
  Route,
  RouteComponentProps,
  Switch,
  withRouter
} from 'react-router-dom';
import { AcceptPolicies } from './AcceptPolicies';
import Login from './Login';
import Logout from './Logout';
import ResetPassword from './ResetPassword';
import ResetPasswordConfirm from './ResetPasswordConfirm';

export enum AuthRoutes {
  LOGIN = '/login',
  LOGOUT = '/logout',
  PASSWORD_RESET = '/reset',
  ACCEPT_POLICIES = '/accept-policies'
}

class AuthRouting extends React.PureComponent<RouteComponentProps<{}>> {
  public render() {
    return (
      <Switch>
        <Route path={AuthRoutes.LOGIN} exact component={Login} />
        <Route path={AuthRoutes.LOGOUT} exact component={Logout} />
        <Route
          path={AuthRoutes.PASSWORD_RESET}
          exact
          component={ResetPassword}
        />
        <Route
          path={`${AuthRoutes.PASSWORD_RESET}/:uuid`}
          component={ResetPasswordConfirm}
        />
        <Route
          path={`${AuthRoutes.ACCEPT_POLICIES}`}
          exact
          component={AcceptPolicies}
        />
      </Switch>
    );
  }
}

export default withRouter(AuthRouting);
