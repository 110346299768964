import React, { useState } from 'react';
import { Typography, Grid, Fab, Button, ButtonGroup } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Add as AddIcon } from '@material-ui/icons';
import { Topup, TopupState } from './types';
import TopupList from './TopupList';
import TopupCreate from './TopupCreate';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '../../theme';
import ActionFeedback from '../../components/patterns/ActionFeedback';
import ActionDrawer from '../../components/patterns/ActionDrawer';
import TopupTransactionCreate from './TopupTransactionCreate';

const useStyles = makeStyles((theme: Theme) => ({
  fab: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  }
}));

enum TopupAction {
  CREATE = 'create',
  CREATE_TRANSACTION = 'create-transaction'
}

const TopupManager: React.FC = () => {
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [drawerContent, setDrawerContent] = useState<TopupAction>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [selectedTopup, selectTopup] = useState<Topup | undefined>(undefined);
  const [error, setError] = useState<string | undefined>(undefined);
  const [success, setSuccess] = useState<boolean>(false);
  const [refreshData, setRefreshData] = useState<boolean>(true);
  const classes = useStyles();

  const toggleDrawer = (content?: TopupAction) => {
    setDrawerOpen(!drawerOpen);
    setDrawerContent(content);
  };
  const onCloseActionSuccess = () => setSuccess(false);

  const onSuccess = async () => {
    setDrawerOpen(false);
    setSuccess(true);
    setRefreshData(true);
  };

  const topupSuccessMessages = new Map();
  topupSuccessMessages.set(
    TopupAction.CREATE,
    'Die Gutschriften wurden erfolgreich dem Kunden zugewiesen.'
  );
  topupSuccessMessages.set(
    TopupAction.CREATE_TRANSACTION,
    'Die Gutschrift wurde erstellt'
  );

  return (
    <>
      <div style={{ marginBottom: 30 }}>
        <Typography variant="h2" gutterBottom>
          Topups
        </Typography>
        <Button
          variant="outlined"
          size="small"
          color="primary"
          onClick={() => toggleDrawer(TopupAction.CREATE)}
        >
          Topup Liste importieren
        </Button>
        <Button
          variant="outlined"
          size="small"
          color="primary"
          onClick={() => toggleDrawer(TopupAction.CREATE_TRANSACTION)}
        >
          Manuelle Gutschrift erstellen
        </Button>
      </div>
      {error && (
        <Alert severity="error">
          Es ist ein Fehler beim Laden der Topup aufgetreten
        </Alert>
      )}

      <TopupList
        handleSelectTopup={selectTopup}
        refreshData={refreshData}
        setRefreshData={setRefreshData}
        selectedTopup={selectedTopup}
        selectTopup={selectTopup}
        setError={setError}
      />
      <ActionDrawer open={drawerOpen} onClose={toggleDrawer} width={470}>
        {drawerContent === TopupAction.CREATE && (
          <TopupCreate onSuccess={onSuccess} />
        )}
        {drawerContent === TopupAction.CREATE_TRANSACTION && (
          <TopupTransactionCreate onSuccess={onSuccess} />
        )}
      </ActionDrawer>
      <ActionFeedback
        message={topupSuccessMessages.get(drawerContent)}
        visible={success}
        onClose={onCloseActionSuccess}
        type="success"
      />
    </>
  );
};

export default TopupManager;
