import React, { useContext, useState, useEffect } from 'react';
import { AuthContext } from './AuthContext';
import {
  LoginForm,
  LoginFormErrors,
  CredentialLoginParameter
} from './components/LoginForm';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { server } from '../../utils/server';
import { Loader } from '../../components/common/Loader';
import { UserRole } from './types';
import { couldStartTrivia } from 'typescript';

interface AuthenticateResponse {
  jwt: string;
  expires_at: number;
  accepted_policies: boolean;
  role: UserRole;
}

export const Login: React.FC<RouteComponentProps<{}>> = ({ history }) => {
  const [isLoading, setLoading] = useState(false);
  const [errors, setErrors] = useState<LoginFormErrors>({});

  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('The wrapping context was not correctly defined');
  }

  useEffect(() => {
    if (context.isAuthenticated) {
      history.push('/');
    }
  }, []);

  const validateInput = ({ email, password }: CredentialLoginParameter) => {
    const errors: LoginFormErrors = {};
    if (!email || email === '') {
      errors.email = 'Bitte gib eine valide E-Mail Adresse an';
    }
    if (!password || password === '') {
      errors.password = 'Ungüliges Passwort';
    }

    if (!errors.email && !errors.password) {
      return true;
    }
    setErrors(errors);
    return false;
  };

  const authenticate = async ({
    email,
    password
  }: CredentialLoginParameter) => {
    setLoading(true);
    if (validateInput({ email, password })) {
      try {
        const res = await server.post('/authenticate', { email, password });
        const {
          jwt,
          expires_at,
          accepted_policies,
          role
        }: AuthenticateResponse = res.data;
        sessionStorage.setItem('access_token', jwt);
        sessionStorage.setItem('access_token_expires_at', String(expires_at));
        context.login();
        if (role !== UserRole.CUSTOMER || accepted_policies) {
          history.push('/');
        } else {
          history.push('/accept-policies');
        }
      } catch (error) {
        setLoading(false);
        setErrors(error);
        // history.push('/login')
      }
    }
  };

  return (
    <>
      <LoginForm onSubmit={authenticate} loading={isLoading} errors={errors} />
      <Loader active={isLoading} />
    </>
  );
};

export default withRouter(Login);
