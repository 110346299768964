import React from 'react';
import Typography, { TypographyProps } from '@material-ui/core/Typography';

interface DateProps extends TypographyProps {
  date: string;
  showTime?: boolean;
  wrapped?: boolean;
}

export const FormattedDate: React.FC<DateProps> = ({
  showTime = true,
  date,
  wrapped = true,
  ...rest
}) => {
  const dateObject = new Date(date);
  const months = [
    'Jänner',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember'
  ];
  const withLeadingZero = (value: number): string =>
    value < 10 ? `0${value}` : String(value);
  const outputDate = `${dateObject.getDate()}. ${
    months[dateObject.getMonth()]
  } ${dateObject.getFullYear()}`;
  const outputTime = ` ${withLeadingZero(
    dateObject.getHours()
  )}:${withLeadingZero(dateObject.getMinutes())}`;
  if (wrapped) {
    return (
      <Typography {...rest}>
        {outputDate}
        {showTime && outputTime}
      </Typography>
    );
  } else {
    return (
      <>
        {outputDate}
        {showTime && outputTime}
      </>
    );
  }
};
