import axios from 'axios';
import { isArray } from 'util';
import { stringify } from 'query-string';

type HttpStatusCode = 400 | 401 | 404 | 500;

interface ErrorResponse {
  response: {
    data: {
      error: string;
      statusCode: HttpStatusCode;
      message:
        | Array<{
            property: string;
            constraints: { [key: string]: any };
          }>
        | string;
    };
  };
}

interface HandledError {
  [key: string]: string;
}

export const stringifyQuery = (query: { [key: string]: any }) =>
  stringify(query);
export const defaultErrorMessage =
  'Leider ist ein Fehler aufgetreten. Bitte haben Sie etwas Geduld und probieren sie es ein wenig später nocheinmal';
export const errorHandler = (error: ErrorResponse): Promise<HandledError> => {
  if (!error.response) {
    return Promise.reject({ common: defaultErrorMessage });
  }
  const { data } = error.response;
  console.log({ data });
  const handledError: HandledError = {};
  if (![400, 401].includes(data.statusCode)) {
    handledError.common = defaultErrorMessage;
  } else {
    if (data.message && isArray(data.message)) {
      for (const m of data.message) {
        handledError[m.property] = Object.values(m.constraints)[0];
      }
    } else if (data.message) {
      handledError.common = data.message;
    }
  }
  return Promise.reject(handledError);
};

export const server = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: {
    'content-type': 'application/json'
  }
});

server.interceptors.request.use(
  async config => {
    const accessToken = sessionStorage.getItem('access_token');
    const accessTokenExpiresAt = sessionStorage.getItem(
      'access_token_expires_at'
    );
    console.log(
      `SERVER REQUEST: ${config.url} ${
        config.data ? JSON.stringify(config.data) : ''
      }`
    );

    const whitelistedEndpoints = ['/authenticate', '/reset'];
    if (
      config.url &&
      config.baseURL &&
      whitelistedEndpoints.includes(config.url.replace(config.baseURL, ''))
    ) {
      return config;
    }

    const isExpired =
      accessTokenExpiresAt && new Date() > new Date(accessTokenExpiresAt);

    if (!accessToken || isExpired) {
      console.log('would redirect to login page');
      // window.location.href = '/'
      return config;
    } else {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
      return config;
    }
  },
  err => Promise.reject(err)
);

server.interceptors.response.use(response => response, errorHandler);
