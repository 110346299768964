import React, { useState } from 'react';
import { Loader } from '../../components/common/Loader';
import { server } from '../../utils/server';
import Button from '../../components/primitives/Button';
import { Typography, MenuItem, TextField } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

interface TopupCreateProps {
  onSuccess: () => void;
}

enum TopupSchema {
  EETY = 'EETY',
  DREI = 'DREI'
}

const TopupCreate: React.FC<TopupCreateProps> = ({ onSuccess }) => {
  const [error, setError] = useState<string | undefined>(undefined);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [file, setFile] = useState<File | undefined>(undefined);
  const [schema, setSchema] = useState<TopupSchema>(TopupSchema.EETY);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    if (file) {
      const formData = new FormData();
      formData.append('file', file, file.name);
      formData.append('schema', String(schema));
      try {
        const res = await server.post('/topups', formData, {
          onUploadProgress: progressEvent => {
            setProgress(
              Math.floor((progressEvent.loaded * 100) / progressEvent.total)
            );
            console.log({ progress });
          }
        });
        setLoading(false);
        console.log({ res });
        onSuccess();
      } catch (error) {
        setLoading(false);
        setError(error);
      }
    } else {
      setError('Bitte wähle einen Topup Typ und eine Datei aus!');
      setLoading(false);
    }
  };

  const handleFileSelect = (files: FileList | null) => {
    if (files) {
      console.log(files);
      setFile(files[0]);
    }
  };

  const handleSchemaSelect = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSchema(event.target.value as TopupSchema);
  };

  return (
    <>
      <Typography variant="h3">Topup hinzufügen</Typography>
      {error && (
        <Alert severity="error">
          Es ist ein Fehler beim Laden der Topup aufgetreten
        </Alert>
      )}
      {isLoading ? (
        <Loader active={isLoading} width={100} />
      ) : (
        <form onSubmit={handleSubmit}>
          <TextField
            id="customer-select"
            select
            label="Topup-Schema auswählen"
            value={schema}
            onChange={handleSchemaSelect}
            variant="outlined"
            fullWidth
          >
            <MenuItem key={TopupSchema.EETY} value={TopupSchema.EETY}>
              {TopupSchema.EETY}
            </MenuItem>
            <MenuItem key={TopupSchema.DREI} value={TopupSchema.DREI}>
              {TopupSchema.DREI}
            </MenuItem>
          </TextField>

          <input
            accept="text/csv"
            id="file"
            onChange={e => handleFileSelect(e.target.files)}
            multiple
            type="file"
          />
          <label htmlFor="file">
            <Button variant="contained" type="submit">
              Upload
            </Button>
          </label>
        </form>
      )}
    </>
  );
};

export default TopupCreate;
