import React, { useState, useEffect } from 'react';
import {
  Typography,
  InputAdornment,
  MenuItem,
  TextField
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import { Loader } from '../../../components/common/Loader';
import { server, stringifyQuery } from '../../../utils/server';
import Button from '../../../components/primitives/Button';
import { Product, OrderItem, Customer } from '../../../types';

interface SimcardAssignProps {
  onSuccess: () => void;
  selectedCustomer?: string;
  selectedOrderItem?: string;
  selectedProduct?: string;
}

interface FormData {
  product: string;
  imeiFrom: string;
  imeiUntil: string;
  customer: string;
  orderItem: string;
}

enum AssignmentType {
  WITH_ORDER = 'mit Bestellung',
  WITHOUT_ORDER = 'ohne Bestellung'
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1)
      }
    },
    btn: {
      margin: theme.spacing(1),
      width: '100%'
    }
  })
);

export const SimcardAssign: React.FC<SimcardAssignProps> = ({
  onSuccess,
  selectedCustomer,
  selectedOrderItem,
  selectedProduct
}) => {
  const [error, setError] = useState<string | undefined>(undefined);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [products, setProducts] = useState<Array<Product>>([]);
  const [customers, setCustomers] = useState<Array<Customer>>([]);
  const [orderItems, setOrderItems] = useState<Array<OrderItem>>([]);
  const [formData, setFormData] = useState<FormData>({
    product: selectedProduct || '',
    imeiFrom: '',
    imeiUntil: '',
    orderItem: selectedOrderItem || '',
    customer: selectedCustomer || ''
  });
  const [assignmentType, setAssignmentType] = useState<AssignmentType>(
    AssignmentType.WITH_ORDER
  );
  const classes = useStyles();

  useEffect(() => {
    Promise.all([fetchProducts(), fetchCustomers(), fetchOrderItems()]).then(
      data => {
        setProducts(data[0]);
        setCustomers(data[1]);
        setOrderItems(data[2]);
      }
    );
  }, []);

  const fetchProducts = async (): Promise<Array<Product>> => {
    setLoading(true);
    try {
      const res = await server.get('/products?type=SIMCARD');
      setLoading(false);
      return res.data;
    } catch (error) {
      setLoading(false);
      return [];
    }
  };
  const fetchCustomers = async (): Promise<Array<Customer>> => {
    setLoading(true);
    try {
      const res = await server.get('/customers?page=0&limit=1000');
      setLoading(false);
      return res.data.rows;
    } catch (error) {
      setLoading(false);
      return [];
    }
  };

  const fetchOrderItems = async (): Promise<Array<OrderItem>> => {
    setLoading(true);
    try {
      const filter: { state: string; customer_uuid?: string } = {
        state: 'RECEIVED'
      };
      filter.customer_uuid = selectedCustomer
        ? formData.customer !== ''
          ? formData.customer
          : undefined
        : undefined;
      const queryString = stringifyQuery(filter);
      const res = await server.get(
        `/orderitems${queryString && '?' + queryString}`
      );
      setLoading(false);
      return res.data;
    } catch (error) {
      setLoading(false);
      return [];
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setError(undefined);

    if (!formData.orderItem && (!formData.product || !formData.customer)) {
      setError(
        'Du musst entweder eine Bestellung oder ein Produkt und einen Kunden auswählen'
      );
    }

    if (!formData.imeiFrom || !formData.imeiUntil) {
      setError('Du musst IMEI Start und IMEI Ende korrekt eingeben');
    }

    setLoading(true);

    try {
      const res = await server.post('/stock/simcard-assignments', {
        ...formData,
        imeiFrom: `894310${formData.imeiFrom}`,
        imeiUntil: `894310${formData.imeiUntil}`
      });
      setLoading(false);
      console.log({ res });
      onSuccess();
    } catch (error) {
      setLoading(false);
      setError(error.common);
    }
  };

  const handleProductSelect = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setFormData({ ...formData, product: event.target.value as string });
  };

  const handleCustomerSelect = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setFormData({ ...formData, customer: event.target.value as string });
    fetchOrderItems().then(orderItems => setOrderItems(orderItems));
  };

  const handleOrderItemSelect = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setFormData({ ...formData, orderItem: event.target.value as string });
  };

  return (
    <>
      <Typography variant="h3">Simkarten einem Kunden zuweisen</Typography>
      {error && <Alert severity="error">{error}</Alert>}
      {isLoading ? (
        <Loader active={isLoading} width={100} />
      ) : (
        <form onSubmit={handleSubmit} className={classes.root}>
          {!selectedCustomer && !selectedOrderItem && (
            <div style={{ marginBottom: 20 }}>
              <TextField
                id="customer-select"
                select
                label="Kunde auswählen"
                value={formData.customer}
                onChange={handleCustomerSelect}
                variant="outlined"
                fullWidth
              >
                {customers.map(customer => (
                  <MenuItem key={customer.uuid} value={customer.uuid}>
                    {customer.business_name}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          )}
          <TextField
            id="assignment-type-select"
            select
            label="MIT oder OHNE Bestellung?"
            value={assignmentType}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
              setAssignmentType(event.target.value as AssignmentType)
            }
            variant="outlined"
            fullWidth
          >
            <MenuItem key="with-order" value={AssignmentType.WITH_ORDER}>
              {AssignmentType.WITH_ORDER}
            </MenuItem>
            <MenuItem key="without-order" value={AssignmentType.WITHOUT_ORDER}>
              {AssignmentType.WITHOUT_ORDER}
            </MenuItem>
          </TextField>

          {assignmentType === AssignmentType.WITH_ORDER && (
            <div style={{ marginTop: 20 }}>
              {orderItems && orderItems.length > 0 ? (
                <TextField
                  id="orderitem-select"
                  select
                  label="Bestellung auswählen"
                  value={formData.orderItem}
                  onChange={handleOrderItemSelect}
                  variant="outlined"
                  fullWidth
                >
                  {orderItems.map(item => (
                    <MenuItem
                      key={item.uuid}
                      value={item.uuid}
                    >{`Bestellung vom ${item.order.created_at} (${
                      item.number
                    } x ${item.product.name})`}</MenuItem>
                  ))}
                </TextField>
              ) : (
                <Alert severity="warning">
                  Dieser Kunde hat keine offenen Bestellungen
                </Alert>
              )}
            </div>
          )}

          <div style={{ marginTop: 20 }}>
            {!selectedProduct && (
              <TextField
                id="product-select"
                select
                label="Produkt auswählen"
                value={formData.product}
                onChange={handleProductSelect}
                variant="outlined"
                fullWidth
              >
                {products.map(product => (
                  <MenuItem key={product.uuid} value={product.uuid}>
                    {product.name}
                  </MenuItem>
                ))}
              </TextField>
            )}

            <TextField
              variant="outlined"
              name="imeiFrom"
              type="text"
              label="IMEI Start"
              defaultValue={formData.imeiFrom}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">894310</InputAdornment>
                )
              }}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                setFormData({
                  ...formData,
                  imeiFrom: event.target.value as string
                })
              }
              required
              fullWidth
            />
            <TextField
              variant="outlined"
              name="imeiUntil"
              type="text"
              label="IMEI Ende"
              defaultValue={formData.imeiUntil}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">894310</InputAdornment>
                )
              }}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                setFormData({
                  ...formData,
                  imeiUntil: event.target.value as string
                })
              }
              required
              fullWidth
            />
          </div>
          <Button
            variant="outlined"
            color="primary"
            type="submit"
            className={classes.btn}
          >
            Simkarten zuweisen
          </Button>
        </form>
      )}
    </>
  );
};
