import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Theme } from '../../../theme';

interface TabNavigationProperties {
  tabs: Array<string>;
  activeTab: number;
  handleChange: (tab: any) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    borderBottom: `1px solid ${theme.palette.divider}`,
    background: 'transparent'
  },
  container: {
    marginTop: 20
  },
  button: {
    color: theme.palette.text.primary
  }
}));

export const TabNavigation: React.FC<TabNavigationProperties> = ({
  tabs,
  activeTab = 0,
  handleChange,
  ...rest
}) => {
  const classes = useStyles();

  function onChange(_event: React.ChangeEvent<{}>, newValue: number) {
    handleChange(newValue);
  }

  return (
    <div className={classes.root}>
      <Tabs
        value={activeTab}
        onChange={onChange}
        indicatorColor="primary"
        textColor="primary"
        {...rest}
      >
        {tabs.map(tab => (
          <Tab key={tab} label={tab} className={classes.button} />
        ))}
      </Tabs>
    </div>
  );
};

export const TabContainer: React.FC = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.container}>{children}</div>;
};
