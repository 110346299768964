import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import {
  Section,
  SectionContent,
  SectionHeader,
  SectionFooter
} from '../../components/primitives/Section';
import { Typography, Avatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '../../theme';
import Button from '@material-ui/core/Button';
import ActionDrawer from '../../components/patterns/ActionDrawer';
import CustomerUsersEdit from './CustomerUsersEdit';
import ActionFeedback from '../../components/patterns/ActionFeedback';

interface User {
  uuid: string;
  firstname: string;
  lastname: string;
  email: string;
  active: boolean;
  last_auth: string;
}

interface UserListProps {
  users: Array<User>;
}

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    marginRight: 30,
    width: 60,
    height: 60
  }
}));

export const CustomerUsers: React.FC<UserListProps> = ({ users }) => {
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [selectedUser, selectUser] = useState<string | undefined>(undefined);
  const [success, setSuccess] = useState<boolean>(false);

  const classes = useStyles();
  const toggleDrawer = () => setDrawerOpen(!drawerOpen);
  const onCloseActionFeedback = () => setSuccess(false);
  const onSuccess = () => {
    toggleDrawer();
    setSuccess(true);
  };
  const editUser = (uuid: string) => {
    selectUser(uuid);
    toggleDrawer();
  };

  const renderUser = (user: User) => (
    <Grid item md={4} sm={6} key={user.uuid}>
      <Section>
        <SectionHeader>
          <Typography variant="h4">
            {user.firstname} {user.lastname}
          </Typography>
        </SectionHeader>
        <SectionContent>
          <Grid container>
            <Avatar
              className={classes.avatar}
              src="https://pickaface.net/gallery/avatar/demo.webmaster541295de29059.png"
            />
            <Grid item>
              {!user.active && (
                <Typography variant="h5">Noch nicht verifziert</Typography>
              )}
              <Typography variant="body1">{user.email}</Typography>
              <Typography variant="body1">{`letzter Login: ${
                user.last_auth
              }`}</Typography>
            </Grid>
          </Grid>
        </SectionContent>
        <SectionFooter>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => editUser(user.uuid)}
          >
            Bearbeiten
          </Button>
        </SectionFooter>
      </Section>
    </Grid>
  );
  return (
    <>
      <Grid container spacing={4}>
        {users.map(user => renderUser(user))}
      </Grid>
      <ActionDrawer open={drawerOpen} onClose={toggleDrawer} width={470}>
        <CustomerUsersEdit onSuccess={onSuccess} userUuid={selectedUser} />
      </ActionDrawer>
      <ActionFeedback
        message="Benutzer gespeichert"
        visible={success}
        onClose={onCloseActionFeedback}
        type="success"
      />
    </>
  );
};
