import React, { useState, useEffect } from 'react';
import { Loader } from '../../components/common/Loader';
import { server } from '../../utils/server';
import Button from '../../components/primitives/Button';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  Typography,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  TextField
} from '@material-ui/core';
import { TopupType } from './types';
import { Customer } from '../../types';

interface TopupCreateProps {
  onSuccess: () => void;
}

interface FormData {
  type?: TopupType;
  amount?: number;
  customer?: string;
  description?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1)
      }
    },
    btn: {
      margin: theme.spacing(1),
      width: '100%'
    }
  })
);

const TopupTransactionCreate: React.FC<TopupCreateProps> = ({ onSuccess }) => {
  const [error, setError] = useState<string | undefined>(undefined);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<FormData>({ type: undefined });
  const [customers, setCustomers] = useState<Array<Customer>>([]);
  const classes = useStyles();

  useEffect(() => {
    fetchCustomers().then(setCustomers);
  }, []);

  const fetchCustomers = async (): Promise<Array<Customer>> => {
    setLoading(true);
    try {
      const res = await server.get('/customers?page=0&limit=400');
      setLoading(false);
      return res.data.rows;
    } catch (error) {
      setLoading(false);
      return [];
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    if (data.type && data.customer && data.amount) {
      try {
        const res = await server.post('/topups/transactions', {
          type: data.type,
          customerUuid: data.customer,
          amount: Math.round(Number(data.amount) * 100),
          description: data.description
        });
        setLoading(false);
        console.log({ res });
        onSuccess();
      } catch (error) {
        setLoading(false);
        setError(error);
      }
    } else {
      setError('Bitte wähle einen Topup Typ und eine Datei aus!');
      setLoading(false);
    }
  };

  const handleTypeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value as TopupType | 0;
    setData({ ...data, type: value === 0 ? undefined : value });
  };

  const handleCustomerSelect = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setData({ ...data, customer: event.target.value as string });
  };

  return (
    <>
      <Typography variant="h3">Topup hinzufügen</Typography>
      {isLoading ? (
        <Loader active={isLoading} width={100} />
      ) : (
        <form onSubmit={handleSubmit}>
          <div style={{ marginBottom: 20 }}>
            <TextField
              id="customer-select"
              select
              label="Kunde auswählen"
              value={data.customer}
              onChange={handleCustomerSelect}
              variant="outlined"
              fullWidth
            >
              {customers.map(customer => (
                <MenuItem key={customer.uuid} value={customer.uuid}>
                  {customer.business_name}
                </MenuItem>
              ))}
            </TextField>
          </div>

          <FormControl
            variant="outlined"
            fullWidth={true}
            style={{ marginTop: 10, marginBottom: 10 }}
          >
            <InputLabel id={`label-topup-type`}>Topup Typ auswählen</InputLabel>
            <Select id={'label-topup-type'} onChange={handleTypeChange}>
              <MenuItem value={0}>Bitte auswählen</MenuItem>
              <MenuItem value={TopupType.ACTIVATION}>Aktivierung</MenuItem>
              <MenuItem value={TopupType.TOPUP_ONE}>1. Topup</MenuItem>
              <MenuItem value={TopupType.TOPUP_TWO}>2. Topup</MenuItem>
              <MenuItem value={TopupType.TOPUP_THREE}>3. Topup</MenuItem>
            </Select>
          </FormControl>

          <TextField
            variant="outlined"
            name="amount"
            type="text"
            label="Betrag"
            defaultValue={data.amount}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
              setData({
                ...data,
                amount: event.target.value as number
              })
            }
            required
            fullWidth
          />

          <TextField
            variant="outlined"
            name="description"
            type="text"
            label="Information"
            defaultValue={data.description}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
              setData({
                ...data,
                description: event.target.value as string
              })
            }
            required
            fullWidth
          />
          <Button
            variant="outlined"
            color="primary"
            type="submit"
            className={classes.btn}
          >
            Gutschrift buchen
          </Button>
        </form>
      )}
    </>
  );
};

export default TopupTransactionCreate;
