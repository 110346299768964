import React, { useState } from 'react';
import { server } from '../../utils/server';
import { Typography, TextField } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Loader } from '../../components/common/Loader';
import Button from '../../components/primitives/Button';
import { Alert, AlertType } from '../../components/primitives/Alert';

interface CampaignCreateProps {
  onSuccess: () => void;
}

interface FormData {
  title: string;
  text: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1)
      }
    },
    btn: {
      margin: theme.spacing(1),
      width: '100%'
    }
  })
);

const CampaignCreate: React.FC<CampaignCreateProps> = ({ onSuccess }) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [file, setFile] = useState<File | undefined>(undefined);
  const [progress, setProgress] = useState<number>(0);
  const [data, setData] = useState<FormData>({
    title: '',
    text: ''
  });
  const classes = useStyles();

  const handleFileSelect = (files: FileList | null) => {
    if (files) {
      console.log(files);
      setFile(files[0]);
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    if (file) {
      const formData = new FormData();
      formData.append('file', file, file.name);
      formData.append('title', data.title);
      formData.append('text', data.text);
      try {
        await server.post('/campaigns', formData, {
          onUploadProgress: progressEvent => {
            setProgress(
              Math.floor((progressEvent.loaded * 100) / progressEvent.total)
            );
            console.log({ progress });
          }
        });
        setLoading(false);
        onSuccess();
      } catch (error) {
        setLoading(false);
        setError(error.common);
      }
    } else {
      setLoading(false);
      setError(
        'Du musst ein Bild auswählen um eine neue Kampagne erstellen zu können.'
      );
    }
  };

  return (
    <>
      <Typography variant="h3">Neue Kampagne anlegen</Typography>
      {error && (
        <Alert type={AlertType.ERROR} headline="Ein Fehler ist aufgetreten">
          <Typography paragraph color="inherit">
            {error}
          </Typography>
        </Alert>
      )}
      {isLoading ? (
        <Loader active={isLoading} width={100} />
      ) : (
        <form onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            name="title"
            type="text"
            label="Titel"
            defaultValue={data.title}
            style={{ marginBottom: 20 }}
            onChange={(event: React.ChangeEvent<{ value: string }>) =>
              setData({
                ...data,
                title: event.target.value
              })
            }
            required
            fullWidth
          />
          <TextField
            variant="outlined"
            name="text"
            type="text"
            label="Text"
            defaultValue={data.text}
            style={{ marginBottom: 20 }}
            onChange={(event: React.ChangeEvent<{ value: string }>) =>
              setData({
                ...data,
                text: event.target.value
              })
            }
            fullWidth
          />
          <input
            accept="image/*"
            id="file"
            onChange={e => handleFileSelect(e.target.files)}
            multiple
            type="file"
          />
          <Button
            variant="outlined"
            color="primary"
            type="submit"
            className={classes.btn}
          >
            Speichern
          </Button>
        </form>
      )}
    </>
  );
};

export default CampaignCreate;
