import React from 'react';
import PyroForm from 'pyro-form';
import { TextField, Alert, AlertType } from '../../../components/primitives';
import { Button, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Lock from '@material-ui/icons/Lock';
import InputAdornment from '@material-ui/core/InputAdornment';

export interface CredentialLoginParameter {
  email: string;
  password: string;
}

export interface LoginFormErrors {
  email?: string;
  password?: string;
  common?: string;
}

export interface LoginFormProps {
  onSubmit: (credentials: CredentialLoginParameter) => void;
  loading: boolean;
  errors?: LoginFormErrors;
}

interface InitialValues {
  email: string;
  password: string;
}

const initialValues: InitialValues = { email: '', password: '' };

export const LoginForm: React.FC<LoginFormProps> = ({
  onSubmit,
  loading,
  errors
}) => {
  return (
    <PyroForm initialValues={initialValues} onSubmit={onSubmit} errors={errors}>
      {({ handleSubmit, hasErrors }) => (
        <form onSubmit={handleSubmit}>
          <Typography variant="h2" gutterBottom style={{ fontWeight: 700 }}>
            Anmeldung
          </Typography>
          <TextField<InitialValues>
            name="email"
            type="email"
            label="E-Mail Adresse"
            fullWidth
            margin="normal"
            required
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              )
            }}
          />
          <TextField<InitialValues>
            name="password"
            type="password"
            label="Password"
            fullWidth
            margin="normal"
            required
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Lock />
                </InputAdornment>
              )
            }}
          />
          {errors && errors.common && (
            <Alert type={AlertType.ERROR}>
              <Typography paragraph color="inherit">
                {errors.common}
              </Typography>
            </Alert>
          )}
          <Button
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
            disabled={loading}
          >
            Jetzt anmelden
          </Button>
          <Button color="primary" component={Link} to="/reset">
            Passwort vergessen
          </Button>
        </form>
      )}
    </PyroForm>
  );
};
