import React, { useContext, useState, useEffect } from 'react';
import { AuthContext } from './AuthContext';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { server } from '../../utils/server';
import { Loader } from '../../components/common/Loader';
import { Alert } from '@material-ui/lab';
import { FormControlLabel, Checkbox, Button } from '@material-ui/core';

export const AcceptPolicies: React.FC<RouteComponentProps<{}>> = ({
  history
}) => {
  const [policiesAccepted, setPoliciesAccepted] = useState<boolean>(false);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<string>();

  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('The wrapping context was not correctly defined');
  }

  useEffect(() => {
    if (context.user.accepted_policies_at) {
      history.push('/');
    }
  }, []);

  const handleAgbAcceptedChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPoliciesAccepted(event.target.checked);
  };

  const onFormSubmit = async (event: any) => {
    event.preventDefault();
    setLoading(true);
    try {
      if (!policiesAccepted) {
        setError('Du musst die Allgemeinen Geschäftsbedingungen akzeptieren');
        setLoading(false);
      } else {
        await server.put(`/users/${context.user.id}`, {
          accepted_policies: true
        });
        history.push('/');
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      setError(error.message || error.common);
    }
  };

  return (
    <>
      <Loader active={isLoading} />
      {error && <Alert severity="error">{error}</Alert>}
      <form onSubmit={onFormSubmit}>
        <FormControlLabel
          control={
            <Checkbox
              checked={policiesAccepted}
              onChange={handleAgbAcceptedChange}
              name="agb"
              color="primary"
            />
          }
          label="Ich bestätige hiermit, dass ich die allgemeinen Geschäftsbedingungen von onlinesim.at gelesen habe und akzeptiere."
        />
        <div style={{ marginTop: 30 }}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
            disabled={isLoading}
          >
            AGBs akzeptieren
          </Button>
        </div>
      </form>
    </>
  );
};

export default withRouter(AcceptPolicies);
