import React, { useState } from 'react';
import PyroForm from 'pyro-form';
import { TextField } from '../../../components/primitives';
import {
  Section,
  SectionHeader,
  SectionContent
} from '../../../components/primitives/Section';
import {
  Button,
  Typography,
  MenuItem,
  TextField as MuiTextField
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { Switch } from '../../../components/primitives/Switch';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Employee } from '../../../types';

export interface FormData {
  active: boolean;
  salesforce_id?: number;
  provision_fixed: number;
  provision_topup_one: number;
  provision_topup_two: number;
  provision_topup_three: number;
  employee?: number;
}

export interface FormErrors {
  common?: string;
  active?: string;
  salesforce_id?: string;
  provision_fixed?: string;
  provision_topup_one?: string;
  provision_topup_two?: string;
  provision_topup_three?: string;
}

export interface FormProps {
  onSubmit: (data: FormData) => void;
  loading: boolean;
  errors?: FormErrors;
  data: FormData;
  employees: Array<Employee>;
  handleEmployeeSelect: (event: React.ChangeEvent<{ value: unknown }>) => void;
}

export const SettingsForm: React.FC<FormProps> = ({
  onSubmit,
  loading,
  errors,
  data,
  employees,
  handleEmployeeSelect
}) => {
  return (
    <PyroForm initialValues={data} onSubmit={onSubmit} errors={errors}>
      {({ handleSubmit, hasErrors }) => (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item sm={12} md={6}>
              <Section>
                <SectionHeader>
                  <Typography variant="h4">Account</Typography>
                </SectionHeader>
                <SectionContent>
                  <div style={{ marginBottom: 30 }}>
                    <Switch name="active" label="Aktiv" checked={data.active} />
                    <Typography variant="body1">
                      Wenn du den Account auf inaktiv stellst, kann sich der
                      Benutzer nicht mehr einloggen
                    </Typography>
                  </div>
                  <TextField<FormData>
                    name="provision_fixed"
                    type="text"
                    label="Provision Registrierung"
                    fullWidth
                    margin="normal"
                    required
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">€</InputAdornment>
                      )
                    }}
                  />
                  <TextField<FormData>
                    name="provision_topup_one"
                    type="text"
                    label="Provision 1. Topup"
                    fullWidth
                    margin="normal"
                    required
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">€</InputAdornment>
                      )
                    }}
                  />
                  <TextField<FormData>
                    name="provision_topup_two"
                    type="text"
                    label="Provision 2. Topup"
                    fullWidth
                    margin="normal"
                    required
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">€</InputAdornment>
                      )
                    }}
                  />
                  <TextField<FormData>
                    name="provision_topup_three"
                    type="text"
                    label="Provision 3. Topup"
                    fullWidth
                    margin="normal"
                    required
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">€</InputAdornment>
                      )
                    }}
                  />
                </SectionContent>
              </Section>
            </Grid>
            <Grid item sm={12} md={6}>
              <Section>
                <SectionHeader>
                  <Typography variant="h4">Salesforce</Typography>
                </SectionHeader>
                <SectionContent>
                  <TextField<FormData>
                    name="salesforce_id"
                    type="text"
                    label="ID"
                    fullWidth
                    margin="normal"
                    helperText="Mit dieser ID wird der Account mit Salesforce verbunden"
                  />
                </SectionContent>
              </Section>

              <Section>
                <SectionHeader>
                  <Typography variant="h4">Zuständiger Mitarbeiter</Typography>
                </SectionHeader>
                <SectionContent>
                  <MuiTextField
                    id="employee-select"
                    select
                    label="Mitarbeiter auswählen"
                    value={data.employee}
                    onChange={handleEmployeeSelect}
                    variant="outlined"
                    fullWidth
                  >
                    <MenuItem key={0} value={0}>
                      Kein Mitarbeiter zugewiesen
                    </MenuItem>
                    {employees.map(employee => (
                      <MenuItem key={employee.id} value={employee.id}>
                        {employee.firstname} {employee.lastname}
                      </MenuItem>
                    ))}
                  </MuiTextField>
                </SectionContent>
              </Section>

              <div style={{ marginTop: 20 }}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={loading}
                >
                  Speichern
                </Button>
              </div>
            </Grid>
          </Grid>
        </form>
      )}
    </PyroForm>
  );
};
