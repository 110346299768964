import MuiTextField, {
  OutlinedTextFieldProps as MuiTextFieldProps
} from '@material-ui/core/TextField';
import { PyroField, PyroFormValues } from 'pyro-form';
import React from 'react';

export interface TextFieldProps<Name extends string>
  extends Partial<MuiTextFieldProps> {
  name: Name;
}

export class TextField<
  Values extends PyroFormValues
> extends React.PureComponent<TextFieldProps<Extract<keyof Values, string>>> {
  public render() {
    const { variant = 'outlined', ...props } = this.props;
    return (
      <PyroField<Values> name={this.props.name}>
        {({ core, meta }) => (
          <MuiTextField
            {...core}
            {...props}
            error={meta.touched ? !!meta.error : undefined}
            helperText={meta.touched ? meta.error : props.helperText}
            variant={variant}
          />
        )}
      </PyroField>
    );
  }
}
