import React from 'react';
import { Typography } from '@material-ui/core';
import { Section } from '../../components/primitives/Section';
import { BalanceTransactionsList } from './BalanceTransactionsList';

export const BalanceTransactionManager: React.FC = () => {
  return (
    <>
      <Typography gutterBottom variant="h3">
        Konto Buchungen
      </Typography>
      <Section>
        <BalanceTransactionsList isAdmin={true} />
      </Section>
    </>
  );
};
