import React from 'react';
import { Typography, Button } from '@material-ui/core';
import { AuthConsumer } from '../auth/AuthContext';
import { Loader } from '../../components/common/Loader';
import { Section, SectionFooter } from '../../components/primitives/Section';
import { Card, CardContent, Grid, CardActions } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { AppConsumer } from '../AppContext';
import { AccountBalance } from '../../components/common/Balance/AccountBalance';
import { Theme } from '../../theme';
import { UserRole } from '../auth/types';
import { Link } from 'react-router-dom';
import VoucherImage from '../../assets/Onlinesim-eety-icon-05.jpg';
import SimcardImage from '../../assets/Onlinesim-eety-icon-04.jpg';
import CampaignImage from '../../assets/campaign/start.jpg';
import TotalBalance from './TotalBalance';
import CampaignLatest from '../campaign/CampaignLatest';

const useStyles = makeStyles((theme: Theme) => ({
  media: {
    minHeight: 164,
    width: '100%'
  },
  avatar: {
    backgroundColor: '#1C2D54'
  },
  section: {
    marginBottom: 20
  }
}));

const Dashboard: React.FC = () => {
  const classes = useStyles();
  return (
    <AuthConsumer>
      {({ user, isFetching }) => {
        if (user.role === UserRole.CUSTOMER) {
          return (
            <AppConsumer>
              {({ balance, isFetching: isAppContextFetching }) =>
                isFetching || isAppContextFetching || !balance ? (
                  <Loader active={isFetching} />
                ) : (
                  <Grid container spacing={4}>
                    <Grid item sm={12}>
                      <Typography gutterBottom variant="h1">
                        Willkommen {user.firstname}!
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Grid item sm={12} className={classes.section}>
                        <Section>
                          <AccountBalance balance={balance.amount} />
                          <SectionFooter>
                            <Button
                              variant="outlined"
                              color="primary"
                              component={Link}
                              to={`/balance`}
                            >
                              Informationen zum Guthaben
                            </Button>
                          </SectionFooter>
                        </Section>
                      </Grid>
                      <Grid container spacing={4}>
                        <Grid item md={6}>
                          <Card>
                            <CardContent style={{ padding: 5 }}>
                              <img
                                src={VoucherImage}
                                alt="vouchers"
                                style={{ height: '100%', width: '100%' }}
                              />
                            </CardContent>

                            <CardActions disableSpacing>
                              <Button
                                variant="outlined"
                                color="primary"
                                component={Link}
                                to={`/shop/vouchers`}
                              >
                                Voucher kaufen
                              </Button>
                            </CardActions>
                          </Card>
                        </Grid>
                        <Grid item md={6}>
                          <Card>
                            <CardContent style={{ padding: 5 }}>
                              <img
                                src={SimcardImage}
                                alt="simcards"
                                style={{ height: '100%', width: '100%' }}
                              />
                            </CardContent>

                            <CardActions disableSpacing>
                              <Button
                                variant="outlined"
                                color="primary"
                                component={Link}
                                to={`/shop/simcard`}
                              >
                                Starterpakete kaufen
                              </Button>
                            </CardActions>
                          </Card>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item sm={6}>
                      <CampaignLatest />
                    </Grid>
                  </Grid>
                )
              }
            </AppConsumer>
          );
        } else {
          return (
            <Grid container spacing={4}>
              <Grid item sm={12}>
                <Typography gutterBottom variant="h1">
                  Willkommen {user.firstname}!
                </Typography>
              </Grid>
              <Grid item sm={4}>
                <TotalBalance />
              </Grid>
            </Grid>
          );
        }
      }}
    </AuthConsumer>
  );
};

export default Dashboard;
