import React, { useState, useEffect } from 'react';
import { server, defaultErrorMessage } from '../../utils/server';
import { Typography, Grid, Card, CardContent } from '@material-ui/core';
import { Loader } from '../../components/common/Loader';
import { Alert, AlertType } from '../../components/primitives/Alert';
import { Campaign } from '../../types';

const CampaignLatest: React.FC = () => {
  const [data, setData] = useState<Array<Campaign>>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [loadingError, setLoadingError] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    fetchData().then(setData);
  }, []);

  const fetchData = async (): Promise<Array<Campaign>> => {
    setLoading(true);
    try {
      const res = await server.get('/campaigns?active=true');
      console.log(res.data.rows);
      setLoading(false);
      return res.data.rows;
    } catch (error) {
      setLoading(false);
      setLoadingError(defaultErrorMessage);
      return [];
    }
  };

  const renderRow = (row: Campaign) => {
    return (
      <Grid item sm={12}>
        <Card style={{ padding: 0 }}>
          <CardContent style={{ padding: 0, paddingBottom: -5 }}>
            <img
              src={row.image.location}
              alt="simcards"
              style={{ height: '100%', width: '100%' }}
            />
            {row.text && (
              <Typography variant="h5" style={{ margin: 10 }}>
                {row.text}
              </Typography>
            )}
          </CardContent>
        </Card>
      </Grid>
    );
  };

  return (
    <>
      {loadingError && (
        <Alert type={AlertType.ERROR} headline="Unerwarteter Fehler">
          <Typography paragraph color="inherit">
            {loadingError}
          </Typography>
        </Alert>
      )}
      {isLoading ? (
        <Loader active={isLoading} width={100} />
      ) : (
        <Grid container spacing={4}>
          {data.map(renderRow)}
        </Grid>
      )}
    </>
  );
};

export default CampaignLatest;
