import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import CoverPhoto from '../../../assets/login2.jpeg';
import Logo from '../../../assets/OnlineSIM_360_with_logo.jpg';

import { Theme } from '../../../theme';
import AuthRouting from '../AuthRouting';

const useStyles = makeStyles((theme: Theme) => ({
  fullScreen: {
    minHeight: '100vh',
    width: '100%'
  },
  leftColumn: {
    minHeight: '95vh',
    marginBottom: 20,
    flexGrow: 1,
    backgroundColor: '#fff'
  },
  rightColumn: {
    background: `url(${CoverPhoto}) 70% 0`,
    backgroundSize: 'cover',
    flexGrow: 1
  },
  test: {
    minHeight: '90vh'
  },
  logo: {
    maxWidth: 300,
    marginBottom: 100
  }
}));

const AuthLayout: React.FC = () => {
  const classes = useStyles();
  return (
    <Grid container direction="row" className={classes.fullScreen}>
      <Grid item sm={6} className={classes.leftColumn}>
        <Grid
          container
          direction="column"
          alignItems="center"
          justify="center"
          className={classes.test}
        >
          <Grid item style={{ maxWidth: 400 }}>
            <img src={Logo} alt="logo" className={classes.logo} />
            <AuthRouting />
          </Grid>
        </Grid>
      </Grid>
      <Grid item sm={6} className={classes.rightColumn}>
        <Grid item />
      </Grid>
    </Grid>
  );
};

export default AuthLayout;
