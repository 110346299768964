import React from 'react';
import PyroForm from 'pyro-form';
import {
  TextField,
  Alert,
  AlertType,
  Select
} from '../../../components/primitives';
import { Button, Typography } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import { ProductType } from '../../../types';

export interface FormData {
  name: string;
  ean: string;
  type: string;
  price: string;
  price_nominale: string;
  logo: string;
  tax_rate: string;
}

export interface FormErrors extends FormData {
  common?: string;
}

export interface FormProps {
  onSubmit: (data: FormData) => void;
  loading: boolean;
  errors?: FormErrors;
  data: FormData;
}

export const ProductForm: React.FC<FormProps> = ({
  onSubmit,
  loading,
  errors,
  data
}) => {
  return (
    <PyroForm initialValues={data} onSubmit={onSubmit} errors={errors}>
      {({ handleSubmit, hasErrors }) => (
        <form onSubmit={handleSubmit}>
          <TextField<FormData>
            name="name"
            type="text"
            label="Produktname"
            fullWidth
            margin="normal"
            required
          />
          <TextField<FormData>
            name="ean"
            type="text"
            label="EAN Code"
            fullWidth
            margin="normal"
            required
          />
          <TextField<FormData>
            name="price"
            type="text"
            label="EK Preis Händler"
            fullWidth
            margin="normal"
            required
          />
          <TextField<FormData>
            name="price_nominale"
            type="text"
            label="Nominale"
            fullWidth
            margin="normal"
            required
          />
          <TextField<FormData>
            name="logo"
            type="text"
            label="Logo"
            fullWidth
            margin="normal"
          />
          <Select<FormData> name="type" label="Produkttyp" fullWidth id="type">
            <MenuItem key={ProductType.SIMCARD} value={ProductType.SIMCARD}>
              Simkarte
            </MenuItem>
            <MenuItem key={ProductType.VOUCHER} value={ProductType.VOUCHER}>
              Voucher
            </MenuItem>
            <MenuItem key={ProductType.RISKFREE} value={ProductType.RISKFREE}>
              Riskfree-Simkarte
            </MenuItem>
          </Select>
          <Select<FormData>
            name="tax_rate"
            label="Steuersatz"
            fullWidth
            id="tax_rate"
          >
            <MenuItem value={'0'}>0%</MenuItem>
            <MenuItem value={'10'}>10%</MenuItem>
            <MenuItem value={'20'}>20%</MenuItem>
          </Select>
          {errors && errors.common && (
            <Alert type={AlertType.ERROR}>
              <Typography paragraph color="inherit">
                {errors.common}
              </Typography>
            </Alert>
          )}
          <Button
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
            disabled={loading}
          >
            Speichern
          </Button>
        </form>
      )}
    </PyroForm>
  );
};
