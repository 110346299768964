import React, { useState } from 'react';
import { server } from '../../utils/server';
import { Typography } from '@material-ui/core';
import { Loader } from '../../components/common/Loader';
import { ProductForm, FormErrors, FormData } from './components/ProductForm';
import { sanitize } from '../../utils/helper';
import { ProductType } from '../../types';

interface ProductCreateProps {
  onSuccess: () => void;
}

const ProductCreate: React.FC<ProductCreateProps> = ({ onSuccess }) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<FormErrors | undefined>(undefined);
  const [formData, setFormData] = useState<FormData>({
    name: '',
    ean: '',
    price: '0',
    price_nominale: '0',
    tax_rate: '0',
    logo: '',
    type: ProductType.SIMCARD
  });

  const onSubmit = async (data: FormData) => {
    setLoading(true);
    try {
      const res = await server.post('/products', {
        // @ts-ignore
        ...sanitize(data, ['price', 'price_nominale', 'tax_rate']),
        origin: 'ONLINESIM',
        price: Math.round(Number(data.price) * 100),
        price_nominale: Math.round(Number(data.price_nominale) * 100),
        tax_rate: Number(data.tax_rate)
      });
      setLoading(false);
      onSuccess();
    } catch (error) {
      setLoading(false);
      setFormData(data);
      setError(error);
    }
  };

  return (
    <>
      <Typography variant="h3">Produkt anlegen</Typography>
      {isLoading && <Loader active={isLoading} width={100} />}
      <>
        <ProductForm
          onSubmit={onSubmit}
          loading={isLoading}
          errors={error}
          data={formData}
        />
      </>
    </>
  );
};

export default ProductCreate;
