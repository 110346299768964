import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  RouteProps
} from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';
import theme from './theme';
import { AuthProvider, AuthConsumer } from './pages/auth/AuthContext';
import Layout from './components/common/Layout';
import AuthLayout from './pages/auth/components/AuthLayout';
import CssBaseline from '@material-ui/core/CssBaseline';

class App extends Component {
  render() {
    return (
      <>
        <CssBaseline />
        <ThemeProvider theme={theme}>
          <Router>
            <AuthProvider>
              <Switch>
                <Route path="/login" component={AuthLayout} />
                <Route path="/logout" component={AuthLayout} />
                <Route path="/reset" component={AuthLayout} />
                <Route path="/accept-policies" component={AuthLayout} />
                <PrivateRoute path="/" component={Layout} />
              </Switch>
            </AuthProvider>
          </Router>
        </ThemeProvider>
      </>
    );
  }
}

export default App;

const PrivateRoute: React.FC<RouteProps> = ({
  component: Component,
  ...rest
}) => (
  <AuthConsumer>
    {({ isAuthenticated }) => (
      <Route
        {...rest}
        render={props =>
          isAuthenticated ? (
            // @ts-ignore
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: props.location }
              }}
            />
          )
        }
      />
    )}
  </AuthConsumer>
);
