export enum UserRole {
  EMPLOYEE = 'employee',
  ADMIN = 'admin',
  CUSTOMER = 'customer'
}

export interface User {
  id: number;
  firstname: string;
  lastname: string;
  avatar?: string;
  role: UserRole;
  accepted_policies_at: Date | null;
}
