import React, { useState } from 'react';
import { server } from '../../../utils/server';
import { Loader } from '../../../components/common/Loader';
import Button from '../../../components/primitives/Button';
import { FormattedDate } from '../../../components/primitives';
import {
  Typography,
  Table,
  TableRow,
  TableBody,
  TableCell
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { StockTransaction, StockTransactionState } from '../../../types';

interface StockTransactionCancelProps {
  onSuccess: () => void;
  stockTransaction: StockTransaction;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    btn: {
      marginTop: theme.spacing(1),
      width: '100%'
    }
  })
);

export const StockTransactionCancel: React.FC<StockTransactionCancelProps> = ({
  onSuccess,
  stockTransaction
}) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [reason, setReason] = useState<string | undefined>('');
  const classes = useStyles();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    try {
      await server.post(
        `/stock/transactions/${stockTransaction.uuid}/cancellations`
      );
      setLoading(false);
      onSuccess();
    } catch (error) {
      setLoading(false);
      setError(error.common || error.message);
    }
  };

  const declineCancellation = async () => {
    setLoading(true);
    try {
      await server.put(`/stock/transactions/${stockTransaction.uuid}`, {
        state: StockTransactionState.CONFIRMED
      });
      setLoading(false);
      onSuccess();
    } catch (error) {
      setLoading(false);
      setError(error.common || error.message);
    }
  };

  return (
    <>
      <Typography variant="h3">Voucher Transaktion stornieren</Typography>
      {error && <Alert severity="error">{error}</Alert>}
      {isLoading && <Loader active={isLoading} width={100} />}

      <Table size="medium" aria-label="voucher_details">
        <TableBody>
          <TableRow>
            <TableCell>Kunde</TableCell>
            <TableCell align="right">
              {stockTransaction.customer!.name}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Produkt</TableCell>
            <TableCell align="right">
              {stockTransaction.voucher!.product.name}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Seriennummer</TableCell>
            <TableCell align="right">
              {stockTransaction.voucher!.serial}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>verkauft am</TableCell>
            <TableCell align="right">
              <FormattedDate
                date={stockTransaction.created_at}
                wrapped={false}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Stornierungsgrund</TableCell>
            <TableCell align="right">
              {stockTransaction.cancellation_reason}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <Alert severity="info">
        Bei einer erfolgreichen Stornierung wird der Betrag auf das Konto des
        Kunden gutgeschrieben.
      </Alert>
      <form onSubmit={handleSubmit} style={{ marginTop: 30 }}>
        {stockTransaction.state ===
          StockTransactionState.CANCELLATION_REQUESTED && (
          <Button
            variant="outlined"
            color="secondary"
            className={classes.btn}
            fullWidth={false}
            onClick={() => declineCancellation()}
          >
            Ablehnen
          </Button>
        )}
        <Button
          variant="outlined"
          color="primary"
          type="submit"
          fullWidth={false}
          className={classes.btn}
        >
          Bestätigen
        </Button>
      </form>
    </>
  );
};
