import React from 'react';
import {
  Theme,
  createStyles,
  makeStyles,
  withStyles
} from '@material-ui/core/styles';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Grid,
  Paper
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { ShopContext, CartItem } from '../ShopContext';
import { AppContext } from '../../AppContext';
import { mapToArray } from '../../../utils/helper';
import { Currency } from '../../../components/primitives';
import { Customer } from '../../../types';

interface CheckoutSummaryProps {
  checkoutError?: string;
  customer: Customer;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: 20
  },
  sum: {
    borderTopStyle: 'solid',
    borderTopColor: '#444',
    borderTopWidth: 2,
    fontWeight: 700,
    fontSize: 16,
    borderBottomWidth: 0
  },
  presum: {
    borderTopStyle: 'solid',
    borderTopColor: '#444',
    borderTopWidth: 2
  },
  table: {
    '& > td': {
      padding: 30
    }
  },
  buttonRoot: {
    marginTop: 30,
    '& > *': {
      margin: theme.spacing(1)
    }
  }
}));

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    body: {
      paddingTop: 10,
      paddingBottom: 10,
      paddingLeft: 5,
      paddingRight: 5
    }
  })
)(TableCell);

export const CheckoutSummary: React.FC<CheckoutSummaryProps> = ({
  checkoutError,
  customer
}) => {
  const classes = useStyles();
  return (
    <ShopContext.Consumer>
      {({
        cart,
        calculateCartSum,
        calculateCartItemSum,
        deliveryFeeLimit,
        deliveryFee,
        chargeDeliveryFee
      }) => (
        <AppContext.Consumer>
          {({ balance }) => (
            <div className={classes.root}>
              <Grid container spacing={5}>
                <Grid item md={12}>
                  <Typography variant="h3">Deine Bestellung</Typography>
                </Grid>
                <Grid item md={8}>
                  <Paper className={classes.paper}>
                    <Table className={classes.table}>
                      <TableBody>
                        {mapToArray<CartItem>(cart.items).map(item => (
                          <TableRow key={item.productUuid}>
                            <StyledTableCell>{`${item.quantity} x ${
                              item.text
                            }`}</StyledTableCell>
                            <StyledTableCell align="right">
                              <Currency value={item.netUnit} />
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              <Currency value={item.netUnit * item.quantity} />
                            </StyledTableCell>
                          </TableRow>
                        ))}
                        <TableRow>
                          <StyledTableCell
                            className={classes.presum}
                            colSpan={2}
                          >
                            Zwischensumme
                          </StyledTableCell>
                          <StyledTableCell
                            className={classes.presum}
                            align="right"
                          >
                            <Currency
                              value={calculateCartItemSum()}
                              wrapped={false}
                            />
                          </StyledTableCell>
                        </TableRow>
                        <TableRow>
                          <StyledTableCell colSpan={2}>
                            Zustellungsgebühr
                            <Typography variant="body2">
                              Zustellung ab{' '}
                              <Currency
                                value={deliveryFeeLimit}
                                wrapped={false}
                              />{' '}
                              gratis!
                            </Typography>
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            <Currency
                              value={chargeDeliveryFee() ? deliveryFee : 0}
                              wrapped={false}
                            />
                          </StyledTableCell>
                        </TableRow>
                        <TableRow>
                          <StyledTableCell className={classes.sum} colSpan={2}>
                            Summe
                          </StyledTableCell>
                          <StyledTableCell
                            className={classes.sum}
                            align="right"
                          >
                            <Currency
                              value={calculateCartSum()}
                              wrapped={false}
                            />
                          </StyledTableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Paper>
                </Grid>
                <Grid item md={4}>
                  <div style={{ marginBottom: 50 }}>
                    <Typography variant="h4">Dein Guthaben</Typography>
                    <Table className={classes.table}>
                      <TableBody>
                        <TableRow key="current_balance">
                          <StyledTableCell>Aktuelles Guthaben</StyledTableCell>
                          <StyledTableCell align="right">
                            <Currency value={balance!.amount} />
                          </StyledTableCell>
                        </TableRow>
                        <TableRow>
                          <StyledTableCell>
                            Betrag der Bestellung
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            <Currency
                              value={calculateCartSum()}
                              wrapped={false}
                            />
                          </StyledTableCell>
                        </TableRow>
                        <TableRow>
                          <StyledTableCell className={classes.sum}>
                            Verbleibendes Guthaben
                          </StyledTableCell>
                          <StyledTableCell
                            className={classes.sum}
                            align="right"
                          >
                            <Currency
                              value={balance!.amount - calculateCartSum()}
                              wrapped={false}
                            />
                          </StyledTableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </div>
                  <div>
                    <Typography variant="h4">Deine Lieferadresse</Typography>
                    <Typography variant="body1">
                      {customer.business_name}
                      <br />
                      {customer.address_street}
                      <br />
                      {customer.address_zip} {customer.address_city}
                    </Typography>
                  </div>
                </Grid>
                <Grid item md={12}>
                  {checkoutError && (
                    <Alert severity="error">{checkoutError}</Alert>
                  )}
                </Grid>
              </Grid>
            </div>
          )}
        </AppContext.Consumer>
      )}
    </ShopContext.Consumer>
  );
};
