import React from 'react';
import PyroForm from 'pyro-form';
import { TextField, Alert, AlertType } from '../../../components/primitives';
import { Button, Typography } from '@material-ui/core';

export interface FormData {
  email: string;
  firstname: string;
  lastname: string;
}

export interface EmployeeFormErrors {
  email?: string;
  firstname?: string;
  lastname?: string;
  common?: string;
}

export interface EmployeeFormProps {
  onSubmit: (data: FormData) => void;
  loading: boolean;
  errors?: EmployeeFormErrors;
}

const initialValues: FormData = { email: '', firstname: '', lastname: '' };

export const EmployeeForm: React.FC<EmployeeFormProps> = ({
  onSubmit,
  loading,
  errors
}) => {
  return (
    <PyroForm initialValues={initialValues} onSubmit={onSubmit} errors={errors}>
      {({ handleSubmit, hasErrors }) => (
        <form onSubmit={handleSubmit}>
          <TextField<FormData>
            name="email"
            type="email"
            label="E-Mail Adresse *"
            fullWidth
            margin="normal"
          />
          <TextField<FormData>
            name="firstname"
            type="text"
            label="Vorname *"
            fullWidth
            margin="normal"
          />
          <TextField<FormData>
            name="lastname"
            type="text"
            label="Nachname *"
            fullWidth
            margin="normal"
          />
          {errors && errors.common && (
            <Alert type={AlertType.ERROR}>
              <Typography paragraph color="inherit">
                {errors.common}
              </Typography>
            </Alert>
          )}
          <Button
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
            disabled={loading}
          >
            Speichern
          </Button>
        </form>
      )}
    </PyroForm>
  );
};
