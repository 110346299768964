import React, { useState, useEffect } from 'react';
import { server, defaultErrorMessage } from '../../utils/server';
import { Loader } from '../../components/common/Loader';
import { SettingsForm, FormErrors, FormData } from './components/SettingsForm';
import { sanitize } from '../../utils/helper';
import ActionFeedback from '../../components/patterns/ActionFeedback';
import { Employee } from '../../types';

interface CustomerSettingsProps {
  data: FormData;
  customerUuid: string;
}

const CustomerSettings: React.FC<CustomerSettingsProps> = ({
  data,
  customerUuid
}) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<FormErrors | undefined>(undefined);
  const [success, setSuccess] = useState<boolean>(false);
  const [formData, setFormData] = useState<FormData>(data);
  const [employees, setEmployees] = useState<Array<Employee>>([]);

  useEffect(() => {
    fetchData().then(data => setEmployees(data));
  }, []);

  const fetchData = async (): Promise<Array<Employee>> => {
    setLoading(true);
    try {
      const res = await server.get('/users', {
        params: {
          role: 'employee'
        }
      });
      console.log(res);
      setLoading(false);
      return res.data;
    } catch (error) {
      setLoading(false);
      return [];
    }
  };

  const onSubmit = async (data: FormData) => {
    setLoading(true);
    console.log({ data, formData });
    try {
      const res = await server.put(`/customers/${customerUuid}`, {
        // @ts-ignore
        ...sanitize({
          ...data,
          provision_fixed: data.provision_fixed * 100,
          provision_topup_one: data.provision_topup_one * 100,
          provision_topup_two: data.provision_topup_two * 100,
          provision_topup_three: data.provision_topup_three * 100,
          employee: formData.employee
        })
      });
      setLoading(false);
      setSuccess(true);
    } catch (error) {
      setLoading(false);
      setFormData(data);
      setError(error);
    }
  };

  const onCloseActionFeedback = () => {
    setSuccess(false);
    setError(undefined);
  };

  const handleEmployeeSelect = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setFormData({ ...formData, employee: event.target.value as number });
  };

  return (
    <>
      {isLoading && <Loader active={isLoading} width={100} />}
      <>
        <SettingsForm
          onSubmit={onSubmit}
          loading={isLoading}
          errors={error}
          data={formData}
          employees={employees}
          handleEmployeeSelect={handleEmployeeSelect}
        />
        <ActionFeedback
          message="Einstellungen gespeichert"
          visible={success}
          onClose={onCloseActionFeedback}
          type="success"
        />
        {error && (
          <ActionFeedback
            message={error.common || 'Ein Fehler ist aufgetreten'}
            type="error"
            visible={true}
            onClose={onCloseActionFeedback}
          />
        )}
      </>
    </>
  );
};

export default CustomerSettings;
