import React, { useEffect, useState } from 'react';
import { ShopContext } from '../ShopContext';
import { Alert } from '@material-ui/lab';
import { Print as PrintIcon } from '@material-ui/icons';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  IconButton
} from '@material-ui/core';
import {
  Order,
  StockTransaction,
  Product,
  ProductType,
  OrderItem
} from '../../../types';
import { ActionDrawer } from '../../../components/patterns';
import { Loader } from '../../../components/common/Loader';
import { VoucherPrint } from '../../stock/voucher/VoucherPrint';

interface CheckoutStatusProps {
  checkoutError?: string;
  order: Order;
  isLoading: boolean;
}

interface StockTransactionWithProduct extends StockTransaction {
  product: Pick<Product, 'name' | 'uuid'>;
}

export const CheckoutStatus: React.FC<CheckoutStatusProps> = ({
  checkoutError,
  order,
  isLoading
}) => {
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [activeStockTransaction, setActiveStockTransaction] = useState<
    string | undefined
  >(undefined);
  const [success, setSuccess] = useState<boolean>(false);

  const toggleDrawer = () => setDrawerOpen(!drawerOpen);
  const onCloseActionSuccess = () => setSuccess(false);
  const printAction = (stockTransactionUuid: string) => {
    setDrawerOpen(true);
    setActiveStockTransaction(stockTransactionUuid);
  };

  const onSuccess = () => {
    setDrawerOpen(false);
    setSuccess(true);
  };

  useEffect(() => {
    if (order && order.items) {
      const voucherTransactions = getVoucherTransactions();
      if (voucherTransactions.length > 0) {
        printAction(voucherTransactions[0].uuid);
      }
    }
  }, [order]);

  const getVoucherTransactions = (): Array<StockTransactionWithProduct> => {
    const voucherStockTransactions: Array<StockTransactionWithProduct> = [];
    for (const item of order.items) {
      if (item.stock_transactions) {
        voucherStockTransactions.push(
          ...item.stock_transactions
            .filter(t => t.voucher)
            .map(t => ({ ...t, product: item.product }))
        );
      }
    }
    return voucherStockTransactions;
  };

  const getSimcardOrderItems = (): Array<OrderItem> => {
    return order.items.filter(
      item => item.product.type === ProductType.SIMCARD
    );
  };

  return (
    <ShopContext.Consumer>
      {({ cart }) => (
        <div>
          <Typography variant="h3">Deine Bestellung</Typography>
          {checkoutError && <Alert severity="error">{checkoutError}</Alert>}
          {!checkoutError && !isLoading && (
            <Alert severity="success">
              <Typography variant="h4" style={{ marginBottom: 0 }}>
                Deine Bestellung war erfolgreich
              </Typography>
            </Alert>
          )}
          {isLoading && <Loader active={isLoading} width={100} />}
          {order && getVoucherTransactions().length > 0 && (
            <>
              <Typography style={{ marginTop: 30 }} variant="h5">
                Gekaufte Voucher
              </Typography>
              <Table>
                <TableBody>
                  {getVoucherTransactions().map(transaction => (
                    <TableRow key={transaction.uuid}>
                      <TableCell component="th" scope="row">
                        {transaction.product.name}
                      </TableCell>
                      <TableCell align="right">
                        Seriennummer: {transaction.voucher!.serial}
                      </TableCell>
                      <TableCell align="right">
                        <IconButton
                          size="small"
                          aria-label="voucher drucken"
                          color="secondary"
                          onClick={() => printAction(transaction.uuid)}
                        >
                          <PrintIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </>
          )}

          {order && getSimcardOrderItems().length > 0 && (
            <>
              <Typography style={{ marginTop: 30 }} variant="h5">
                Gekaufte Simkarten
              </Typography>
              <Table>
                <TableBody>
                  {getSimcardOrderItems().map(orderItem => (
                    <TableRow key={orderItem.uuid}>
                      <TableCell component="th" scope="row">
                        {orderItem.number} x {orderItem.product.name}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <Alert severity="info">
                Die bestellen Simkarten werden dir per Post zugeschickt oder von
                einem Mitarbeiter von OnlineSim.at geliefert.
              </Alert>
            </>
          )}

          <ActionDrawer
            open={drawerOpen}
            onClose={() => toggleDrawer()}
            width={470}
          >
            <VoucherPrint
              onSuccess={onSuccess}
              stockTransactionUuid={activeStockTransaction!}
              isDuplicate={false}
            />
          </ActionDrawer>
        </div>
      )}
    </ShopContext.Consumer>
  );
};
