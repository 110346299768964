import React, { useState, useEffect } from 'react';
import { TopupTransaction, TopupState, Topup } from './types';
import { Table, TableCell } from '../../components/primitives/Table';
import {
  TableRow,
  IconButton,
  Table as MuiTable,
  TableHead,
  TableBody,
  Drawer,
  Typography
} from '@material-ui/core';
import { Currency } from '../../components/primitives';
import Loader from 'react-content-loader';
import { RemoveCircle, Edit, Save } from '@material-ui/icons';
import { Theme } from '../../theme';
import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/styles';
import { server } from '../../utils/server';
import ActionFeedback from '../../components/patterns/ActionFeedback';
import RemoveDialog from './components/RemoveTransactionDialog';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Collapse from '@material-ui/core/Collapse';
import EditTopupTransaction from './EditTopupTransaction';
import ActionDrawer from '../../components/patterns/ActionDrawer';

interface TopupTransactionListProps {
  topup: Topup;
}

interface GroupedTopupTransaction {
  customer: string;
  transactions: Array<TopupTransaction>;
  sum: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  editableRow: {
    backgroundColor: grey[100]
  },
  textfield: {
    backgroundColor: '#fff'
  }
}));

const TopupTransactionList: React.FC<TopupTransactionListProps> = ({
  topup
}) => {
  const [transactions, setTransactions] = useState<
    Array<GroupedTopupTransaction>
  >([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [editableRow, setEditableRow] = useState<TopupTransaction | null>(null);
  const [editedData, setEditedData] = useState<{ amount: string } | undefined>(
    undefined
  );
  const [removeDialogOpen, setRemoveDialogOpen] = useState<boolean>(false);
  const [removeableRow, setRemoveableRow] = useState<
    TopupTransaction | undefined
  >(undefined);
  const [actionFeedback, setActionFeedback] = useState<string | undefined>(
    undefined
  );
  const classes = useStyles();

  useEffect(() => {
    fetchData();
  }, [topup]);

  const onCloseActionSuccess = () => setActionFeedback(undefined);

  const onEditSuccess = () => {
    fetchData();
    setActionFeedback('Die Transaktion wurde erfolgreich bearbeitet');
    setEditableRow(null);
  };

  const toggleEditMode = (transaction: TopupTransaction | null) => {
    setEditableRow(transaction);
    if (transaction) {
      setEditedData({ amount: String(transaction.amount / 100) });
    }
  };

  const removeTransaction = async () => {
    if (!removeableRow) {
      setRemoveableRow(undefined);
      setRemoveDialogOpen(false);
      return;
    }
    try {
      await server.delete(
        `/topups/${topup.uuid}/transactions/${removeableRow.uuid}`
      );
      fetchData();
      setActionFeedback('Die Transaktion wurde erfolgreich entfernt');
      setRemoveableRow(undefined);
      setRemoveDialogOpen(false);
    } catch (error) {
      console.log({ error });
    }
  };

  const openRemoveDialog = (transaction: TopupTransaction) => {
    setRemoveableRow(transaction);
    setRemoveDialogOpen(true);
  };

  const groupTransactionByCustomer = (
    transactions: Array<TopupTransaction>
  ): Array<GroupedTopupTransaction> => {
    const groupedTransactions: Array<GroupedTopupTransaction> = [];
    for (const transaction of transactions) {
      const existingCustomer = groupedTransactions.find(
        entry => entry.customer === transaction.customer.business_name
      );
      if (existingCustomer) {
        existingCustomer.sum = existingCustomer.sum + transaction.amount;
        existingCustomer.transactions.push(transaction);
      } else {
        groupedTransactions.push({
          customer: transaction.customer.business_name,
          sum: transaction.amount,
          transactions: [transaction]
        });
      }
    }
    return groupedTransactions;
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await server.get(`/topups/${topup.uuid}/transactions`);
      console.log(res.data);
      setTransactions(groupTransactionByCustomer(res.data));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  function TopupTransactionRow(props: { row: GroupedTopupTransaction }) {
    const { row } = props;
    const [open, setOpen] = useState<boolean>(false);

    return (
      <>
        <TableRow key={row.customer}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell>{row.customer ? row.customer : '-'}</TableCell>
          <TableCell>{`${row.transactions.length} Topups`}</TableCell>
          <TableCell align="right">
            <Currency wrapped={false} value={row.sum} />
          </TableCell>
        </TableRow>
        <TableRow key={`${row.customer}-transactions`}>
          <TableCell
            style={{ paddingBottom: 0, paddingTop: 0, backgroundColor: '#eee' }}
            colSpan={6}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <MuiTable aria-label="purchases">
                <TableHead>
                  <TableRow key={`${row.customer}-transactions-header`}>
                    <TableCell>Topup</TableCell>
                    <TableCell>Simkarte</TableCell>
                    <TableCell align="right">Provision</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>{row.transactions.map(renderRow)}</TableBody>
              </MuiTable>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  }

  const renderTopupTransactionRow = (row: GroupedTopupTransaction) => (
    <TopupTransactionRow row={row} key={row.customer} />
  );

  const renderRow = (row: TopupTransaction) => (
    <TableRow key={row.uuid}>
      <TableCell>{row.type}</TableCell>
      <TableCell> {row.simcard ? row.simcard.imei : '-'}</TableCell>
      <TableCell align="right">
        <Currency wrapped={false} value={row.amount} />
      </TableCell>
      <TableCell align="right">
        {row.state === 'PENDING' && (
          <>
            <IconButton
              color="default"
              size="small"
              onClick={() => toggleEditMode(row)}
            >
              <Edit />
            </IconButton>
            <IconButton
              color="default"
              size="small"
              onClick={() => openRemoveDialog(row)}
            >
              <RemoveCircle />
            </IconButton>
          </>
        )}
      </TableCell>
    </TableRow>
  );

  return (
    <>
      {isLoading ? (
        <SkeletonLoader />
      ) : (
        <>
          <Table
            headers={[
              { label: '' },
              { label: 'Kunde' },
              { label: 'Anzahl Topups' },
              { label: 'Betrag', align: 'right' }
            ]}
            rows={transactions}
            // @ts-ignore
            renderRow={renderTopupTransactionRow}
            noBorder={true}
          />
          <RemoveDialog
            open={removeDialogOpen}
            handleClose={setRemoveDialogOpen}
            handleAgree={removeTransaction}
            transaction={removeableRow}
          />
          <ActionFeedback
            message={actionFeedback!}
            visible={Boolean(actionFeedback)}
            onClose={onCloseActionSuccess}
            type="success"
          />
          <ActionDrawer
            open={Boolean(editableRow)}
            onClose={() => toggleEditMode(null)}
          >
            {editableRow && (
              <EditTopupTransaction
                transaction={editableRow!}
                topup={topup}
                onSuccess={onEditSuccess}
              />
            )}
          </ActionDrawer>
        </>
      )}
    </>
  );
};

export const SkeletonLoader: React.FC = () => {
  return (
    <Loader ariaLabel="Provisionen werden geladen" height={100}>
      <rect x="0" y="0" rx="4" ry="4" width="200" height="15" />
      <rect x="210" y="0" rx="4" ry="4" width="100" height="15" />
      <rect x="320" y="0" rx="4" ry="4" width="70" height="15" />
      <rect x="0" y="20" rx="4" ry="4" width="200" height="15" />
      <rect x="210" y="20" rx="4" ry="4" width="100" height="15" />
      <rect x="320" y="20" rx="4" ry="4" width="70" height="15" />
      <rect x="0" y="40" rx="4" ry="4" width="200" height="15" />
      <rect x="210" y="40" rx="4" ry="4" width="100" height="15" />
      <rect x="320" y="40" rx="4" ry="4" width="70" height="15" />
    </Loader>
  );
};

export default TopupTransactionList;
