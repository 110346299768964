import React, { useState, useEffect } from 'react';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  IconButton
} from '@material-ui/core';
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles
} from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import { SimCard as SimcardIcon } from '@material-ui/icons';
import { Loader } from '../../components/common/Loader';
import { FormattedDate, Chip, Currency } from '../../components/primitives';
import {
  Order,
  StockTransaction,
  Simcard,
  Voucher,
  OrderState,
  OrderItem,
  mapOrderState
} from '../../types';
import { server } from '../../utils/server';
import { mapOrderStateToChipType } from './OrderList';
import { OrderCancellationConfirmation } from './OrderCancellationConfirmation';

interface OrderDetailsProps {
  onSuccess: () => void;
  onSimcardAssignment: (orderItem: OrderItem) => void;
  orderUuid: string;
  isAdmin?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      backgroundColor: theme.palette.background.paper
    },
    table: {
      '& > td': {
        padding: 5
      }
    },
    sum: {
      borderTopStyle: 'solid',
      borderTopColor: '#444',
      borderTopWidth: 2,
      fontWeight: 700
    }
  })
);

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    body: {
      paddingTop: 10,
      paddingBottom: 10,
      paddingLeft: 5,
      paddingRight: 5
    }
  })
)(TableCell);

export const OrderDetails: React.FC<OrderDetailsProps> = ({
  orderUuid,
  onSuccess,
  onSimcardAssignment,
  isAdmin = false
}) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [loadingError, setLoadingError] = useState<string | undefined>(
    undefined
  );
  const [error, setError] = useState<string | undefined>(undefined);
  const [order, setOrder] = useState<Order | undefined>(undefined);
  const [
    orderCancellationDialogOpen,
    setOrderCancellationDialogOpen
  ] = useState<boolean>(false);
  const [stockTransactions, setStockTransactions] = useState<
    Array<StockTransaction>
  >([]);
  const classes = useStyles();

  useEffect(() => {
    fetchData().then(data => {
      setOrder(data);
      setStockTransactions(getStockTransactionsOfOrder(data!));
    });
  }, []);

  const getStockTransactionsOfOrder = (
    order: Order
  ): Array<StockTransaction> => {
    const stockTransactions: Array<StockTransaction> = [];
    for (const item of order.items) {
      if (item.stock_transactions)
        stockTransactions.push(...item.stock_transactions);
    }
    return stockTransactions;
  };

  const fetchData = async (): Promise<Order | undefined> => {
    setLoading(true);
    try {
      const res = await server.get(`/orders/${orderUuid}`);
      setLoading(false);
      return res.data;
    } catch (error) {
      setLoading(false);
      setLoadingError('Fehler beim Ladens des Vouchers');
      return undefined;
    }
  };

  const assignSimcardsOfOrderItem = async (orderItem: OrderItem) => {
    setLoading(true);
    onSimcardAssignment(orderItem);
  };

  const cancelOrder = async (orderUuid: string) => {
    try {
      setLoading(true);
      const res = await server.post(`/orders/${orderUuid}/cancellations`);
      const data = await fetchData();
      setOrder(data);
      setStockTransactions(getStockTransactionsOfOrder(data!));
      setLoading(false);
      console.log({ res });
      onSuccess();
    } catch (error) {
      setLoading(false);
      setLoadingError(error.common);
    }
  };

  return (
    <>
      <Typography variant="h3">
        Bestellung {order && `#${order.number}`}
      </Typography>
      {loadingError && <Alert severity="error">{loadingError}</Alert>}
      {isLoading && <Loader active={isLoading} width={100} />}
      {order && (
        <>
          <Table aria-label="orders" className={classes.table}>
            <TableBody>
              <TableRow key="date">
                <TableCell component="th" scope="row">
                  Datum
                </TableCell>
                <TableCell align="right">
                  <FormattedDate date={order.created_at} wrapped={false} />
                </TableCell>
              </TableRow>
              {isAdmin && (
                <TableRow key="customer">
                  <TableCell component="th" scope="row">
                    Kunde
                  </TableCell>
                  <TableCell align="right">{order.customer.name}</TableCell>
                </TableRow>
              )}
              <TableRow key="state">
                <TableCell component="th" scope="row">
                  Status
                </TableCell>
                <TableCell align="right">
                  <Chip
                    type={mapOrderStateToChipType(order.state)}
                    label={mapOrderState(order.state)}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>

          {[
            OrderState.DELIVERED,
            OrderState.RECEIVED,
            OrderState.IN_PROGRESS
          ].includes(order.state) &&
            isAdmin && (
              <div style={{ marginTop: 20, marginBottom: 20 }}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setOrderCancellationDialogOpen(true)}
                >
                  Bestellung stornieren
                </Button>
                <OrderCancellationConfirmation
                  open={orderCancellationDialogOpen}
                  handleClose={() => setOrderCancellationDialogOpen(false)}
                  handleConfirm={() => {
                    cancelOrder(order.uuid);
                    setOrderCancellationDialogOpen(false);
                  }}
                />
              </div>
            )}

          <Typography variant="h5" style={{ marginTop: 40 }}>
            Warenkorb
          </Typography>
          <Table aria-label="orders-items" className={classes.table}>
            <TableBody>
              {order.items.map(item => (
                <TableRow key={item.uuid}>
                  <StyledTableCell>
                    {item.state === OrderState.RECEIVED && isAdmin && (
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => assignSimcardsOfOrderItem(item)}
                      >
                        <SimcardIcon />
                      </Button>
                    )}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {item.number} x {item.name}
                  </StyledTableCell>
                  <StyledTableCell>
                    <Chip
                      type={mapOrderStateToChipType(item.state)}
                      label={mapOrderState(item.state)}
                      size="small"
                    />
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <Currency value={item.amount_gross} wrapped={false} />
                  </StyledTableCell>
                </TableRow>
              ))}
              <TableRow key="sum">
                <StyledTableCell colSpan={3} className={classes.sum}>
                  Summe
                </StyledTableCell>
                <StyledTableCell align="right" className={classes.sum}>
                  <Currency value={order.amount_gross} wrapped={false} />
                </StyledTableCell>
              </TableRow>
            </TableBody>
          </Table>

          {stockTransactions.length > 0 && (
            <>
              <Typography variant="h5" style={{ marginTop: 40 }}>
                Gekaufte Produkte
              </Typography>
              <Table aria-label="orders-vouchers" className={classes.table}>
                <TableBody>
                  {stockTransactions.map(st => (
                    <>
                      {st.simcard && (
                        <TableRow key={st.uuid}>
                          <StyledTableCell>Simkarte</StyledTableCell>
                          <StyledTableCell component="th" scope="row">
                            {st.simcard.product.name}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {st.simcard.imei}
                          </StyledTableCell>
                        </TableRow>
                      )}
                      {st.voucher && (
                        <TableRow key={st.uuid}>
                          <StyledTableCell>Voucher</StyledTableCell>
                          <StyledTableCell component="th" scope="row">
                            {st.voucher.product.name}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {st.voucher.serial}
                          </StyledTableCell>
                        </TableRow>
                      )}
                    </>
                  ))}
                </TableBody>
              </Table>
            </>
          )}
        </>
      )}
    </>
  );
};
