import React from 'react';
import {
  Section,
  SectionHeader,
  SectionContent,
  SectionFooter,
  Currency,
  FormattedDate,
  Table,
  TableCell,
  Chip
} from '../../../components/primitives';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '../../../theme';
import { Typography, TableRow, Button } from '@material-ui/core';

interface Transaction {
  date: string;
  uuid: string;
  amount: number;
  description: string;
}

interface AccountBalanceTransactionsProperties {
  transactions: Array<Transaction>;
}

const useStyles = makeStyles((theme: Theme) => ({
  tableCell: {
    fontSize: 14
  }
}));

export const AccountBalanceTransactions: React.FC<
  AccountBalanceTransactionsProperties
> = ({ transactions }) => {
  const classes = useStyles();

  const renderRow = (row: Transaction) => (
    <TableRow key={row.uuid}>
      <TableCell component="th" scope="row" className={classes.tableCell}>
        <FormattedDate date={row.date} showTime={true} />
      </TableCell>
      <TableCell className={classes.tableCell}>{row.description}</TableCell>
      <TableCell align="right" className={classes.tableCell}>
        {row.amount > 0 ? (
          <Chip
            label={<Currency value={row.amount} wrapped={false} />}
            type="success"
          />
        ) : (
          <Chip
            label={<Currency value={row.amount} wrapped={false} />}
            type="error"
          />
        )}
      </TableCell>
    </TableRow>
  );

  return (
    <Section>
      <SectionContent noPadding={true}>
        <Table
          headers={[
            { label: 'Datum', align: 'left' },
            { label: 'Beschreibung', align: 'left' },
            { label: 'Betrag', align: 'right' }
          ]}
          rows={transactions}
          // @ts-ignore
          renderRow={renderRow}
          noBorder={true}
        />
      </SectionContent>
    </Section>
  );
};
