import React, { useState, useEffect } from 'react';
import { server, defaultErrorMessage } from '../../utils/server';
import { Typography, Chip } from '@material-ui/core';
import { Loader } from '../../components/common/Loader';
import { Alert, AlertType } from '../../components/primitives/Alert';
import Fab from '@material-ui/core/Fab';
import EditIcon from '@material-ui/icons/Edit';
import { Table, TableCell } from '../../components/primitives/Table';
import TableRow from '@material-ui/core/TableRow';
import { Campaign } from '../../types';

interface CampaignListProps {
  refreshData: boolean;
  setRefreshData: (status: boolean) => void;
  editAction: (campaign: Campaign) => void;
}

const CampaignList: React.FC<CampaignListProps> = ({
  refreshData,
  setRefreshData,
  editAction
}) => {
  const [data, setData] = useState<Array<Campaign>>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [loadingError, setLoadingError] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    if (refreshData) {
      setRefreshData(false);
      fetchData().then(data => setData(data));
    }
  }, [[refreshData]]);

  const fetchData = async (): Promise<Array<Campaign>> => {
    setLoading(true);
    try {
      const res = await server.get('/campaigns');
      console.log(res.data.rows);
      setLoading(false);
      return res.data.rows;
    } catch (error) {
      setLoading(false);
      setLoadingError(defaultErrorMessage);
      return [];
    }
  };

  const renderRow = (row: Campaign) => {
    return (
      <TableRow key={row.uuid}>
        <TableCell component="th" scope="row">
          {row.title}
          {row.active ? (
            <Chip size="small" label="Aktiv" color="primary" />
          ) : (
            <Chip size="small" label="Deaktiviert" color="default" />
          )}
        </TableCell>
        <TableCell align="right">
          <Fab
            size="small"
            aria-label="Edit"
            color="secondary"
            onClick={() => editAction(row)}
          >
            <EditIcon />
          </Fab>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <>
      <Typography variant="h2" gutterBottom>
        Kampagnen
      </Typography>
      {loadingError && (
        <Alert type={AlertType.ERROR} headline="Unerwarteter Fehler">
          <Typography paragraph color="inherit">
            {loadingError}
          </Typography>
        </Alert>
      )}
      {isLoading ? (
        <Loader active={isLoading} width={100} />
      ) : (
        <Table
          headers={[{ label: 'Title' }, { label: '', align: 'right' }]}
          rows={data}
          // @ts-ignore
          renderRow={renderRow}
        />
      )}
    </>
  );
};

export default CampaignList;
