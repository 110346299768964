import React from 'react';
import { Product, ProductType } from '../../../types';
import { Typography, GridList, GridListTile, Button } from '@material-ui/core';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { Currency } from '../../../components/primitives';
import { Alert } from '@material-ui/lab';

interface ProductListProps {
  data: Array<Product>;
  headline: string;
  btnLabel?: string;
  onSelectProduct: (product: Product) => void;
}

interface ProductListItemProps {
  product: Product;
  btnLabel: string;
  onSelectProduct: (product: Product) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      overflow: 'hidden',
      marginBottom: 40
    },
    gridList: {
      width: '100%',
      height: 'auto'
    },
    gridListItem: {
      marginRight: 10,
      marginBottom: 10
    },
    gridListTileBar: {
      fontSize: 12,
      position: 'relative',
      backgroundColor: 'rgba(15, 38, 77, 0.9)',
      borderTopStyle: 'solid',
      borderTopWidth: 3,
      borderTopColor: 'rgba(15, 38, 77, 1)'
    },
    button: {
      fontSize: 12,
      width: '100%'
    }
  })
);

const ProductListItem: React.FC<ProductListItemProps> = ({
  product,
  onSelectProduct,
  btnLabel
}) => {
  const classes = useStyles();

  return (
    <GridListTile key={product.uuid} cols={1} className={classes.gridListItem}>
      {product.logo && (
        <img
          src={product.logo}
          alt={product.name}
          width={200}
          style={{ height: 'auto' }}
        />
      )}
      <div style={{ textAlign: 'center', padding: 10 }}>
        <Typography
          variant="body1"
          style={{
            paddingBottom: 5,
            marginBottom: 5,
            borderBottomWidth: 1,
            borderBottomColor: '#ccc',
            borderBottomStyle: 'solid'
          }}
        >
          {product.name}
        </Typography>
        <Typography variant="body1" style={{ fontWeight: 500, fontSize: 16 }}>
          <Currency value={product.price_nominale} wrapped={false} />
        </Typography>
      </div>
      <Button
        className={classes.button}
        color="primary"
        variant="outlined"
        size="small"
        disabled={
          [ProductType.SIMCARD, ProductType.VOUCHER].includes(product.type) &&
          product.stock_count === 0
        }
        startIcon={<AddShoppingCartIcon />}
        onClick={() => onSelectProduct(product)}
      >
        {btnLabel}
      </Button>
      {[ProductType.SIMCARD, ProductType.VOUCHER].includes(product.type) &&
        product.stock_count === 0 && (
          <Alert severity="error">Aktuell nicht verfügbar</Alert>
        )}
    </GridListTile>
  );
};

export const ProductList: React.FC<ProductListProps> = ({
  headline,
  data,
  onSelectProduct,
  btnLabel = 'In den Warenkorb'
}) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.root}>
        <GridList cellHeight={150} className={classes.gridList} cols={5}>
          {data.map(product => (
            <ProductListItem
              key={product.uuid}
              product={product}
              onSelectProduct={onSelectProduct}
              btnLabel={btnLabel}
            />
          ))}
        </GridList>
      </div>
    </>
  );
};
