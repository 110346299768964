import React, { useState, useEffect } from 'react';
import {
  Typography,
  Select,
  FormControl,
  InputLabel,
  MenuItem
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Loader } from '../../../components/common/Loader';
import { server } from '../../../utils/server';
import Button from '../../../components/primitives/Button';
import { Product } from '../../../types';

interface VoucherImportProps {
  onSuccess: () => void;
}

export const VoucherImport: React.FC<VoucherImportProps> = ({ onSuccess }) => {
  const [error, setError] = useState<string | undefined>(undefined);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [progress, setProgress] = useState<number>(0);
  const [file, setFile] = useState<File | undefined>(undefined);
  const [products, setProducts] = useState<Array<Product>>([]);
  const [selectedProduct, setSelectedProduct] = useState('');

  useEffect(() => {
    fetchProducts().then(products => setProducts(products));
  }, []);
  const fetchProducts = async (): Promise<Array<Product>> => {
    setLoading(true);
    try {
      const res = await server.get('/products?type=VOUCHER');
      setLoading(false);
      return res.data;
    } catch (error) {
      setLoading(false);
      return [];
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setError(undefined);

    if (!selectedProduct) {
      setError('Du musst ein Produkt auswählen');
      return;
    }

    setLoading(true);
    console.log({ selectedProduct, file });

    if (file) {
      const formData = new FormData();
      formData.append('file', file, file.name);
      formData.append('productUuid', selectedProduct);
      try {
        const res = await server.post('/stock/vouchers', formData, {
          onUploadProgress: progressEvent => {
            setProgress(
              Math.floor((progressEvent.loaded * 100) / progressEvent.total)
            );
            console.log({ progress });
          }
        });
        setLoading(false);
        console.log({ res });
        onSuccess();
      } catch (error) {
        setLoading(false);
        setError(error.common);
      }
    } else {
      setError('Bitte wähle eine Datei aus!');
      setLoading(false);
    }
  };

  const handleFileSelect = (files: FileList | null) => {
    if (files) {
      console.log(files);
      setFile(files[0]);
    }
  };

  const handleProductSelect = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setSelectedProduct(event.target.value as string);
  };

  return (
    <>
      <Typography variant="h3">Voucher importieren</Typography>
      {error && <Alert severity="error">{error}</Alert>}
      {isLoading ? (
        <Loader active={isLoading} width={100} />
      ) : (
        <form onSubmit={handleSubmit}>
          <FormControl
            variant="outlined"
            style={{ width: '100%', marginTop: 10, marginBottom: 10 }}
          >
            <InputLabel id="demo-simple-select-outlined-label">
              Produkt auswählen
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={selectedProduct}
              onChange={handleProductSelect}
              label="Age"
            >
              {products.map(product => (
                <MenuItem key={product.uuid} value={product.uuid}>
                  {product.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl
            style={{ width: '100%', marginTop: 10, marginBottom: 30 }}
          >
            <Typography variant="h5">CSV-Datei auswählen</Typography>
            <Alert severity="info">
              <AlertTitle>Example:</AlertTitle>
              serialnr;chargingbon <br />
              12345678910;123456
            </Alert>
            <input
              accept="text/csv"
              id="file"
              onChange={e => handleFileSelect(e.target.files)}
              multiple
              type="file"
            />
          </FormControl>
          <Button
            variant="outlined"
            color="primary"
            type="submit"
            style={{ width: '100%' }}
          >
            Voucher importieren
          </Button>
        </form>
      )}
    </>
  );
};
