import React from 'react';
import Typography from '@material-ui/core/Typography';

interface CurrencyProps {
  value: number;
  wrapped?: boolean;
}

export const Currency: React.FC<CurrencyProps> = ({
  value,
  wrapped = true
}) => {
  const output = `${(Number(value) / 100).toFixed(2)} €`.replace('.', ',');
  if (wrapped) {
    return <Typography>{output}</Typography>;
  } else {
    return <>{output}</>;
  }
};
