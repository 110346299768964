import React, { useState, useEffect } from 'react';
import { server, defaultErrorMessage } from '../../utils/server';
import { Typography, Chip } from '@material-ui/core';
import { Loader } from '../../components/common/Loader';
import { Alert, AlertType } from '../../components/primitives/Alert';
import Fab from '@material-ui/core/Fab';
import EditIcon from '@material-ui/icons/Edit';
import { Table, TableCell } from '../../components/primitives/Table';
import TableRow from '@material-ui/core/TableRow';
import { Product, ProductType } from '../../types';

interface ProductListProps {
  refreshData: boolean;
  setRefreshData: (status: boolean) => void;
  editAction: (productUuid: string) => void;
}

const ProductList: React.FC<ProductListProps> = ({
  refreshData,
  setRefreshData,
  editAction
}) => {
  const [data, setData] = useState<Array<Product>>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [loadingError, setLoadingError] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    if (refreshData) {
      setRefreshData(false);
      fetchData().then(data => setData(data));
    }
  }, [[refreshData]]);

  const fetchData = async (): Promise<Array<Product>> => {
    setLoading(true);
    try {
      const res = await server.get('/products');
      setLoading(false);
      return res.data;
    } catch (error) {
      setLoading(false);
      setLoadingError(defaultErrorMessage);
      return [];
    }
  };

  const renderRow = (row: Product) => {
    return (
      <TableRow key={row.uuid}>
        <TableCell component="th" scope="row">
          {row.name}
          {!row.active && (
            <Chip size="small" label="Deaktiviert" color="secondary" />
          )}
        </TableCell>
        <TableCell>{row.type}</TableCell>
        <TableCell>{`${row.price_nominale / 100} €`}</TableCell>
        <TableCell>
          {row.type === ProductType.VOUCHER && row.stock_count}
        </TableCell>
        <TableCell>
          <Fab
            size="small"
            aria-label="Edit"
            color="secondary"
            onClick={() => editAction(row.uuid)}
          >
            <EditIcon />
          </Fab>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <>
      <Typography variant="h2" gutterBottom>
        Produkte
      </Typography>
      {loadingError && (
        <Alert type={AlertType.ERROR} headline="Unerwarteter Fehler">
          <Typography paragraph color="inherit">
            {loadingError}
          </Typography>
        </Alert>
      )}
      {isLoading ? (
        <Loader active={isLoading} width={100} />
      ) : (
        <Table
          headers={[
            { label: 'Name' },
            { label: 'Typ' },
            { label: 'Nominale' },
            { label: 'Lagerstand' },
            { label: '', align: 'right' }
          ]}
          rows={data}
          // @ts-ignore
          renderRow={renderRow}
        />
      )}
    </>
  );
};

export default ProductList;
