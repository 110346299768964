import React from 'react';
import { Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '../../../theme';
import { Currency } from '../../primitives';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: '#0F254B',
    padding: 30,
    textAlign: 'center'
  },
  content: {
    color: theme.palette.common.white
  }
}));

interface AccountBalanceProperties {
  balance: number;
}

export const AccountBalance: React.FC<AccountBalanceProperties> = ({
  balance
}) => {
  const classes = useStyles();
  return (
    <Paper className={classes.root} elevation={0}>
      <Typography variant="body2" className={classes.content}>
        DEIN GUTHABEN
      </Typography>
      <Typography variant="h1" className={classes.content}>
        <Currency value={balance} wrapped={false} />
      </Typography>
    </Paper>
  );
};
