import React, { StatelessComponent } from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '../../../theme';
import classNames from 'classnames';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import PendingIcon from '@material-ui/icons/Refresh';
import { green, yellow, red } from '@material-ui/core/colors';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    flexDirection: 'column',
    border: '1px solid #eee'
  },
  sectionHeader: {
    alignItems: 'center',
    borderBottom: `1px solid #eee`,
    borderTopLeftRadius: '2px',
    borderTopRightRadius: '2px',
    display: 'flex',
    height: '64px',
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    position: 'relative'
  },
  sectionHeaderSuccess: {
    color: '#fff',
    backgroundColor: green[600]
  },
  sectionHeaderPending: {
    color: '#fff',
    backgroundColor: yellow[600]
  },
  sectionHeaderError: {
    color: '#fff',
    backgroundColor: red[600]
  },
  sectionFooter: {
    alignItems: 'center',
    borderTop: `1px solid #eee`,
    borderTopLeftRadius: '2px',
    borderTopRightRadius: '2px',
    display: 'flex',
    height: '64px',
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    position: 'relative'
  },
  sectionContent: (props: SectionContentProps) => ({
    paddingLeft: props.noPadding ? 0 : theme.spacing(3),
    paddingRight: props.noPadding ? 0 : theme.spacing(3),
    paddingTop: props.noPadding ? 0 : theme.spacing(2),
    paddingBottom: props.noPadding ? 0 : theme.spacing(2),
    flexGrow: 1
  })
}));

export const Section: React.FC = ({ children, ...rest }) => {
  const classes = useStyles({});
  return (
    <Paper className={classes.root} elevation={0} square={false} {...rest}>
      {children}
    </Paper>
  );
};

export type SectionHeaderState =
  | 'success'
  | 'pending'
  | 'error'
  | 'approved'
  | 'aborted';
interface SectionHeaderProperties {
  state?: SectionHeaderState;
}

export const SectionHeader: React.FC<SectionHeaderProperties> = ({
  state,
  children,
  ...rest
}) => {
  const classes = useStyles({});
  switch (state) {
    case 'success':
    case 'approved':
      return (
        <div
          {...rest}
          className={classNames(
            classes.sectionHeader,
            classes.sectionHeaderSuccess
          )}
        >
          {children}
          <CheckCircleIcon />
        </div>
      );
    case 'error':
    case 'aborted':
      console.log('render error header');
      return (
        <div
          {...rest}
          className={classNames(
            classes.sectionHeader,
            classes.sectionHeaderError
          )}
        >
          {children}
          <RemoveCircleIcon />
        </div>
      );
    case 'pending':
      return (
        <div
          {...rest}
          className={classNames(
            classes.sectionHeader,
            classes.sectionHeaderPending
          )}
        >
          {children}
          <PendingIcon />
        </div>
      );
    default:
      return (
        <div {...rest} className={classes.sectionHeader}>
          {children}
        </div>
      );
  }
};

interface SectionContentProps {
  noPadding?: boolean;
}

export const SectionContent: React.FC<SectionContentProps> = ({
  noPadding = false,
  children,
  ...rest
}) => {
  const classes = useStyles({ noPadding });
  return (
    <div {...rest} className={classes.sectionContent}>
      {children}
    </div>
  );
};

export const SectionFooter: React.FC = ({ children, ...rest }) => {
  const classes = useStyles({});
  return (
    <div {...rest} className={classes.sectionFooter}>
      {children}
    </div>
  );
};
