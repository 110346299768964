import React from 'react';
import { Typography } from '@material-ui/core';
import { AuthConsumer } from '../auth/AuthContext';
import { Loader } from '../../components/common/Loader';
import { Section } from '../../components/primitives/Section';
import { AccountBalance } from '../../components/common/Balance/AccountBalance';
import { BalanceTransactionsList } from './BalanceTransactionsList';
import { AppConsumer } from '../AppContext';

export const CustomerBalance: React.FC = () => {
  return (
    <AuthConsumer>
      {({ user, isFetching }) => (
        <AppConsumer>
          {({ customer, balance, isFetching: isAppContextFetching }) =>
            isFetching || isAppContextFetching ? (
              <Loader active={isFetching} />
            ) : (
              <>
                <Typography gutterBottom variant="h3">
                  Deine Konto Buchungen
                </Typography>
                {user.role === 'customer' && balance && (
                  <Section>
                    <AccountBalance balance={balance.amount} />
                    <BalanceTransactionsList
                      isAdmin={user.role !== 'customer'}
                    />
                  </Section>
                )}
              </>
            )
          }
        </AppConsumer>
      )}
    </AuthConsumer>
  );
};
