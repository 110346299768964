export enum TopupState {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  ABORTED = 'ABORTED',
  IN_PROGRESS = 'IN_PROGRESS'
}

export interface Topup {
  uuid: string;
  date: string;
  transactions: Array<TopupTransaction>;
  state: TopupState;
  type: TopupType;
}

export enum TopupType {
  ACTIVATION = 'ACTIVATION',
  TOPUP_ONE = 'TOPUP_1',
  TOPUP_TWO = 'TOPUP_2',
  TOPUP_THREE = 'TOPUP_3'
}

export interface TopupTransaction {
  uuid: string;
  customer: {
    uuid: string;
    business_name: string;
    salesforce_id: number;
    provision_fixed: number;
  };
  simcard?: {
    uuid: string;
    imei: string;
  };
  amount: number;
  type: TopupType;
  state: TopupState;
}
