import React, { useState, useEffect } from 'react';
import { IconButton } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Cancel as CancelIcon } from '@material-ui/icons';
import {
  server,
  defaultErrorMessage,
  stringifyQuery
} from '../../utils/server';
import {
  RiskfreeTransaction,
  RiskfreeTransactionState,
  RiskfreeTransactionType,
  Product
} from '../../types';
import {
  DataTable,
  FormattedDate,
  Chip,
  ChipType
} from '../../components/primitives';
import { Loader } from '../../components/common/Loader';

interface RiskfreeTransactionListProps {
  isAdmin?: boolean;
  refreshData: boolean;
  setRefreshData: (status: boolean) => void;
  cancelAction: (riskfreeTransaction: RiskfreeTransaction) => void;
  selectedState?: RiskfreeTransactionState;
}

interface FetchDataOptions {
  filter?: RiskfreeTransactionListFilter;
}

interface RiskfreeTransactionListFilter {
  product_id?: string;
  serial?: string;
  state?: RiskfreeTransactionState;
  type?: RiskfreeTransactionType;
}

export const RiskfreeTransactionList: React.FC<
  RiskfreeTransactionListProps
> = ({
  refreshData,
  setRefreshData,
  isAdmin = false,
  cancelAction,
  selectedState
}) => {
  const [data, setData] = useState<Array<RiskfreeTransaction>>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [hpp, setHpp] = useState<number>(20);
  const [filter, setFilter] = useState<RiskfreeTransactionListFilter>({});
  const [loadingError, setLoadingError] = useState<string | undefined>(
    undefined
  );
  const [products, setProducts] = useState<Array<Product>>([]);

  useEffect(() => {
    Promise.all([fetchData({ filter: {} }), fetchProducts()]).then(res => {
      setData(res[0]);
      setProducts(res[1]);
    });
  }, []);

  useEffect(() => {
    if (refreshData) {
      setRefreshData(false);
      fetchData({}).then(setData);
    }
  }, [[refreshData]]);

  const fetchProducts = async (): Promise<Array<Product>> => {
    setLoading(true);
    try {
      const res = await server.get(`/products?type=RISKFREE`);
      setLoading(false);
      return res.data;
    } catch (error) {
      setLoading(false);
      return [];
    }
  };

  const fetchData = async ({
    filter = {}
  }: FetchDataOptions): Promise<Array<RiskfreeTransaction>> => {
    setLoading(true);
    try {
      if (selectedState) filter.state = selectedState;
      const queryString = stringifyQuery(filter);
      const res = await server.get(
        `/riskfree-transactions${queryString && '?' + queryString}`
      );
      setLoading(false);
      return res.data;
    } catch (error) {
      setLoading(false);
      setLoadingError(defaultErrorMessage);
      return [];
    }
  };

  const handleFilterSubmit = (applyFilters: () => Array<any>): void => {
    const filterList = applyFilters();
    const filter: RiskfreeTransactionListFilter = {};
    filter.serial = filterList[2].length ? filterList[2][0] : undefined;
    if (filterList[1].length) {
      const product = products.find(
        product => product.name === filterList[1][0]
      );
      filter.product_id = product ? product.uuid : undefined;
    }
    filter.type = filterList[3].length ? filterList[3][0] : undefined;
    filter.state = filterList[4].length ? filterList[4][0] : undefined;
    fetchData({ filter }).then(setData);
  };

  const getColumns = (products: Array<Product>) => [
    {
      name: 'date',
      label: 'Durchgeführt am',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'product',
      label: 'Produkt',
      options: {
        filter: true,
        filterType: 'dropdown' as any,
        filterOptions: { names: products.map(p => p.name), fullWidth: true },
        customFilterListOptions: { render: (v: string) => `Produkt: ${v}` },
        sort: true
      }
    },
    {
      name: 'serial',
      label: 'SERIENNUMMER',
      options: {
        filter: true,
        filterType: 'textField' as any,
        filterOptions: {
          fullWidth: true
        },
        sort: true
      }
    },
    {
      name: 'type',
      label: 'Typ',
      options: {
        filter: true,
        filterType: 'dropdown' as any,
        filterOptions: {
          names: [
            RiskfreeTransactionType.ACTIVATION,
            RiskfreeTransactionType.CANCELLATION
          ],
          fullWidth: true
        },
        customFilterListOptions: {
          render: (v: RiskfreeTransactionState) => `Typ: ${v}`
        },
        sort: true
      }
    },
    {
      name: 'state',
      label: 'Status',
      options: {
        filter: true,
        filterType: 'dropdown' as any,
        filterOptions: {
          names: [
            RiskfreeTransactionState.SUCCEEDED,
            RiskfreeTransactionState.FAILED,
            RiskfreeTransactionState.IN_PROGRESS
          ],
          fullWidth: true
        },
        customFilterListOptions: {
          render: (v: RiskfreeTransactionState) => `Status: ${v}`
        },
        sort: true
      }
    },
    {
      name: 'action',
      label: 'Aktionen',
      options: {
        filter: false,
        sort: false
      }
    }
  ];

  const prepareRows = (
    rows: Array<RiskfreeTransaction>,
    isAdmin: boolean
  ): Array<Array<any>> => {
    const optimizedRows = rows.map(row => ({
      date: <FormattedDate date={row.created_at} wrapped={false} />,
      product: row.product.name,
      serial: row.simcard_serial,
      type: row.type,
      state: (
        <Chip
          size="small"
          label={mapTransactionStateToReadable(row.state)}
          type={mapTransactionStateToChipType(row.state)}
        />
      ),
      action: row.type === RiskfreeTransactionType.ACTIVATION &&
        row.state === RiskfreeTransactionState.SUCCEEDED && (
          <>
            <IconButton
              size="small"
              aria-label="Stornierung beantragen"
              color="secondary"
              onClick={() => cancelAction(row)}
            >
              <CancelIcon />
            </IconButton>
          </>
        )
    }));
    return optimizedRows.map(row => Object.values(row));
  };

  const mapTransactionStateToChipType = (
    state: RiskfreeTransactionState
  ): ChipType => {
    switch (state) {
      case RiskfreeTransactionState.SUCCEEDED:
        return 'success';
      case RiskfreeTransactionState.FAILED:
        return 'error';
      case RiskfreeTransactionState.IN_PROGRESS:
        return 'info';
      default:
        return 'error';
    }
  };

  const mapTransactionStateToReadable = (
    state: RiskfreeTransactionState
  ): string => {
    switch (state) {
      case RiskfreeTransactionState.SUCCEEDED:
        return 'ERFOLGREICH';
      case RiskfreeTransactionState.FAILED:
        return 'FEHLGESCHLAGEN';
      case RiskfreeTransactionState.IN_PROGRESS:
        return 'IN BEARBEITUNG';
      default:
        return 'unknown';
    }
  };

  return (
    <>
      {loadingError && <Alert severity="error">{loadingError}</Alert>}
      {isLoading && <Loader active={isLoading} width={100} />}
      <DataTable
        title={''}
        data={prepareRows(data, isAdmin)}
        columns={getColumns(products)}
        handleFilterSubmit={handleFilterSubmit}
        filterEnabled={true}
        searchEnabled={false}
        options={{
          page,
          count: hpp,
          onChangePage: async (page: number) => {
            setPage(page);
          }
        }}
      />
    </>
  );
};
