import React, { useState, useEffect } from 'react';
import { Typography, IconButton } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Cancel as CancelIcon } from '@material-ui/icons';
import {
  server,
  defaultErrorMessage,
  stringifyQuery
} from '../../../utils/server';
import {
  Customer,
  SimcardAssignment,
  SimcardAssignmentState
} from '../../../types';
import {
  DataTable,
  FormattedDate,
  Chip,
  ChipType
} from '../../../components/primitives';
import { Loader } from '../../../components/common/Loader';

interface SimcardAssignmentListProps {
  isAdmin?: boolean;
  refreshData: boolean;
  setRefreshData: (status: boolean) => void;
  cancelAction: (assignmentUuid: string) => void;
}

interface FetchDataOptions {
  filter?: SimcardAssignmentListFilter;
}

interface SimcardAssignmentListFilter {
  state?: 'ASSIGNED' | 'CANCELLED';
  customerName?: string;
}

export const SimcardAssignmentList: React.FC<SimcardAssignmentListProps> = ({
  refreshData,
  setRefreshData,
  cancelAction,
  isAdmin = false
}) => {
  const [data, setData] = useState<Array<SimcardAssignment>>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [page, setPage] = useState<number>(0);
  const [hpp, setHpp] = useState<number>(20);
  const [loadingError, setLoadingError] = useState<string | undefined>(
    undefined
  );
  const [customers, setCustomers] = useState<Array<Customer>>([]);

  useEffect(() => {
    fetchCustomers().then(customers => setCustomers(customers));
  }, []);
  const fetchCustomers = async (): Promise<Array<Customer>> => {
    setLoading(true);
    try {
      const res = await server.get('/customers?limit=1000');
      setLoading(false);
      return res.data.rows;
    } catch (error) {
      setLoading(false);
      return [];
    }
  };

  useEffect(() => {
    if (refreshData) {
      setRefreshData(false);
      fetchData({}).then(data => setData(data));
    }
  }, [[refreshData]]);

  const fetchData = async ({
    filter = {}
  }: FetchDataOptions): Promise<Array<SimcardAssignment>> => {
    setLoading(true);
    try {
      const queryString = stringifyQuery(filter);
      const res = await server.get(
        `/stock/simcard-assignments${queryString && '?' + queryString}`
      );
      setLoading(false);
      return res.data;
    } catch (error) {
      setLoading(false);
      setLoadingError(defaultErrorMessage);
      return [];
    }
  };

  const handleFilterSubmit = (applyFilters: () => Array<any>): void => {
    const filterList = applyFilters();
    const filter: SimcardAssignmentListFilter = {};
    filter.customerName = filterList[1].length ? filterList[1][0] : undefined;
    filter.state = filterList[4].length ? filterList[4][0] : undefined;
    fetchData({ filter }).then(setData);
  };

  const getColumns = (customers: Array<Customer>) => [
    {
      name: 'date',
      label: 'Zugewiesen am',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'customer',
      label: 'Kunde',
      options: {
        filter: true,
        filterType: 'dropdown' as any,
        filterOptions: {
          names: customers.map(p => p.business_name),
          fullWidth: true
        },
        customFilterListOptions: { render: (v: string) => `Kunde: ${v}` },
        sort: true
      }
    },
    {
      name: 'created_by',
      label: 'Mitarbeiter',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'product',
      label: 'Produkt',
      options: {
        filter: false,
        sort: false
      }
    },
    {
      name: 'state',
      label: 'Status',
      options: {
        filter: true,
        filterType: 'dropdown' as any,
        filterOptions: {
          names: [
            SimcardAssignmentState.ASSIGNED,
            SimcardAssignmentState.CANCELLED
          ],
          fullWidth: true
        },
        customFilterListOptions: {
          render: (v: SimcardAssignmentState) => `Status: ${v}`
        },
        sort: true
      }
    },
    {
      name: 'action',
      label: 'Aktionen',
      options: {
        filter: false,
        sort: false
      }
    }
  ];

  const mapVoucherStateToChipType = (
    state: SimcardAssignmentState
  ): ChipType => {
    switch (state) {
      case SimcardAssignmentState.CANCELLED:
        return 'error';
      case SimcardAssignmentState.ASSIGNED:
        return 'success';
        return 'info';
    }
  };

  const prepareRows = (rows: Array<SimcardAssignment>): Array<Array<any>> => {
    console.log(rows);
    const optimizedRows = rows.map(row => ({
      date: <FormattedDate date={row.created_at} wrapped={false} />,
      customer: row.customer.business_name,
      created_by: `${row.created_by.firstname} ${row.created_by.lastname}`,
      product: `${row.product.name} (${row.imei_from} - ${row.imei_until})`,
      state: (
        <Chip
          size="small"
          label={row.state}
          type={mapVoucherStateToChipType(row.state)}
        />
      ),
      action: (
        <IconButton
          size="small"
          aria-label="stornieren"
          color="secondary"
          onClick={() => cancelAction(row.uuid)}
        >
          <CancelIcon />
        </IconButton>
      )
    }));
    return optimizedRows.map(row => Object.values(row));
  };

  return (
    <>
      {loadingError && <Alert severity="error">{loadingError}</Alert>}
      {isLoading && <Loader active={isLoading} width={100} />}
      <DataTable
        title={''}
        data={prepareRows(data)}
        columns={getColumns(customers)}
        handleFilterSubmit={handleFilterSubmit}
        filterEnabled={true}
        searchEnabled={false}
        options={{
          page,
          count: hpp,
          onChangePage: async (page: number) => {
            setPage(page);
          }
        }}
      />
    </>
  );
};
