import React, { useRef, useState, useEffect } from 'react';
import ReactToPrint from 'react-to-print';
// @ts-ignore
import Barcode from 'react-barcode';
import styled from 'styled-components';
import { Alert } from '@material-ui/lab';
import { Button, Typography } from '@material-ui/core';
import { server } from '../../../utils/server';
import { Voucher } from '../../../types';
import { Loader } from '../../../components/common/Loader';

interface VoucherPrintProps {
  onSuccess: () => void;
  stockTransactionUuid: string;
  isDuplicate?: boolean;
}

export const VoucherPrint: React.FC<VoucherPrintProps> = ({
  onSuccess,
  stockTransactionUuid,
  isDuplicate = true
}) => {
  const componentRef = useRef(null);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [loadingError, setLoadingError] = useState<string | undefined>(
    undefined
  );
  const [voucher, setVoucher] = useState<Voucher | undefined>(undefined);

  useEffect(() => {
    fetchData().then(setVoucher);
  }, []);

  const fetchData = async (): Promise<Voucher | undefined> => {
    setLoading(true);
    try {
      const res = await server.get(
        `/stock/transactions/${stockTransactionUuid}`
      );
      setLoading(false);
      if (!res.data.voucher) {
        setLoadingError(
          'Die ausgewählte Transaktion hat keinen Voucher angehängt'
        );
      }
      return res.data.voucher;
    } catch (error) {
      setLoading(false);
      setLoadingError('Fehler beim Ladens des Vouchers');
      return undefined;
    }
  };

  return (
    <>
      {loadingError && <Alert severity="error">{loadingError}</Alert>}
      {isLoading && <Loader active={isLoading} width={100} />}
      {voucher && (
        <>
          <ReactToPrint
            trigger={() => (
              <Button variant="outlined" color="primary">
                Print!
              </Button>
            )}
            content={() => componentRef.current}
          />
          <Ticket
            ref={componentRef}
            serial={voucher.serial}
            pin={voucher.pin}
            logo={voucher.product.logo}
            productName={voucher.product.name}
            isDuplicate={isDuplicate}
          />
        </>
      )}
    </>
  );
};

interface TicketProps {
  serial: string;
  productName: string;
  pin: string;
  isDuplicate?: boolean;
  logo?: string;
}

const TicketRoot = styled.div`
  width: 180pt;
  background-color: #fff;
  margin: 10px;
`;
const TicketPin = styled.div`
  border-top: 1px dotted #444;
  border-bottom: 1px dotted #444;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
`;
const TicketHeader = styled.div``;
const TicketBody = styled.div``;
const TicketFooter = styled.div`
  margin-top: 20px;
`;

class Ticket extends React.PureComponent<TicketProps> {
  render() {
    const { serial, productName, pin, isDuplicate = true, logo } = this.props;

    return (
      <TicketRoot>
        <TicketHeader>
          {isDuplicate && <Typography variant="h3">DUPLIKAT</Typography>}
          {logo && (
            <img
              src={logo}
              alt={productName}
              width={250}
              style={{ height: 'auto' }}
            />
          )}
        </TicketHeader>
        <TicketPin>
          <p>
            <strong>{productName}</strong>
          </p>
          <p>
            Seriennummer:
            <br />
            {serial}
            <br />
            Gültig bis: 2021-06-04T22:00:00.000Z
          </p>
          <p>
            Code / PIN:
            <br />
            <strong>{pin}</strong>
          </p>
        </TicketPin>
        <TicketBody>
          <p>Beleg berechtigt nicht zum Vorsteuerabzug.</p>
          <p>
            So lösen Sie Ihren Ladebon ein: Auflade-Hotline: 83033 oder am
            Handy:
            <br />
            *147* Ladebon-Nummer # Grüne Taste
          </p>
          <p>eety g'scheit wie nie</p>
          <p>Es gelten die AGB der Hutchison Drei Austria GmbH.</p>
          <p>
            eety Service-Hotline:
            <br />
            +43 (0) 665 65065
          </p>
          <p>Umtausch und Rückgabe ausgeschlossen</p>
        </TicketBody>
        <TicketFooter>
          <Barcode value={serial} width={1} height={50} fontSize={12} />
        </TicketFooter>
      </TicketRoot>
    );
  }
}
