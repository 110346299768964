import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import {
  Stepper,
  Step,
  StepButton,
  Typography,
  Container,
  CssBaseline,
  Button
} from '@material-ui/core';
import { Link } from 'react-router-dom';

import { CheckoutDeliveryAddress } from './components/CheckoutDeliveryAddress';
import { CheckoutSummary } from './components/CheckoutSummary';
import { CheckoutStatus } from './components/CheckoutStatus';
import { ShopContext, CartItem } from './ShopContext';
import { AppContext } from '../AppContext';
import { server, defaultErrorMessage } from '../../utils/server';
import { mapToArray } from '../../utils/helper';
import { Order } from '../../types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%'
    },
    button: {
      marginRight: theme.spacing(1)
    },
    completed: {
      display: 'inline-block'
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
    stepper: {
      background: 'none',
      border: 0,
      marginTop: 30,
      marginBottom: 30
    }
  })
);

function getSteps() {
  return ['Lieferadresse', 'Zusammenfassung', 'Status'];
}

export const Checkout: React.FC = () => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(1);
  const [completed, setCompleted] = React.useState<{ [k: number]: boolean }>(
    {}
  );
  const [isLoading, setLoading] = React.useState<boolean>(false);
  const [checkoutError, setCheckoutError] = React.useState<string | undefined>(
    undefined
  );
  const [finishedOrder, setFinishedOrder] = React.useState<Order | undefined>(
    undefined
  );

  const shopContext = React.useContext(ShopContext);
  const appContext = React.useContext(AppContext);

  const steps = getSteps();
  const totalSteps = () => steps.length;
  const completedSteps = () => Object.keys(completed).length;
  const isLastStep = () => activeStep === totalSteps() - 1;
  const allStepsCompleted = () => completedSteps() === totalSteps();
  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
    setCheckoutError(undefined);
  };
  const handleStep = (step: number) => () => setActiveStep(step);
  const handleNext = async () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    setCheckoutError(undefined);
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    if (activeStep === 1) {
      await finish();
    } else {
      setActiveStep(newActiveStep);
    }
  };

  function getStepContent(step: number) {
    switch (step) {
      case 0:
        return <CheckoutDeliveryAddress />;
      case 1:
        return (
          <CheckoutSummary
            checkoutError={checkoutError}
            customer={appContext.customer!}
          />
        );
      case 2:
        return (
          <CheckoutStatus
            checkoutError={checkoutError}
            order={finishedOrder!}
            isLoading={isLoading}
          />
        );
      default:
        return 'Unknown step';
    }
  }

  const finish = async (): Promise<void> => {
    setActiveStep(2);
    setLoading(true);
    try {
      const res = await server.post('/orders', {
        items: mapToArray<CartItem>(shopContext.cart.items).map(item => ({
          quantity: item.quantity,
          product_uuid: item.productUuid
        }))
      });
      if (appContext && appContext.balance && appContext.updateBalance) {
        const newBalance =
          appContext.balance.amount - shopContext.calculateCartSum();
        appContext.updateBalance(newBalance);
      }
      setFinishedOrder(res.data);
      shopContext.clearCart();
      setLoading(false);
      return;
    } catch (error) {
      setLoading(false);
      setCheckoutError(error.common);
    }
  };

  return (
    <div className={classes.root}>
      <Typography variant="h1">Checkout</Typography>
      <CssBaseline />
      <Container maxWidth="md">
        <Stepper nonLinear activeStep={activeStep} className={classes.stepper}>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepButton
                onClick={handleStep(index)}
                completed={completed[index]}
              >
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>
        <div>{getStepContent(activeStep)}</div>
        <div
          style={{
            textAlign: 'right',
            marginTop: 60,
            paddingTop: 30,
            borderTopWidth: 1,
            borderTopStyle: 'solid',
            borderTopColor: '#eee'
          }}
        >
          {(activeStep !== 2 || checkoutError) && (
            <>
              <Button
                variant="outlined"
                color="primary"
                className={classes.button}
                component={Link}
                to="/shop"
              >
                weiter einkaufen
              </Button>
              <Button
                variant="outlined"
                disabled={activeStep === 0}
                onClick={handleBack}
                className={classes.button}
              >
                zurück
              </Button>
              {activeStep !== 2 && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  className={classes.button}
                >
                  Weiter
                </Button>
              )}
            </>
          )}
          {activeStep === 2 && !checkoutError && (
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to="/shop/vouchers"
              className={classes.button}
            >
              Zurück zum Shop
            </Button>
          )}
        </div>
      </Container>
    </div>
  );
};
