import palette from '../palette';

export default {
  root: {
    borderBottom: `1px solid ${palette.divider}`,
    padding: '14px 40px 14px 24px',
    fontSize: '14px'
  },
  head: {
    fontSize: '14px',
    color: palette.text.primary,
    fontWeight: 500
  }
};
