import MuiTableCell from './MuiTableCell';
import MuiPaper from './MuiPaper';
import MuiStepLabel from './MuiStepLabel';
import MuiCheckbox from './MuiCheckbox';

export default {
  MuiTableCell,
  MuiPaper,
  MuiStepLabel,
  MuiCheckbox
};
