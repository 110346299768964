import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Typography } from '@material-ui/core';
import Button from '../../../components/primitives/Button';
import { Topup } from '../types';
import { FormattedDate } from '../../../components/primitives';

interface ApproveTopupDialogProps {
  open: boolean;
  handleClose: (open: boolean) => void;
  handleAgree: () => void;
  topup?: Topup;
}

const ApproveDialog: React.FC<ApproveTopupDialogProps> = ({
  open,
  handleClose,
  handleAgree,
  topup
}) => {
  return (
    <Dialog
      open={open}
      onClose={() => handleClose(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle disableTypography={true} id="alert-dialog-title">
        <Typography variant="h4">
          {`Möchtest du den Top vom `}
          {topup && (
            <FormattedDate date={topup.date} showTime={false} wrapped={false} />
          )}
          {` freigeben`}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          variant="body1"
          color="textPrimary"
          id="alert-dialog-description"
        >
          Wenn du den Topup freigibst, werden alle Provisionen den Kunden auf
          Ihr Konto gutgeschrieben.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => handleClose(false)}
          color="default"
        >
          Abbrechen
        </Button>
        <Button
          variant="outlined"
          onClick={() => handleAgree()}
          color="primary"
          autoFocus
        >
          Jetzt freigeben
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ApproveDialog;
