import React, { useState, useEffect } from 'react';
import {
  server,
  defaultErrorMessage,
  stringifyQuery
} from '../../utils/server';
import { Typography, Fab, IconButton } from '@material-ui/core';
import { Loader } from '../../components/common/Loader';
import { Chip } from '../../components/primitives';
import { Alert } from '@material-ui/lab';
import { Face as FaceIcon, SimCard as SimcardIcon } from '@material-ui/icons';
import { DataTable } from '../../components/primitives/DataTable';
import { Link } from 'react-router-dom';
import { Customer } from '../../types';
import { Currency } from '../../components/primitives';

interface CustomerListProps {
  refreshData: boolean;
  setRefreshData: (status: boolean) => void;
  assignSimcards: (customerUuid: string) => void;
}

interface CustomerListFilter {
  term?: string;
}

interface FetchDataOptions {
  filter?: CustomerListFilter;
}

interface CustomerListResult {
  total: number;
  rows: Array<Customer>;
}

const CustomersList: React.FC<CustomerListProps> = ({
  refreshData,
  setRefreshData,
  assignSimcards
}) => {
  const [data, setData] = useState<Array<Customer>>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [page, setPage] = useState<number>(0);
  const [filter, setFilter] = useState<CustomerListFilter>({});
  const [count, setCount] = useState<number>(0);
  const [hpp, setHpp] = useState<number>(20);
  const [loadingError, setLoadingError] = useState<string | undefined>(
    undefined
  );

  const columns = [
    {
      name: 'business_name',
      label: 'Firma',
      options: {
        filter: true,
        filterType: 'textField' as any,
        sort: false
      }
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'email',
      label: 'Email',
      options: {
        filter: false,
        sort: false
      }
    },
    {
      name: 'balance',
      label: 'Kontostand',
      options: {
        filter: false,
        sort: false
      }
    },
    {
      name: 'action',
      label: '',
      options: { filter: false, sort: false }
    }
  ];

  const fetchData = async ({
    filter = {}
  }: FetchDataOptions): Promise<CustomerListResult> => {
    setLoading(true);
    try {
      const queryString = stringifyQuery(filter);
      const res = await server.get(
        `/customers?page=${queryString ? 0 : page}${queryString &&
          '&' + queryString}`
      );
      setLoading(false);
      return res.data;
    } catch (error) {
      setLoading(false);
      setLoadingError(defaultErrorMessage);
      return { total: 0, rows: [] };
    }
  };

  useEffect(() => {
    if (refreshData) {
      setRefreshData(false);
      fetchData({ filter }).then(data => {
        setData(data.rows);
        setCount(data.total);
      });
    }
  }, [[refreshData]]);

  useEffect(() => {
    fetchData({ filter }).then(data => {
      setData(data.rows);
      setCount(data.total);
    });
  }, [page, filter]);

  const handleFilterSubmit = (applyFilters: () => Array<any>): void => {
    const filterList = applyFilters();
    const filter: CustomerListFilter = {};
    filter.term = filterList[0].length ? filterList[0][0] : undefined;
    setFilter(filter);
    setPage(0);
    /*
    fetchData({ filter }).then(res => {
      setData(res.rows)
      setCount(res.total)
    });
    */
  };

  const prepareRows = (rows: Array<Customer>): Array<Array<any>> => {
    const optimizedRows = rows.map(row => ({
      business: row.business_name,
      name:
        row.contact_firstname && row.contact_lastname
          ? `${row.contact_firstname} ${row.user.lastname}`
          : `${row.user.firstname} ${row.user.lastname}`,
      email: row.contact_email,
      balance: (
        <Chip
          size="small"
          label={<Currency value={row.balance.amount} wrapped={false} />}
          type={row.balance.amount < 0 ? 'error' : 'success'}
        />
      ),
      action: (
        <>
          <IconButton
            size="small"
            aria-label="Details"
            color="secondary"
            component={Link}
            to={`/customers/${row.uuid}`}
            style={{ marginRight: 5 }}
          >
            <FaceIcon />
          </IconButton>
          <IconButton
            size="small"
            aria-label="Details"
            color="secondary"
            onClick={() => assignSimcards(row.uuid)}
          >
            <SimcardIcon />
          </IconButton>
        </>
      )
    }));
    return optimizedRows.map(row => Object.values(row));
  };

  return (
    <>
      <Typography variant="h2" gutterBottom>
        Kunden
      </Typography>
      {loadingError && <Alert severity="error">{loadingError}</Alert>}
      {isLoading ? (
        <Loader active={isLoading} width={100} />
      ) : (
        <DataTable
          title={''}
          data={prepareRows(data)}
          columns={columns}
          handleFilterSubmit={handleFilterSubmit}
          searchEnabled={false}
          filterEnabled={true}
          options={{
            page,
            count,
            onChangePage: async (page: number) => setPage(page)
          }}
        />
      )}
    </>
  );
};

export default CustomersList;
