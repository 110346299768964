import React, { useState } from 'react';
import { ActionDrawer, ActionFeedback } from '../../components/patterns';
import { OrderList } from './OrderList';
import { OrderDetails } from './OrderDetails';
import { AuthConsumer } from '../auth/AuthContext';
import { UserRole } from '../auth/types';
import { Order, OrderItem } from '../../types';
import { SimcardAssign } from '../stock/simcard/SimcardAssign';

enum OrderAction {
  DETAILS = 'details',
  ASSIGN_SIMCARDS = 'assign-simcards'
}

const orderSuccessMessages = new Map();
orderSuccessMessages.set(
  OrderAction.ASSIGN_SIMCARDS,
  'Die Simkarten wurden erfolgreich zugewiesen.'
);

const OrderWrapper: React.FC = () => {
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [drawerContent, setDrawerContent] = useState<OrderAction>();
  const [activeOrder, setActiveOrder] = useState<Order | undefined>(undefined);
  const [selectedOrderItem, setSelectedOrderItem] = useState<
    OrderItem | undefined
  >(undefined);
  const [success, setSuccess] = useState<boolean>(false);
  const [refreshData, setRefreshData] = useState<boolean>(true);

  const toggleDrawer = (content?: OrderAction) => {
    setDrawerOpen(!drawerOpen);
    setDrawerContent(content);
  };
  const onCloseActionSuccess = () => setSuccess(false);
  const showDetails = (order: Order) => {
    setDrawerOpen(true);
    setDrawerContent(OrderAction.DETAILS);
    setActiveOrder(order);
  };

  const showSimcardAssignment = (order_item: OrderItem) => {
    setDrawerOpen(true);
    setDrawerContent(OrderAction.ASSIGN_SIMCARDS);
    console.log(order_item);
    setSelectedOrderItem(order_item);
  };

  const onSuccess = async () => {
    setDrawerOpen(false);
    setSuccess(true);
    setRefreshData(true);
  };

  return (
    <AuthConsumer>
      {({ user }) => (
        <>
          <OrderList
            refreshData={refreshData}
            setRefreshData={setRefreshData}
            showDetailsAction={showDetails}
            isAdmin={user.role === UserRole.ADMIN}
          />
          <ActionDrawer open={drawerOpen} onClose={toggleDrawer} width={600}>
            {drawerContent === OrderAction.DETAILS && (
              <OrderDetails
                onSuccess={onSuccess}
                onSimcardAssignment={showSimcardAssignment}
                orderUuid={activeOrder!.uuid}
                isAdmin={user.role === UserRole.ADMIN}
              />
            )}
            {drawerContent === OrderAction.ASSIGN_SIMCARDS &&
              selectedOrderItem && (
                <SimcardAssign
                  selectedOrderItem={selectedOrderItem.uuid}
                  selectedProduct={selectedOrderItem.product.uuid}
                  selectedCustomer={activeOrder && activeOrder.customer.uuid}
                  onSuccess={onSuccess}
                />
              )}
          </ActionDrawer>
          <ActionFeedback
            message={orderSuccessMessages.get(drawerContent)}
            visible={success}
            onClose={onCloseActionSuccess}
            type="success"
          />
        </>
      )}
    </AuthConsumer>
  );
};

export default OrderWrapper;
