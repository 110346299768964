import React, { useState, useEffect } from 'react';
import { server } from '../../utils/server';
import { Loader } from '../../components/common/Loader';
import { Alert, AlertType } from '../../components/primitives/Alert';
import { Currency } from '../../components/primitives';
import { Typography, Card, CardContent } from '@material-ui/core';

const TotalBalance: React.FC = () => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [loadingError, setLoadingError] = useState<string | undefined>(
    undefined
  );
  const [totalBalance, setTotalBalance] = useState<number>(0);

  useEffect(() => {
    fetchTotalBalance().then(setTotalBalance);
  }, []);

  const fetchTotalBalance = async (): Promise<number> => {
    setLoading(true);
    try {
      const res = await server.get(`/balances/total`);
      setLoading(false);
      return res.data.total_value;
    } catch (error) {
      setLoading(false);
      setLoadingError('Fehler beim Ladens des Produktes');
      return 0;
    }
  };

  return (
    <>
      <Card>
        <CardContent>
          <Typography>Summe Kontostände</Typography>
          {loadingError && (
            <Alert type={AlertType.ERROR} headline="Unerwarteter Fehler">
              <Typography paragraph color="inherit">
                {loadingError}
              </Typography>
            </Alert>
          )}
          {isLoading && <Loader active={isLoading} width={100} />}
          <Typography variant="h2">
            <Currency value={totalBalance} wrapped={false} />
          </Typography>
        </CardContent>
      </Card>
    </>
  );
};

export default TotalBalance;
