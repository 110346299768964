import React, { useContext } from 'react';
import {
  List as MuiList,
  ListItem as MuiListItem,
  ListItemIcon as MuiListItemIcon,
  ListItemText as MuiListItemText
} from '@material-ui/core';
import { AppRoutes } from '../../pages/AppRouting';
import { AuthContext, AuthContextData } from '../../pages/auth/AuthContext';
import { User, UserRole } from '../../pages/auth/types';
import { Link } from 'react-router-dom';
import DashboardIcon from '@material-ui/icons/Dashboard';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import FaceIcon from '@material-ui/icons/Face';
import MonetizationIcon from '@material-ui/icons/MonetizationOn';
import PhoneIcon from '@material-ui/icons/PhoneAndroid';
import ShoppingCart from '@material-ui/icons/ShoppingCart';
import FiberPinIcon from '@material-ui/icons/FiberPin';
import EuroIcon from '@material-ui/icons/Euro';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import SimcardIcon from '@material-ui/icons/SimCard';
import PictureInPictureIcon from '@material-ui/icons/PictureInPicture';
import { AppConsumer, MenuItem } from '../../pages/AppContext';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '../../theme';
import { ShoppingBasket } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) => ({
  menuItemText: {
    fontSize: 14
  }
}));

const Navigation: React.FC = () => {
  const classes = useStyles();
  const authContext: AuthContextData | undefined = useContext(AuthContext);
  if (!authContext) {
    throw new Error('MissingAuthContext');
  }
  const { user } = authContext;

  const menuItems: Array<MenuItem> = [
    {
      label: 'HOME',
      route: AppRoutes.DASHBOARD,
      color: '#0097a7',
      icon: <DashboardIcon />,
      scopes: ['customer', 'admin', 'employee']
    },
    {
      label: 'KUNDEN',
      route: AppRoutes.CUSTOMERS,
      color: '#00acc1',
      icon: <FaceIcon />,
      scopes: ['admin', 'employee']
    },
    {
      label: 'MITARBEITER',
      route: AppRoutes.EMPLOYEE,
      color: '#00bcd4',
      icon: <SupervisorAccountIcon />,
      scopes: ['admin']
    },
    {
      label: 'KAMPAGNEN',
      route: AppRoutes.CAMPAIGNS,
      color: '#00bcd4',
      icon: <PictureInPictureIcon />,
      scopes: ['admin']
    },
    {
      label: 'TOPUP',
      route: AppRoutes.TOPUPS,
      color: '#0097a7',
      icon: <MonetizationIcon />,
      scopes: ['admin']
    },
    {
      label: 'BUCHUNGEN',
      route: AppRoutes.BALANCE_TRANSACTIONS,
      color: '#00bcd4',
      icon: <EuroIcon />,
      scopes: ['admin']
    },
    {
      label: 'GUTSCHRIFTEN',
      route: AppRoutes.CREDITNOTES,
      color: '#0097a7',
      icon: <MonetizationIcon />,
      scopes: ['admin', 'customer']
    },
    {
      label: 'PRODUKTE',
      route: AppRoutes.PRODUCTS,
      color: '#0097a7',
      icon: <PhoneIcon />,
      scopes: ['admin']
    },
    {
      label: 'RISKFREE',
      route: AppRoutes.RISKFREE,
      color: '#0097a7',
      icon: <SimcardIcon />,
      scopes: ['admin', 'customer']
    },
    {
      label: 'VOUCHER',
      route: AppRoutes.STOCK_VOUCHERS,
      color: '#00bcd4',
      icon: <FiberPinIcon />,
      scopes: ['admin']
    },
    {
      label: 'SIMKARTEN',
      route: AppRoutes.STOCK_SIMCARDS,
      color: '#00bcd4',
      icon: <SimcardIcon />,
      scopes: ['admin']
    },
    {
      label: 'SHOP VOUCHER',
      route: AppRoutes.SHOP_VOUCHERS,
      color: '#00bcd4',
      icon: <ShoppingCart />,
      scopes: ['customer']
    },
    {
      label: 'SHOP SIMKARTEN',
      route: AppRoutes.SHOP_SIMCARDS,
      color: '#00bcd4',
      icon: <ShoppingCart />,
      scopes: ['customer']
    },
    {
      label: 'BESTELLUNGEN',
      route: AppRoutes.ORDERS,
      color: '#00acc1',
      icon: <ShoppingBasket />,
      scopes: ['admin', 'customer']
    },
    {
      label: 'RECHNUNGEN',
      route: AppRoutes.INVOICES,
      color: '#00bcd4',
      icon: <InsertDriveFileIcon />,
      scopes: ['admin']
    },
    {
      label: 'BUCHUNGEN',
      route: AppRoutes.BALANCE,
      color: '#00bcd4',
      icon: <EuroIcon />,
      scopes: ['customer']
    }
  ];

  interface RenderMenuItemOptions {
    item: MenuItem;
    user: User;
    isActive: boolean;
    setActiveMenuItem: (item: MenuItem) => void;
  }

  const renderMenuItem = ({
    item,
    user,
    isActive,
    setActiveMenuItem
  }: RenderMenuItemOptions) => {
    return (
      //  { (user && [UserRole.ADMIN].includes(user.role)) && (
      <MuiListItem
        key={item.route}
        button
        selected={isActive}
        style={{ borderLeft: `6px solid ${item.color}` }}
        // onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => setActiveMenuItem(item)}
        component={Link}
        to={item.route}
      >
        <MuiListItemIcon>{item.icon}</MuiListItemIcon>
        <MuiListItemText
          primary={item.label}
          primaryTypographyProps={{ className: classes.menuItemText }}
        />
      </MuiListItem>
    );
  };

  const availableMenuItemsForUser = menuItems.filter(item =>
    item.scopes.includes(user.role)
  );

  return (
    <AppConsumer>
      {({ activeMenuItem, setActiveMenuItem }) => (
        <>
          <MuiList disablePadding>
            {availableMenuItemsForUser.map(item =>
              renderMenuItem({
                item,
                user,
                isActive: Boolean(
                  activeMenuItem && item.label === activeMenuItem.label
                ),
                setActiveMenuItem: setActiveMenuItem!
              })
            )}
          </MuiList>
        </>
      )}
    </AppConsumer>
  );
};

export default Navigation;
