import React, { useState } from 'react';
import { server } from '../../../utils/server';
import { Loader } from '../../../components/common/Loader';
import Button from '../../../components/primitives/Button';
import { Typography, TextField } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

interface StockTransactionCancelRequestProps {
  onSuccess: () => void;
  stockTransactionUuid: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    btn: {
      marginTop: theme.spacing(1),
      width: '100%'
    }
  })
);

export const StockTransactionCancelRequest: React.FC<
  StockTransactionCancelRequestProps
> = ({ onSuccess, stockTransactionUuid }) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [reason, setReason] = useState<string | undefined>('');
  const classes = useStyles();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    try {
      await server.post(
        `/stock/transactions/${stockTransactionUuid}/cancellation-requests`,
        { reason }
      );
      setLoading(false);
      onSuccess();
    } catch (error) {
      setLoading(false);
      setError(error.common || error.message);
    }
  };

  return (
    <>
      <Typography variant="h3">Voucher-Stornierung beantragen</Typography>
      {error && <Alert severity="error">{error}</Alert>}
      {isLoading && <Loader active={isLoading} width={100} />}
      <Typography>
        Hiermit kannst du die Stornierung beantragen. Onlinesim.at wird deine
        Anfrage so schnell wie möglich bearbeiten. Wir bitten um etwas Geduld.
      </Typography>
      <Alert severity="info">
        Bei einer erfolgreichen Stornierung wird dir den Betrag auf dein Konto
        gutgeschrieben.
      </Alert>
      <form onSubmit={handleSubmit} style={{ marginTop: 30 }}>
        <TextField
          variant="outlined"
          name="reason"
          type="text"
          label="Stornierungsgrund"
          defaultValue={reason}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
            setReason(event.target.value as string)
          }
          required
          fullWidth
        />
        <Button
          variant="outlined"
          color="primary"
          type="submit"
          className={classes.btn}
        >
          Stornierung beantragen
        </Button>
      </form>
    </>
  );
};
