import React, { useState } from 'react';
import { Typography, Button } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Loader } from '../../components/common/Loader';
import { server } from '../../utils/server';
import {
  RiskfreeTransaction,
  RiskfreeTransactionState,
  RiskfreeTransactionType
} from '../../types';

interface CancelRiskfreeTransactionProps {
  onSuccess: () => void;
  transaction: RiskfreeTransaction;
}

export const CancelRiskfreeTransaction: React.FC<
  CancelRiskfreeTransactionProps
> = ({ onSuccess, transaction }) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);

  const onCancelTransaction = async () => {
    setLoading(true);
    try {
      const res = await server.post(`/riskfree-transactions`, {
        type: RiskfreeTransactionType.CANCELLATION,
        serial: transaction.simcard_serial,
        onlinesim_product_id: transaction.product.id
      });
      setLoading(false);
      const { data } = res;
      if (data.state === RiskfreeTransactionState.FAILED) {
        setError(data.error);
      } else {
        onSuccess();
      }
    } catch (error) {
      setLoading(false);
      // @ts-ignore
      setError(error.common);
    }
  };

  return (
    <>
      <Typography variant="h3">Zuweisung stornieren</Typography>
      {error && <Alert severity="error">{error}</Alert>}
      {isLoading && <Loader active={isLoading} width={100} />}
      <>
        <Typography variant="body1" style={{ marginTop: 20 }}>
          Bist du dir sicher, dass du die Riskfree Aktivierung für die Simkarte{' '}
          <b>{transaction.product.name}</b> mit der Seriennummer{' '}
          <b>{transaction.simcard_serial}</b>
          wieder stornieren willst ?
        </Typography>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => onCancelTransaction()}
          style={{ marginTop: 20 }}
        >
          Ja, ich möchte jetzt stornieren
        </Button>
      </>
    </>
  );
};
