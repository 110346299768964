import React, { useState, useEffect } from 'react';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  IconButton,
  TableRow
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import { Loader } from '../../../components/common/Loader';
import { FormattedDate, Chip, ChipType } from '../../../components/primitives';
import { Voucher, StockItemState, StockTransactionState } from '../../../types';
import { server } from '../../../utils/server';
import { Cancel as CancelIcon } from '@material-ui/icons';
import { StockTransactionCancellationConfirmation } from '../stock-transaction/StockTransactionCancellationConfirmation';

interface VoucherEditProps {
  onSuccess: () => void;
  voucherUuid: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      backgroundColor: theme.palette.background.paper
    }
  })
);

export const VoucherEdit: React.FC<VoucherEditProps> = ({
  voucherUuid,
  onSuccess
}) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [loadingError, setLoadingError] = useState<string | undefined>(
    undefined
  );
  const [error, setError] = useState<string | undefined>(undefined);
  const [voucher, setVoucher] = useState<Voucher | undefined>(undefined);
  const [
    stockTransactionCancellationDialogOpen,
    setStockTransactionCancellationDialogOpen
  ] = useState<boolean>(false);

  useEffect(() => {
    fetchData().then(setVoucher);
  }, []);

  const fetchData = async (): Promise<Voucher | undefined> => {
    setLoading(true);
    try {
      const res = await server.get(`/stock/vouchers/${voucherUuid}`);
      console.log(res);
      setLoading(false);
      return res.data;
    } catch (error) {
      setLoading(false);
      setLoadingError('Fehler beim Ladens des Vouchers');
      return undefined;
    }
  };

  const onCancelTransaction = async (transactionUuid: string) => {
    setLoading(true);
    try {
      await server.put(
        `/stock/vouchers/${voucherUuid}/stock-transactions/${transactionUuid}/cancellations`
      );
      setLoading(false);
      onSuccess();
    } catch (error) {
      setLoading(false);
      setError(error.common || error.message);
    }
  };

  const mapTransactionStateToChipType = (
    state: StockTransactionState
  ): ChipType => {
    switch (state) {
      case StockTransactionState.CONFIRMED:
        return 'success';
      case StockTransactionState.CANCELLED:
        return 'error';
      case StockTransactionState.PENDING:
        return 'warning';
      default:
        return 'error';
    }
  };

  const mapTransactionStateToReadable = (
    state: StockTransactionState
  ): string => {
    switch (state) {
      case StockTransactionState.CONFIRMED:
        return 'VERKAUFT';
      case StockTransactionState.CANCELLED:
        return 'STORNIERT';
      case StockTransactionState.CANCELLATION_REQUESTED:
        return 'STORNIERUNG BEANTRAGT';
      case StockTransactionState.PENDING:
        return 'IN BEARBEITUNG';
      default:
        return 'unknown';
    }
  };

  return (
    <>
      <Typography variant="h3">Voucher Informationen</Typography>
      {loadingError && <Alert severity="error">{loadingError}</Alert>}
      {error && <Alert severity="error">{error}</Alert>}
      {isLoading && <Loader active={isLoading} width={100} />}
      {voucher && (
        <>
          <Table size="medium" aria-label="voucher_details">
            <TableBody>
              <TableRow key="voucher-product">
                <TableCell>Produkt</TableCell>
                <TableCell align="right">{voucher.product.name}</TableCell>
              </TableRow>
              <TableRow key="voucher-serial">
                <TableCell>Seriennummer</TableCell>
                <TableCell align="right">{voucher.serial}</TableCell>
              </TableRow>
              <TableRow key="voucher-pin">
                <TableCell>PIN</TableCell>
                <TableCell align="right">{voucher.pin}</TableCell>
              </TableRow>
              <TableRow key="voucher-createdat">
                <TableCell>importiert am</TableCell>
                <TableCell align="right">
                  <FormattedDate date={voucher.created_at} wrapped={false} />
                </TableCell>
              </TableRow>
              <TableRow key="voucher-state">
                <TableCell>Aktueller Status</TableCell>
                <TableCell align="right">
                  <Chip
                    size="medium"
                    label={voucher.state}
                    type={
                      voucher.state === StockItemState.AVAILABLE
                        ? 'success'
                        : 'error'
                    }
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>

          <Typography variant="h5" style={{ marginTop: 40, marginBottom: 20 }}>
            Transaktionen
          </Typography>
          {voucher.stock_transactions.length ? (
            <>
              <Table size="medium" aria-label="transactions">
                <TableBody>
                  {voucher.stock_transactions.map(transaction => (
                    <TableRow key={transaction.uuid}>
                      <TableCell>
                        <FormattedDate
                          date={transaction.created_at}
                          wrapped={false}
                        />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {transaction.customer && transaction.customer.name}
                      </TableCell>
                      <TableCell>
                        <Chip
                          size="small"
                          label={mapTransactionStateToReadable(
                            transaction.state
                          )}
                          type={mapTransactionStateToChipType(
                            transaction.state
                          )}
                        />
                      </TableCell>
                      <TableCell align="right">
                        {[
                          StockTransactionState.CONFIRMED,
                          StockTransactionState.CANCELLATION_REQUESTED,
                          StockTransactionState.PENDING
                        ].includes(transaction.state) && (
                          <>
                            <StockTransactionCancellationConfirmation
                              open={stockTransactionCancellationDialogOpen}
                              handleClose={() =>
                                setStockTransactionCancellationDialogOpen(false)
                              }
                              handleConfirm={() => {
                                onCancelTransaction(transaction.uuid);
                                setStockTransactionCancellationDialogOpen(
                                  false
                                );
                              }}
                            />
                            <IconButton
                              size="small"
                              aria-label="transaktion stornieren"
                              color="secondary"
                              onClick={() =>
                                setStockTransactionCancellationDialogOpen(true)
                              }
                            >
                              <CancelIcon />
                            </IconButton>
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </>
          ) : (
            <Typography variant="body1">Noch keine Transaktionen</Typography>
          )}
        </>
      )}
    </>
  );
};
