interface Data {
  [key: string]: string | number | undefined | null;
}

export const sanitize = (
  data: Data,
  numberFields: Array<string> = []
): Data => {
  for (const [key, value] of Object.entries(data)) {
    let formValue;
    if (value && typeof value === 'object') {
      // @ts-ignore
      formValue = value.target.value;
    } else {
      formValue = value === '' ? null : value;
    }
    if (numberFields.includes(key) && typeof formValue === 'string') {
      console.log(key);
      formValue = formValue.replace(',', '.');
      formValue = Number(formValue);
    }
    console.log({ key, formValue });
    data[key] = formValue;
  }
  return data;
};

export function mapToArray<T>(map: Map<string, T>): Array<T> {
  return Array.from(map.values());
}

export function arrayToMap<T>(arr: Array<T>, key: string): Map<string, T> {
  const map = new Map();
  arr.forEach(element => {
    // @ts-ignore
    map.set(element[key], element);
  });
  return map;
}
