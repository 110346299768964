import React from 'react';
import theme, { Theme } from '../../theme';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/styles';
import {
  Drawer as MuiDrawer,
  Divider as MuiDivider,
  IconButton as MuiIconButton
} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Navigation from './Navigation';

interface StyleProperties {
  drawerWidth: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    // width: (((props: StyleProperties) => props.drawerWidth)) as any,
    flexShrink: 0,
    whiteSpace: 'nowrap'
  },
  drawerOpen: {
    width: ((props: StyleProperties) => props.drawerWidth) as any,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1
    }
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar
  }
}));

interface DrawerProps {
  isOpen: boolean;
  handleDrawerClose: () => void;
  drawerWidth: number;
}

const Drawer: React.FC<DrawerProps> = ({
  isOpen,
  handleDrawerClose,
  drawerWidth
}) => {
  const classes = useStyles({ drawerWidth });

  return (
    <MuiDrawer
      variant="permanent"
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isOpen,
        [classes.drawerClose]: !isOpen
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isOpen,
          [classes.drawerClose]: !isOpen
        })
      }}
      open={isOpen}
    >
      <div className={classes.toolbar}>
        <MuiIconButton onClick={handleDrawerClose}>
          {theme.direction === 'rtl' ? (
            <ChevronRightIcon />
          ) : (
            <ChevronLeftIcon />
          )}
        </MuiIconButton>
      </div>
      <MuiDivider />
      <Navigation />
    </MuiDrawer>
  );
};

export default Drawer;
