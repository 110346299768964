import React, { useState, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import { Product, ProductType } from '../../types';
import { server } from '../../utils/server';
import { Loader } from '../../components/common/Loader';
import { ProductList } from '../shop/components/ProductList';
import {
  TabNavigation,
  TabContainer
} from '../../components/primitives/TabNavigation';
import ActionFeedback from '../../components/patterns/ActionFeedback';
import ActionDrawer from '../../components/patterns/ActionDrawer';
import { CancelRiskfreeTransaction } from './CancelRiskfreeTransaction';
import { ActivateRiskfree } from './ActivateRiskfree';
import { RiskfreeTransaction } from '../../types';
import { RiskfreeTransactionList } from './RiskfreeTransactionList';

export const ShopRiskfree: React.FC = () => {
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [drawerContent, setDrawerContent] = useState<'activate' | 'cancel'>();
  const [products, setProducts] = useState<Array<Product>>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [activeTab, setActiveTab] = useState<any>(0);
  const [selectedProduct, setSelectedProduct] = useState<Product | undefined>(
    undefined
  );
  const [activeTransaction, setActiveTransaction] = useState<
    RiskfreeTransaction | undefined
  >(undefined);
  const [success, setSuccess] = useState<boolean>(false);
  const [refreshData, setRefreshData] = useState<boolean>(true);
  const tabs = ['Einkaufen', 'Archiv'];

  const toggleDrawer = (content?: 'activate' | 'cancel') => {
    setDrawerOpen(!drawerOpen);
    setDrawerContent(content);
  };
  const onCloseActionSuccess = () => setSuccess(false);
  const cancelTransaction = (transaction: RiskfreeTransaction) => {
    setDrawerOpen(true);
    setDrawerContent('cancel');
    setActiveTransaction(transaction);
  };

  const onSuccess = async () => {
    setDrawerOpen(false);
    setSuccess(true);
    setRefreshData(true);
  };

  useEffect(() => {
    fetchData().then(data => setProducts(data));
  }, []);

  const fetchData = async (): Promise<Array<Product>> => {
    setLoading(true);
    try {
      const res = await server.get('/products?active=1');
      setLoading(false);
      return res.data;
    } catch (error) {
      setLoading(false);
      return [];
    }
  };

  const onSelectProduct = (product: Product) => {
    setSelectedProduct(product);
    toggleDrawer('activate');
  };

  return (
    <div>
      <Typography variant="h3">Shop Riskfree</Typography>
      <TabNavigation
        tabs={tabs}
        activeTab={activeTab}
        handleChange={setActiveTab}
      />
      {activeTab === 0 &&
        (isLoading ? (
          <Loader active={isLoading} width={100} />
        ) : (
          <>
            <TabContainer>
              <ProductList
                data={products.filter(p => p.type === ProductType.RISKFREE)}
                headline="Riskfree"
                onSelectProduct={onSelectProduct}
                btnLabel="Aktivieren"
              />
            </TabContainer>
          </>
        ))}
      {activeTab === 1 && (
        <TabContainer>
          <RiskfreeTransactionList
            refreshData={refreshData}
            setRefreshData={setRefreshData}
            cancelAction={cancelTransaction}
          />
        </TabContainer>
      )}
      <ActionDrawer open={drawerOpen} onClose={toggleDrawer} width={470}>
        {drawerContent === 'activate' && (
          <ActivateRiskfree
            onSuccess={onSuccess}
            selectedProduct={selectedProduct!}
          />
        )}
        {drawerContent === 'cancel' && (
          <CancelRiskfreeTransaction
            transaction={activeTransaction!}
            onSuccess={onSuccess}
          />
        )}
      </ActionDrawer>
      <ActionFeedback
        message={
          drawerContent === 'activate'
            ? 'Die Simkarten wurde erfolgreich aktiviert'
            : 'Die Aktivierung wurde erfolgreich storniert'
        }
        visible={success}
        onClose={onCloseActionSuccess}
        type="success"
      />
    </div>
  );
};
