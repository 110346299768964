import React, { useState } from 'react';
import { Fab, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Add as AddIcon } from '@material-ui/icons';
import { AuthConsumer } from '../../auth/AuthContext';
import { UserRole } from '../../auth/types';
import { Theme } from '../../../theme';
import { ActionDrawer, ActionFeedback } from '../../../components/patterns';
import { VoucherList } from './VoucherList';
import { VoucherEdit } from './VoucherEdit';
import { VoucherImport } from './VoucherImport';
import {
  TabNavigation,
  TabContainer
} from '../../../components/primitives/TabNavigation';
import {
  ProductType,
  StockTransaction,
  StockTransactionState
} from '../../../types';
import { StockTransactionList } from '../stock-transaction/StockTransactionList';
import { StockTransactionCancel } from '../stock-transaction/StockTransactionCancel';

const useStyles = makeStyles((theme: Theme) => ({
  fab: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  }
}));

enum VoucherAction {
  CREATE = 'create',
  CANCEL = 'cancel',
  EDIT = 'edit'
}

const voucherSuccessMessages = new Map();
voucherSuccessMessages.set(
  VoucherAction.CANCEL,
  'Die Transaktion wurde erfolgreich storniert.'
);
voucherSuccessMessages.set(
  VoucherAction.CREATE,
  'Der Voucher wurde erfolgreich importiert'
);

const VoucherManager: React.FC = () => {
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [drawerContent, setDrawerContent] = useState<VoucherAction>();
  const [activeVoucher, setActiveVoucher] = useState<string | undefined>(
    undefined
  );
  const [activeStockTransaction, setActiveStockTransaction] = useState<
    StockTransaction | undefined
  >(undefined);
  const [success, setSuccess] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<any>(0);
  const [refreshData, setRefreshData] = useState<boolean>(true);
  const classes = useStyles();
  const tabs = ['Datenbank', 'Beantragte Stornierungen'];

  const toggleDrawer = (content?: VoucherAction) => {
    setDrawerOpen(!drawerOpen);
    setDrawerContent(content);
  };
  const onCloseActionSuccess = () => setSuccess(false);
  const editVoucher = (voucherUuid: string) => {
    setDrawerOpen(true);
    setDrawerContent(VoucherAction.EDIT);
    setActiveVoucher(voucherUuid);
  };

  const cancelAction = (stockTransaction: StockTransaction): void => {
    setActiveStockTransaction(stockTransaction);
    setDrawerOpen(true);
    setDrawerContent(VoucherAction.CANCEL);
  };

  const onSuccess = async () => {
    setDrawerOpen(false);
    setSuccess(true);
    setRefreshData(true);
  };

  return (
    <>
      <Typography variant="h2" gutterBottom>
        Voucher
      </Typography>
      <TabNavigation
        tabs={tabs}
        activeTab={activeTab}
        handleChange={setActiveTab}
      />
      {activeTab === 0 && (
        <TabContainer>
          <AuthConsumer>
            {({ user, isFetching }) => (
              <>
                <VoucherList
                  refreshData={refreshData}
                  setRefreshData={setRefreshData}
                  editAction={editVoucher}
                  isAdmin={user.role === UserRole.ADMIN}
                />
                {!isFetching && user.role !== UserRole.CUSTOMER && (
                  <Fab
                    className={classes.fab}
                    color="secondary"
                    onClick={() => toggleDrawer(VoucherAction.CREATE)}
                  >
                    <AddIcon />
                  </Fab>
                )}
              </>
            )}
          </AuthConsumer>
        </TabContainer>
      )}
      {activeTab === 1 && (
        <TabContainer>
          <StockTransactionList
            type={ProductType.VOUCHER}
            selectedState={StockTransactionState.CANCELLATION_REQUESTED}
            isAdmin={true}
            refreshData={refreshData}
            setRefreshData={setRefreshData}
            cancelAction={cancelAction}
          />
        </TabContainer>
      )}

      <ActionDrawer open={drawerOpen} onClose={toggleDrawer} width={800}>
        {drawerContent === VoucherAction.CREATE && (
          <VoucherImport onSuccess={onSuccess} />
        )}
        {drawerContent === VoucherAction.EDIT && (
          <VoucherEdit onSuccess={onSuccess} voucherUuid={activeVoucher!} />
        )}
        {drawerContent === VoucherAction.CANCEL && (
          <StockTransactionCancel
            onSuccess={onSuccess}
            stockTransaction={activeStockTransaction!}
          />
        )}
      </ActionDrawer>
      <ActionFeedback
        message={voucherSuccessMessages.get(drawerContent)}
        visible={success}
        onClose={onCloseActionSuccess}
        type="success"
      />
    </>
  );
};

export default VoucherManager;
