import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/Close';
import { Theme } from '../../../theme';
import Drawer from '@material-ui/core/Drawer';

interface ActionDrawerProps {
  width?: number;
  open: boolean;
  onClose: () => void;
}

interface StyleProperties {
  width: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    width: ((props: StyleProperties) => props.width) as any,
    padding: 20,
    paddingTop: 40
  },
  closeIcon: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2)
  }
}));

const ActionDrawer: React.FC<ActionDrawerProps> = ({
  open = false,
  children,
  onClose,
  width = 400
}) => {
  const classes = useStyles({ width });
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      classes={{ paper: classes.drawer }}
    >
      <IconButton className={classes.closeIcon} tabIndex={0} onClick={onClose}>
        <CloseIcon />
      </IconButton>
      {children}
    </Drawer>
  );
};

export default ActionDrawer;
