import React, { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { server, defaultErrorMessage } from '../../utils/server';
import { Loader } from '../../components/common/Loader';
import { Alert, AlertType } from '../../components/primitives/Alert';
import {
  TabNavigation,
  TabContainer
} from '../../components/primitives/TabNavigation';
import { AuthConsumer } from '../auth/AuthContext';
import { UserRole } from '../auth/types';
import CustomerEdit from './CustomerEdit';
import CustomerSettings from './CustomerSettings';
import { AccountBalance } from '../../components/common/Balance/AccountBalance';
import { AccountBalanceTransactions } from '../../components/common/Balance/AccountBalanceTransactions';
import Grid from '@material-ui/core/Grid';
import { CustomerUsers } from './CustomerUsers';
import { Customer, BalanceTransaction } from '../../types';

type PathParamsType = {
  customerId: string;
};

// Your component own properties
type CustomerDashboardProps = RouteComponentProps<PathParamsType> & {
  someString: string;
};

const defaultCustomer = {
  uuid: '',
  address_street: '',
  address_zip: '',
  address_city: '',
  address_country: '',
  active: true,
  business_name: 'Kunde wird geladen...',
  provision_fixed: 0,
  provision_topup_one: 0,
  provision_topup_two: 0,
  provision_topup_three: 0,
  user: {
    uuid: '123',
    firstname: 'John',
    lastname: 'Doe',
    email: 'john@doe.at',
    active: false,
    last_auth: '2019-05-25 23:59:59'
  },
  balance: {
    id: 1,
    amount: 0,
    currency: 'EUR'
  }
};

const CustomersDashboard: React.FC<CustomerDashboardProps> = ({ match }) => {
  const [customer, setCustomer] = useState<Customer>(defaultCustomer);
  const [balanceTransactions, setBalanceTransactions] = useState<
    Array<BalanceTransaction>
  >([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [loadingError, setLoadingError] = useState<string | undefined>(
    undefined
  );
  const [activeTab, setActiveTab] = useState<any>(0);
  const tabs = ['Überblick', 'Stammdaten', 'Einstellungen', 'Benutzer'];

  useEffect(() => {
    fetchCustomer(match.params.customerId).then(customer =>
      setCustomer(customer)
    );
  }, []);

  const fetchCustomer = async (uuid: string): Promise<Customer> => {
    setLoading(true);
    try {
      const res = await server.get(`/customers/${uuid}`);
      console.log(res.data);
      setLoading(false);
      return res.data;
    } catch (error) {
      setLoading(false);
      setLoadingError(defaultErrorMessage);
      return defaultCustomer;
    }
  };

  const fetchBalanceTransactions = async (): Promise<
    Array<BalanceTransaction>
  > => {
    try {
      const res = await server.get(
        `/balances/${customer.balance.id}/transactions`
      );
      setLoading(false);
      setBalanceTransactions(res.data);
      return res.data;
    } catch (error) {
      setLoading(false);
      setLoadingError(defaultErrorMessage);
      return [];
    }
  };

  return (
    <AuthConsumer>
      {({ user }) => (
        <>
          {loadingError && (
            <Alert type={AlertType.ERROR} headline="Unerwarteter Fehler">
              <Typography paragraph color="inherit">
                {loadingError}
              </Typography>
            </Alert>
          )}
          {isLoading ? (
            <Loader active={isLoading} width={100} />
          ) : (
            <>
              <Typography variant="h2" gutterBottom>
                {customer.business_name}
              </Typography>
              <TabNavigation
                tabs={tabs}
                activeTab={activeTab}
                handleChange={setActiveTab}
              />
              {activeTab === 0 && (
                <TabContainer>
                  <Grid container spacing={3}>
                    <Grid item md={6}>
                      <AccountBalance balance={customer.balance.amount} />
                      <AccountBalanceTransactions
                        transactions={balanceTransactions}
                      />
                    </Grid>
                    <Grid item md={6} />
                  </Grid>
                </TabContainer>
              )}
              {activeTab === 1 && (
                <TabContainer>
                  <CustomerEdit customerUuid={customer.uuid} data={customer} />
                </TabContainer>
              )}
              {activeTab === 2 && user.role === UserRole.ADMIN && (
                <TabContainer>
                  <CustomerSettings
                    customerUuid={customer.uuid}
                    data={{
                      salesforce_id: customer.salesforce_id,
                      active: customer.active,
                      provision_fixed: customer.provision_fixed / 100,
                      employee: customer.employee
                        ? customer.employee.id
                        : undefined,
                      provision_topup_one: customer.provision_topup_one / 100,
                      provision_topup_two: customer.provision_topup_two / 100,
                      provision_topup_three:
                        customer.provision_topup_three / 100
                    }}
                  />
                </TabContainer>
              )}
              {activeTab === 3 && user.role === UserRole.ADMIN && (
                <TabContainer>
                  <CustomerUsers users={[customer.user]} />
                </TabContainer>
              )}
            </>
          )}
        </>
      )}
    </AuthConsumer>
  );
};

export default withRouter(CustomersDashboard);
