import React from 'react';
import { Theme } from '../../theme';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/styles';
import {
  AppBar as MuiAppBar,
  Toolbar as MuiToolbar,
  Typography as MuiTypography,
  IconButton as MuiIconButton
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import {
  Menu as MenuIcon,
  AccountCircle,
  SettingsPower as SettingsPowerIcon
} from '@material-ui/icons';
import Logo from '../../assets/OnlineSIM_360x.jpg';
import { CurrentBalance } from '../../pages/customer/CurrentBalance';

interface StyleProperties {
  drawerWidth: number;
}

interface LoggedUser {
  id: number | string;
  firstname: string;
  lastname: string;
  avatar?: string;
}

interface AppBarProps {
  handleDrawerOpen: () => void;
  drawerOpen: boolean;
  title: string;
  loggedUser?: LoggedUser;
  drawerWidth: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  grow: {
    flexGrow: 1
  },
  appBar: {
    backgroundColor: '#FFFFFF',
    borderBottom: '1px solid #DFE3E8',
    boxShadow: 'none',
    color: '#444',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: ((props: StyleProperties) => props.drawerWidth) as any,
    width: ((props: StyleProperties) =>
      `calc(100% - ${props.drawerWidth}px)`) as any,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36
  },
  hide: {
    display: 'none'
  }
}));

const AppBar: React.FC<AppBarProps> = ({
  handleDrawerOpen,
  drawerOpen,
  title,
  loggedUser,
  drawerWidth
}) => {
  const classes = useStyles({ drawerWidth });

  return (
    <MuiAppBar
      position="fixed"
      className={classNames(classes.appBar, {
        [classes.appBarShift]: drawerOpen
      })}
    >
      <MuiToolbar disableGutters={!drawerOpen}>
        <MuiIconButton
          aria-label="Open drawer"
          onClick={handleDrawerOpen}
          className={classNames(classes.menuButton, {
            [classes.hide]: drawerOpen
          })}
        >
          <MenuIcon />
        </MuiIconButton>
        <img src={Logo} alt="OnlineSIM.at" height={45} />
        <div className={classes.grow} />
        <AppBarNavigation />
      </MuiToolbar>
    </MuiAppBar>
  );
};

const AppBarNavigation: React.FC = () => {
  return (
    <>
      <CurrentBalance />
      <MuiIconButton component={Link} to={`/logout`}>
        <SettingsPowerIcon />
      </MuiIconButton>
    </>
  );
};

export default AppBar;
