import React, { useState, useEffect } from 'react';
import { Typography, IconButton } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Info as InfoIcon } from '@material-ui/icons';
import {
  server,
  defaultErrorMessage,
  stringifyQuery
} from '../../utils/server';
import { Customer, Order, OrderState, mapOrderState } from '../../types';
import {
  DataTable,
  FormattedDate,
  Chip,
  ChipType,
  Currency
} from '../../components/primitives';
import { Loader } from '../../components/common/Loader';

interface OrderListProps {
  refreshData: boolean;
  setRefreshData: (status: boolean) => void;
  showDetailsAction: (order: Order) => void;
  isAdmin?: boolean;
}

interface FetchDataOptions {
  filter?: OrderListFilter;
}

interface OrderListFilter {
  state?: 'AVAILABLE' | 'SOLD' | 'RESERVED';
  customerName?: string;
  numberSearch?: string;
}

export const mapOrderStateToChipType = (state: OrderState): ChipType => {
  switch (state) {
    case OrderState.ABORTED:
    case OrderState.CANCELLED:
      return 'error';
    case OrderState.DELIVERED:
    case OrderState.SHIPPED:
      return 'success';
    case OrderState.RECEIVED:
    case OrderState.IN_PROGRESS:
      return 'warning';
    default:
      return 'info';
  }
};

export const OrderList: React.FC<OrderListProps> = ({
  refreshData,
  setRefreshData,
  showDetailsAction,
  isAdmin = false
}) => {
  const [data, setData] = useState<Array<Order>>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [page, setPage] = useState<number>(0);
  const [hpp, setHpp] = useState<number>(20);
  const [loadingError, setLoadingError] = useState<string | undefined>(
    undefined
  );
  const [customers, setCustomers] = useState<Array<Customer>>([]);

  useEffect(() => {
    fetchCustomers().then(customers => setCustomers(customers));
  }, []);
  const fetchCustomers = async (): Promise<Array<Customer>> => {
    setLoading(true);
    try {
      const res = await server.get('/customers?page=0&limit=1000');
      setLoading(false);
      return res.data.rows;
    } catch (error) {
      setLoading(false);
      return [];
    }
  };

  useEffect(() => {
    if (refreshData) {
      setRefreshData(false);
      fetchData({}).then(data => setData(data));
    }
  }, [[refreshData]]);

  const fetchData = async ({
    filter = {}
  }: FetchDataOptions): Promise<Array<Order>> => {
    setLoading(true);
    try {
      const queryString = stringifyQuery(filter);
      const res = await server.get(
        `/orders${queryString && '?' + queryString}`
      );
      setLoading(false);
      return res.data;
    } catch (error) {
      setLoading(false);
      setLoadingError(defaultErrorMessage);
      return [];
    }
  };

  const handleFilterSubmit = (applyFilters: () => Array<any>): void => {
    const filterList = applyFilters();
    const filter: OrderListFilter = {};
    filter.numberSearch = filterList[0].length ? filterList[0][0] : undefined;
    filter.customerName = filterList[2].length ? filterList[2][0] : undefined;
    filter.state = filterList[3].length ? filterList[3][0] : undefined;
    fetchData({ filter }).then(setData);
  };

  const getColumns = (customers: Array<Customer>) => [
    {
      name: 'date',
      label: 'Datum',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'number',
      label: 'Nummer',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'customer',
      label: 'Kunde',
      options: {
        filter: isAdmin,
        filterType: 'dropdown' as any,
        filterOptions: {
          names: customers.map(customer => customer.business_name),
          fullWidth: true
        },
        customFilterListOptions: { render: (v: string) => `Kunde: ${v}` },
        sort: true
      }
    },
    {
      name: 'state',
      label: 'Status',
      options: {
        filter: true,
        filterType: 'dropdown' as any,
        filterOptions: {
          names: [
            OrderState.ABORTED,
            OrderState.CANCELLED,
            OrderState.DELIVERED,
            OrderState.IN_PROGRESS,
            OrderState.RECEIVED,
            OrderState.SHIPPED
          ],
          fullWidth: true
        },
        customFilterListOptions: { render: (v: OrderState) => `Status: ${v}` },
        sort: true
      }
    },
    {
      name: 'amount',
      label: 'Betrag',
      options: {
        filter: false,
        sort: false
      }
    },
    {
      name: 'action',
      label: 'Aktionen',
      options: {
        filter: false,
        sort: false
      }
    }
  ];

  const prepareRows = (rows: Array<Order>): Array<Array<any>> => {
    const optimizedRows = rows.map(row => ({
      date: <FormattedDate date={row.created_at} wrapped={false} />,
      number: `#${row.number}`,
      customer: row.customer.name,
      state: (
        <Chip
          size="small"
          label={mapOrderState(row.state)}
          type={mapOrderStateToChipType(row.state)}
        />
      ),
      amount: <Currency wrapped={false} value={row.amount_gross} />,
      action: (
        <IconButton
          size="small"
          aria-label="bearbeiten"
          color="secondary"
          onClick={() => showDetailsAction(row)}
        >
          <InfoIcon />
        </IconButton>
      )
    }));
    return optimizedRows.map(row => Object.values(row));
  };

  return (
    <>
      <Typography variant="h2" gutterBottom>
        Bestellungen
      </Typography>
      {loadingError && <Alert severity="error">{loadingError}</Alert>}
      {isLoading && <Loader active={isLoading} width={100} />}
      <DataTable
        title={''}
        data={prepareRows(data)}
        columns={getColumns(customers)}
        handleFilterSubmit={handleFilterSubmit}
        filterEnabled={true}
        searchEnabled={false}
        options={{
          page,
          count: hpp,
          onChangePage: async (page: number) => {
            setPage(page);
          }
        }}
      />
    </>
  );
};
