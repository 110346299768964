import React from 'react';

interface SimcardEditProps {
  onSuccess: () => void;
  simcardUuid: string;
}

export const SimcardEdit: React.FC<SimcardEditProps> = ({
  onSuccess,
  simcardUuid
}) => {
  return <></>;
};
