import { PyroField, PyroFormValues } from 'pyro-form';
import React, { ChangeEvent } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MuiSelect, {
  SelectProps as MuiSelectProps
} from '@material-ui/core/Select';

export interface SelectProps<Name extends string>
  extends Partial<MuiSelectProps> {
  name: Name;
  id: string;
  label: string;
}

export class Select<Values extends PyroFormValues> extends React.PureComponent<
  SelectProps<Extract<keyof Values, string>>
> {
  public render() {
    const { name, label, id, children, fullWidth } = this.props;

    return (
      <PyroField<Values> name={name}>
        {({ core, meta }) => {
          const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
            const { value: eventValue } = event.target;
            // @ts-ignore
            core.onChange(eventValue);
          };
          return (
            <FormControl
              variant="outlined"
              fullWidth={fullWidth}
              error={meta.touched ? !!meta.error : undefined}
              style={{ marginTop: 10, marginBottom: 10 }}
            >
              <InputLabel id={`label-${id}`}>{label}</InputLabel>
              <MuiSelect {...core} id={id} onChange={handleChange}>
                {children}
              </MuiSelect>
            </FormControl>
          );
        }}
      </PyroField>
    );
  }
}
