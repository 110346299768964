import React, { useState } from 'react';
import {
  ResetPasswordForm,
  ResetPasswordParameter
} from './components/ResetPasswordForm';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';
import { Alert, AlertType } from '../../components/primitives/Alert';
import { server } from '../../utils/server';
import { Loader } from '../../components/common/Loader';
import { Button, Typography } from '@material-ui/core';

export const ResetPassword: React.FC<RouteComponentProps<{}>> = ({
  history
}) => {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [success, setSuccess] = useState(false);

  const validateInput = ({ email }: ResetPasswordParameter) => {
    if (!email || email === '') {
      setError('Bitte gib eine valide E-Mail Adresse an');
      return false;
    }
    return true;
  };

  const onSubmit = async ({ email }: ResetPasswordParameter) => {
    setLoading(true);
    setSuccess(false);
    setError(undefined);

    if (validateInput({ email })) {
      try {
        await server.post('/reset', { email });
        setLoading(false);
        setSuccess(true);
      } catch (error) {
        setLoading(false);
        setError(error.common);
      }
    }
  };

  const LoginLink = (props: any) => <Link to="/" {...props} />;

  return (
    <>
      {error && (
        <Alert type={AlertType.ERROR}>
          <Typography paragraph color="inherit">
            {error}
          </Typography>
        </Alert>
      )}
      {success ? (
        <Alert type={AlertType.SUCCESS} headline="E-Mail versendet">
          <Typography paragraph color="inherit">
            Wir haben dir ein E-Mail geschickt mit deren Hilfe du ein neues
            Passwort setzen kannst.
          </Typography>
        </Alert>
      ) : (
        <>
          <ResetPasswordForm onSubmit={onSubmit} loading={isLoading} />
          <Button color="primary" component={LoginLink}>
            Zurück zum Login
          </Button>
          <Loader active={isLoading} />
        </>
      )}
    </>
  );
};

export default withRouter(ResetPassword);
