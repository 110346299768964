import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '../../../theme';
import { Typography, Divider } from '@material-ui/core';

interface FormSectionProps {
  headline: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  section: {
    marginBottom: 20,
    marginTop: 20
  }
}));

export const FormSection: React.FC<FormSectionProps> = ({
  headline,
  children
}) => {
  const classes = useStyles();

  return (
    <div className={classes.section}>
      <Typography variant="h6">{headline}</Typography>
      <Divider />
      {children}
    </div>
  );
};
