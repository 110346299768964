import React, { useState, useEffect } from 'react';
import {
  Typography,
  InputAdornment,
  MenuItem,
  TextField
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import { Loader } from '../../../components/common/Loader';
import { server, stringifyQuery } from '../../../utils/server';
import Button from '../../../components/primitives/Button';
import { Product, Customer } from '../../../types';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment, { Moment } from 'moment';

interface FormData {
  product: string;
  from: Date | null;
  until: Date | null;
  customer: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1)
      }
    },
    btn: {
      margin: theme.spacing(1),
      width: '100%'
    }
  })
);

export const StockTransactionExport: React.FC<{ onSuccess: () => void }> = ({
  onSuccess
}) => {
  const [error, setError] = useState<string | undefined>(undefined);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [products, setProducts] = useState<Array<Product>>([]);
  const [customers, setCustomers] = useState<Array<Customer>>([]);
  const [formData, setFormData] = useState<FormData>({
    product: '0',
    from: new Date(),
    until: new Date(),
    customer: '0'
  });
  const classes = useStyles();

  useEffect(() => {
    Promise.all([fetchProducts(), fetchCustomers()]).then(data => {
      setProducts(data[0]);
      setCustomers(data[1]);
    });
  }, []);

  const fetchProducts = async (): Promise<Array<Product>> => {
    setLoading(true);
    try {
      const res = await server.get('/products?type=SIMCARD');
      setLoading(false);
      return res.data;
    } catch (error) {
      setLoading(false);
      return [];
    }
  };
  const fetchCustomers = async (): Promise<Array<Customer>> => {
    setLoading(true);
    try {
      const res = await server.get('/customers?page=0&limit=1000');
      setLoading(false);
      return res.data.rows;
    } catch (error) {
      setLoading(false);
      return [];
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setError(undefined);
    setLoading(true);

    try {
      const filter = {
        customer: formData.customer !== '0' ? formData.customer : undefined,
        productName: formData.product !== '0' ? formData.product : undefined,
        createdFrom: formData.from
          ? moment(formData.from).format('YYYY-MM-DD')
          : undefined,
        createdUntil: formData.until
          ? moment(formData.until).format('YYYY-MM-DD')
          : undefined
      };
      const queryString = stringifyQuery(filter);

      const res = await server.get(
        `/stock/stock-transactions/export${queryString && '?' + queryString}`,
        {
          responseType: 'blob'
        }
      );
      setLoading(false);
      const url = window.URL.createObjectURL(res.data);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `stock-transactions-export-${Date.now()}.csv`
      );
      document.body.appendChild(link);
      link.click();
      setLoading(false);
      console.log({ res });
      onSuccess();
    } catch (error) {
      setLoading(false);
      setError(error.common);
    }
  };

  const handleProductSelect = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setFormData({ ...formData, product: event.target.value as string });
  };

  const handleCustomerSelect = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setFormData({ ...formData, customer: event.target.value as string });
  };

  return (
    <>
      <Typography variant="h3">Simkarten-Zuweisungen exportieren</Typography>
      {error && <Alert severity="error">{error}</Alert>}
      {isLoading ? (
        <Loader active={isLoading} width={100} />
      ) : (
        <form onSubmit={handleSubmit} className={classes.root}>
          <TextField
            id="customer-select"
            select
            label="Kunde auswählen"
            value={formData.customer}
            onChange={handleCustomerSelect}
            variant="outlined"
            fullWidth
          >
            <MenuItem key="all-customers" value="0">
              Alle Kunden exportieren
            </MenuItem>
            {customers.map(customer => (
              <MenuItem key={customer.uuid} value={customer.uuid}>
                {customer.business_name}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            id="product-select"
            select
            label="Produkt auswählen"
            value={formData.product}
            onChange={handleProductSelect}
            variant="outlined"
            fullWidth
          >
            <MenuItem key="all-products" value="0">
              Alle Produkte exportieren
            </MenuItem>
            {products.map(product => (
              <MenuItem key={product.uuid} value={product.name}>
                {product.name}
              </MenuItem>
            ))}
          </TextField>

          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
              margin="normal"
              id="from"
              label="Datum von"
              format="DD.MM.YYYY"
              value={formData.from}
              fullWidth
              onChange={(date: Moment | null) =>
                setFormData({
                  ...formData,
                  from: date ? date.toDate() : null
                })
              }
              KeyboardButtonProps={{
                'aria-label': 'change date'
              }}
            />

            <KeyboardDatePicker
              margin="normal"
              id="until"
              label="Datum bis"
              format="DD.MM.YYYY"
              value={formData.until}
              fullWidth
              onChange={(date: Moment | null) =>
                setFormData({
                  ...formData,
                  until: date ? date.toDate() : null
                })
              }
              KeyboardButtonProps={{
                'aria-label': 'change date'
              }}
            />
          </MuiPickersUtilsProvider>
          <Button
            variant="outlined"
            color="primary"
            type="submit"
            className={classes.btn}
          >
            Export starten
          </Button>
        </form>
      )}
    </>
  );
};
