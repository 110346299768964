import React, { useState } from 'react';
import { server } from '../../utils/server';
import {
  Typography,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Loader } from '../../components/common/Loader';
import Button from '../../components/primitives/Button';
import { Alert, AlertType } from '../../components/primitives/Alert';
import { Campaign } from '../../types';

interface CampaignCreateProps {
  onSuccess: () => void;
  campaign: Campaign;
}

interface FormData {
  title: string;
  text: string;
  active: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1)
      }
    },
    btn: {
      margin: theme.spacing(1),
      width: '100%'
    }
  })
);

const CampaignEdit: React.FC<CampaignCreateProps> = ({
  onSuccess,
  campaign
}) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [data, setData] = useState<FormData>({
    title: campaign.title,
    text: campaign.text || '',
    active: campaign.active
  });
  const classes = useStyles();

  const handleActiveChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setData({ ...data, [event.target.name]: event.target.checked });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    try {
      await server.put(`/campaigns/${campaign.id}`, data);
      setLoading(false);
      onSuccess();
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  return (
    <>
      <Typography variant="h3">Kampagne bearbeiten</Typography>
      {error && (
        <Alert type={AlertType.ERROR} headline="Ein Fehler ist aufgetreten">
          <Typography paragraph color="inherit">
            {error}
          </Typography>
        </Alert>
      )}
      {isLoading ? (
        <Loader active={isLoading} width={100} />
      ) : (
        <form onSubmit={handleSubmit}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={data.active}
                  onChange={handleActiveChange}
                  name="active"
                />
              }
              label="Kampagne aktiv?"
            />
          </FormGroup>
          <TextField
            variant="outlined"
            name="title"
            type="text"
            label="Titel"
            defaultValue={data.title}
            onChange={(event: React.ChangeEvent<{ value: string }>) =>
              setData({
                ...data,
                title: event.target.value
              })
            }
            style={{ marginBottom: 20 }}
            required
            fullWidth
          />
          <div style={{ marginBottom: 20 }}>
            <img
              src={campaign.image.location}
              alt={campaign.title}
              style={{ height: '100%', width: '100%' }}
            />
          </div>
          <TextField
            variant="outlined"
            name="text"
            type="text"
            label="Text"
            defaultValue={data.text}
            onChange={(event: React.ChangeEvent<{ value: string }>) =>
              setData({
                ...data,
                text: event.target.value
              })
            }
            style={{ marginBottom: 20 }}
            fullWidth
          />
          <Button
            variant="outlined"
            color="primary"
            type="submit"
            className={classes.btn}
          >
            Speichern
          </Button>
        </form>
      )}
    </>
  );
};

export default CampaignEdit;
