import React from 'react';
import { server } from '../../utils/server';
import { User, UserRole } from './types';

export interface AuthContextData extends AuthContextState {
  login: () => void;
  logout: () => void;
}

interface AuthContextState {
  isAuthenticated: boolean;
  user: User;
  isFetching: boolean;
}

const mockUser = {
  id: 1,
  firstname: 'Edmund',
  lastname: 'Sackbauer',
  role: UserRole.CUSTOMER,
  accepted_policies_at: null
};

const AuthContext = React.createContext<AuthContextData>({
  isAuthenticated: false,
  login: () => undefined,
  logout: () => undefined,
  user: mockUser,
  isFetching: false
});

const checkAuthenticationState = (): boolean => {
  const accessToken = sessionStorage.getItem('access_token');
  const accessTokenExpiresAt = sessionStorage.getItem(
    'access_token_expires_at'
  );
  if (
    accessToken &&
    (accessTokenExpiresAt &&
      new Date() < new Date(Number(accessTokenExpiresAt)))
  ) {
    return true;
  }
  return false;
};

class AuthProvider extends React.Component<{}, AuthContextState> {
  public state: AuthContextState = {
    isAuthenticated: checkAuthenticationState(),
    user: mockUser,
    isFetching: false
  };

  async componentDidMount() {
    await this.fetchMe();
  }

  private login = async () => {
    this.setState({ isAuthenticated: true });
    await this.fetchMe();
  };

  private logout = () => {
    sessionStorage.removeItem('access_token');
    sessionStorage.removeItem('access_token_expires_at');
    this.setState({
      isAuthenticated: false,
      user: mockUser
    });
  };

  private fetchMe = async () => {
    this.setState({ isFetching: true });
    if (this.state.isAuthenticated) {
      try {
        const res = await server.get('/users/me');
        this.setState({ user: res.data });
      } catch (error) {
        // this.logout()
      }
    }
    this.setState({ isFetching: false });
  };

  public render() {
    return (
      <AuthContext.Provider
        value={{
          isAuthenticated: this.state.isAuthenticated,
          login: this.login,
          logout: this.logout,
          user: this.state.user,
          isFetching: this.state.isFetching
        }}
      >
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}
const AuthConsumer = AuthContext.Consumer;
export { AuthProvider, AuthConsumer, AuthContext };
