import React, { ComponentType } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '../../../theme';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import classNames from 'classnames';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const typeIcons: { [key: string]: ComponentType<any> } = {
  error: ErrorIcon,
  success: CheckCircleIcon
};

export enum AlertType {
  ERROR = 'error',
  SUCCESS = 'success'
}

export interface AlertProps {
  headline?: string;
  type: AlertType;
}

const useStyles = makeStyles((theme: Theme) => ({
  right: ({ type }: AlertProps) => ({
    borderLeftWidth: 10,
    borderLeftColor:
      type === AlertType.ERROR
        ? theme.palette.error.main
        : theme.palette.primary.main,
    backgroundColor:
      type === AlertType.ERROR
        ? theme.palette.error.dark
        : theme.palette.primary.dark,
    color: '#fff',
    height: '100%'
  }),
  left: {
    textAlign: 'center'
  },
  icon: ({ type }: AlertProps) => ({
    fontSize: 40,
    color:
      type === AlertType.ERROR
        ? theme.palette.error.dark
        : theme.palette.primary.dark,
    borderWith: 20,
    borderColor: AlertType.ERROR
      ? theme.palette.error.light
      : theme.palette.primary.light
  }),
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  margin: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  headline: ({ type }: AlertProps) => ({
    color:
      type === AlertType.ERROR
        ? theme.palette.error.light
        : theme.palette.primary.light
  }),
  wrapper: {
    minHeight: 50
  }
}));

export const Alert: React.FC<AlertProps> = ({
  type,
  children,
  headline,
  ...rest
}) => {
  const classes = useStyles({ type });
  const Icon = typeIcons[type];

  return (
    <Paper style={{ marginBottom: 30 }}>
      <Grid
        container
        spacing={2}
        alignItems="center"
        justify="center"
        className={classNames(classes.wrapper, classes.margin)}
        {...rest}
      >
        <Grid item xs={2} className={classes.left}>
          <Icon className={classes.icon} />
        </Grid>
        <Grid item xs={10} style={{ padding: 0 }} className={classes.right}>
          <div style={{ padding: 20 }}>
            {headline && (
              <Typography
                variant="h5"
                className={classes.headline}
                style={{ fontWeight: 700 }}
              >
                {headline}
              </Typography>
            )}
            {children}
          </div>
        </Grid>
      </Grid>
    </Paper>
  );
};
