import React from 'react';
import PyroForm from 'pyro-form';
import { TextField } from '../../../components/primitives';
import {
  Section,
  SectionHeader,
  SectionContent
} from '../../../components/primitives/Section';
import { Button, Typography } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';

export interface FormData {
  business_name?: string;
  uid?: string;
  address_street?: string;
  address_zip?: string;
  address_city?: string;
  contact_email?: string;
  contact_phone?: string;
  contact_firstname?: string;
  contact_lastname?: string;
}

export interface FormErrors extends FormData {
  common?: string;
}

export interface FormProps {
  onSubmit: (data: FormData) => void;
  loading: boolean;
  errors?: FormErrors;
  data: FormData;
}

export const BaseForm: React.FC<FormProps> = ({
  onSubmit,
  loading,
  errors,
  data
}) => {
  return (
    <PyroForm initialValues={data} onSubmit={onSubmit} errors={errors}>
      {({ handleSubmit, hasErrors }) => (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item sm={12} md={6}>
              <Section>
                <SectionHeader>
                  <Typography variant="h4">Firma</Typography>
                </SectionHeader>
                <SectionContent>
                  <TextField<FormData>
                    name="business_name"
                    type="text"
                    label="Firma"
                    fullWidth
                    margin="normal"
                    required
                  />
                  <TextField<FormData>
                    name="address_street"
                    type="text"
                    label="Anschrift"
                    fullWidth
                    margin="normal"
                  />
                  <TextField<FormData>
                    name="address_zip"
                    type="text"
                    label="PLZ"
                    margin="normal"
                    style={{ width: '48%', marginRight: '2%' }}
                  />
                  <TextField<FormData>
                    name="address_city"
                    type="text"
                    label="Stadt"
                    margin="normal"
                    style={{ width: '50%' }}
                  />
                  <TextField<FormData>
                    name="uid"
                    type="text"
                    label="UID"
                    fullWidth
                    margin="normal"
                  />
                  <Typography variant="body1">
                    Sollte keine UID angegeben werden, gilt für diesen Kunden
                    die Steuerbefreiung!
                  </Typography>
                </SectionContent>
              </Section>
            </Grid>
            <Grid item sm={12} md={6}>
              <Section>
                <SectionHeader>
                  <Typography variant="h4">Kontakt</Typography>
                </SectionHeader>
                <SectionContent>
                  <TextField<FormData>
                    name="contact_email"
                    type="text"
                    label="E-Mail"
                    fullWidth
                    margin="normal"
                    required
                  />
                  <TextField<FormData>
                    name="contact_phone"
                    type="text"
                    label="Telefonnummer"
                    fullWidth
                    margin="normal"
                  />
                  <TextField<FormData>
                    name="contact_firstname"
                    type="text"
                    label="Kontakt Vorname"
                    fullWidth
                    margin="normal"
                  />
                  <TextField<FormData>
                    name="contact_lastname"
                    type="text"
                    label="Kontakt Nachname"
                    fullWidth
                    margin="normal"
                  />
                </SectionContent>
              </Section>
              <div style={{ marginTop: 20 }}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={loading}
                >
                  Speichern
                </Button>
              </div>
            </Grid>
          </Grid>
        </form>
      )}
    </PyroForm>
  );
};
