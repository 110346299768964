import React, { useState } from 'react';
import { Button, ButtonGroup, Typography, Toolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Add as AddIcon } from '@material-ui/icons';
import { AuthConsumer } from '../../auth/AuthContext';
import { UserRole } from '../../auth/types';
import { Theme } from '../../../theme';
import { ActionDrawer, ActionFeedback } from '../../../components/patterns';
import {
  TabNavigation,
  TabContainer
} from '../../../components/primitives/TabNavigation';
import { SimcardImport } from './SimcardImport';
import { SimcardEdit } from './SimcardEdit';
import { SimcardAssign } from './SimcardAssign';
import { SimcardList } from './SimcardList';
import { SimcardAssignmentList } from './SimcardAssignmentList';
import { CancelSimcardAssignment } from './CancelSimcardAssignment';
import { StockTransactionExport } from '../stock-transaction/StockTransactionExport';

const useStyles = makeStyles((theme: Theme) => ({
  actions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(1)
    }
  }
}));

enum SimcardAction {
  IMPORT = 'import',
  ASSIGN = 'assign',
  CANCEL_ASSIGNMENT = 'cancel_assignment',
  EDIT = 'edit',
  EXPORT = 'export'
}

const SimcardManager: React.FC = () => {
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [drawerContent, setDrawerContent] = useState<SimcardAction>();
  const [activeSimcard, setActiveSimcard] = useState<string | undefined>(
    undefined
  );
  const [activeAssignment, setActiveAssignment] = useState<string | undefined>(
    undefined
  );
  const [success, setSuccess] = useState<boolean>(false);
  const [refreshData, setRefreshData] = useState<boolean>(true);
  const [activeTab, setActiveTab] = useState<any>(0);
  const tabs = ['Datenbank', 'Zuweisungen'];

  const classes = useStyles();

  const toggleDrawer = (content?: SimcardAction) => {
    setDrawerOpen(!drawerOpen);
    setDrawerContent(content);
  };
  const onCloseActionSuccess = () => setSuccess(false);
  const editSimcard = (simcardUuid: string) => {
    setDrawerOpen(true);
    setDrawerContent(SimcardAction.EDIT);
    setActiveSimcard(simcardUuid);
  };
  const cancelSimcardAssignment = (assignmentUuid: string) => {
    setDrawerOpen(true);
    setDrawerContent(SimcardAction.CANCEL_ASSIGNMENT);
    setActiveAssignment(assignmentUuid);
  };

  const onSuccess = async () => {
    setDrawerOpen(false);
    setSuccess(true);
    setRefreshData(true);
  };

  const changeTab = (activeTab: number) => {
    setActiveTab(activeTab);
    setRefreshData(true);
  };

  const simcardSuccessMessages = new Map();
  simcardSuccessMessages.set(
    SimcardAction.ASSIGN,
    'Die Simkarten wurde erfolgreich dem Kunden zugewiesen.'
  );
  simcardSuccessMessages.set(
    SimcardAction.IMPORT,
    'Die Simkarten wurden erfolgreich importiert'
  );
  simcardSuccessMessages.set(
    SimcardAction.EDIT,
    'Die Simkarte wurde erfolgreich bearbeitet'
  );
  simcardSuccessMessages.set(
    SimcardAction.CANCEL_ASSIGNMENT,
    'Die Simkarten wurden erfolgreich ins Lager zurück gespielt.'
  );
  simcardSuccessMessages.set(
    SimcardAction.EXPORT,
    'Die Simkarten-Zuweisungen wurden erfolgreich exportiert'
  );

  return (
    <AuthConsumer>
      {({ user, isFetching }) => (
        <>
          <div style={{ flexGrow: 1 }}>
            <Toolbar>
              <Typography style={{ flexGrow: 1 }} variant="h2" gutterBottom>
                Simkarten
              </Typography>
              <ButtonGroup
                color="primary"
                aria-label="outlined primary button group"
                style={{ flex: 1 }}
              >
                {user.role === UserRole.ADMIN && (
                  <Button onClick={() => toggleDrawer(SimcardAction.IMPORT)}>
                    Simkarten importieren
                  </Button>
                )}
                {user.role === UserRole.ADMIN && (
                  <Button onClick={() => toggleDrawer(SimcardAction.EXPORT)}>
                    Simkarten Zuweisungen exportieren
                  </Button>
                )}
                <Button onClick={() => toggleDrawer(SimcardAction.ASSIGN)}>
                  Simkarten zuweisen
                </Button>
              </ButtonGroup>
            </Toolbar>
          </div>
          <TabNavigation
            tabs={tabs}
            activeTab={activeTab}
            handleChange={changeTab}
          />
          {activeTab === 0 && (
            <TabContainer>
              <SimcardList
                refreshData={refreshData}
                setRefreshData={setRefreshData}
                editAction={editSimcard}
                isAdmin={user.role === UserRole.ADMIN}
              />
            </TabContainer>
          )}
          {activeTab === 1 && (
            <TabContainer>
              <SimcardAssignmentList
                refreshData={refreshData}
                setRefreshData={setRefreshData}
                cancelAction={cancelSimcardAssignment}
                isAdmin={user.role === UserRole.ADMIN}
              />
            </TabContainer>
          )}
          <ActionDrawer open={drawerOpen} onClose={toggleDrawer} width={470}>
            {drawerContent === SimcardAction.IMPORT && (
              <SimcardImport onSuccess={onSuccess} />
            )}
            {drawerContent === SimcardAction.ASSIGN && (
              <SimcardAssign onSuccess={onSuccess} />
            )}
            {drawerContent === SimcardAction.CANCEL_ASSIGNMENT && (
              <CancelSimcardAssignment
                onSuccess={onSuccess}
                assignmentUuid={activeAssignment!}
              />
            )}
            {drawerContent === SimcardAction.EDIT && (
              <SimcardEdit onSuccess={onSuccess} simcardUuid={activeSimcard!} />
            )}
            {drawerContent === SimcardAction.EXPORT && (
              <StockTransactionExport onSuccess={onSuccess} />
            )}
          </ActionDrawer>
          <ActionFeedback
            message={simcardSuccessMessages.get(drawerContent)}
            visible={success}
            onClose={onCloseActionSuccess}
            type="success"
          />
        </>
      )}
    </AuthConsumer>
  );
};

export default SimcardManager;
