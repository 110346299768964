import React, { useState, useEffect } from 'react';
import { Loader } from '../../components/common/Loader';
import { server } from '../../utils/server';
import Button from '../../components/primitives/Button';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField
} from '@material-ui/core';
import { Topup, TopupTransaction, TopupType } from './types';
import { Customer } from '../../types';

interface EditTopupTransactionProps {
  onSuccess: () => void;
  transaction: TopupTransaction;
  topup: Topup;
}

interface FormData {
  amount?: string;
}

const TopupTransactionCreate: React.FC<EditTopupTransactionProps> = ({
  onSuccess,
  transaction,
  topup
}) => {
  const [error, setError] = useState<string | undefined>(undefined);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<FormData>({
    amount: String(transaction.amount / 100).replace('.', ',')
  });

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    if (data.amount) {
      try {
        const centAmount = Math.round(
          Number(data.amount.replace(',', '.')) * 100
        );
        console.log({ centAmount });
        await server.put(
          `/topups/${topup.uuid}/transactions/${transaction.uuid}`,
          { amount: centAmount }
        );
        setLoading(false);
        onSuccess();
      } catch (error) {
        setLoading(false);
        setError(error);
      }
    } else {
      setError('Bitte wähle einen Topup Typ und eine Datei aus!');
      setLoading(false);
    }
  };

  return (
    <>
      <Typography variant="h3">Topup bearbeiten</Typography>
      {isLoading ? (
        <Loader active={isLoading} width={100} />
      ) : (
        <>
          <Table size="medium" aria-label="transaction-details">
            <TableBody>
              <TableRow key="transaction-customer">
                <TableCell>Kunde</TableCell>
                <TableCell align="right">
                  {transaction.customer.business_name}
                </TableCell>
              </TableRow>
              <TableRow key="transaction-type">
                <TableCell>Topup Type</TableCell>
                <TableCell align="right">{transaction.type}</TableCell>
              </TableRow>
              <TableRow key="transaction-simcard">
                <TableCell>Simcard</TableCell>
                <TableCell align="right">
                  {transaction.simcard ? transaction.simcard.imei : '-'}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Provision"
              value={data && data.amount ? data.amount : 0}
              onChange={event =>
                setData({ amount: String(event.target.value) })
              }
              margin="normal"
              variant="outlined"
              fullWidth
              style={{ backgroundColor: '#fff' }}
            />
            <Button variant="outlined" color="primary" type="submit" fullWidth>
              Speichern
            </Button>
          </form>
        </>
      )}
    </>
  );
};

export default TopupTransactionCreate;
