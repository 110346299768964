import React from 'react';
// @ts-ignore
import LoadingOverlay from 'react-loading-overlay';
import { ClipLoader } from 'react-spinners';

interface LoaderProps {
  active: boolean;
  text?: string;
  width?: 50 | 100;
}

export const Loader: React.FC<LoaderProps> = ({ active, text, width = 50 }) => {
  return (
    <LoadingOverlay
      active={active}
      classNamePrefix="Loader_"
      spinner={<ClipLoader size={50} />}
      text={text}
      styles={{
        wrapper: {
          width: '0px',
          height: '0px',
          overflow: active ? 'hidden' : 'scroll'
        },
        overlay: (base: any) => ({
          ...base,
          width: `${width}%`,
          background: 'rgba(255, 255, 255, 0.5)'
        })
      }}
    />
  );
};
