import React, { useState } from 'react';
import { Typography, Grid, Fab, Button, ButtonGroup } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Campaign } from '../../types';
import CampaignList from './CampaignList';
import CampaignCreate from './CampaignCreate';
import CampaignEdit from './CampaignEdit';
import ActionFeedback from '../../components/patterns/ActionFeedback';
import ActionDrawer from '../../components/patterns/ActionDrawer';

enum CampaignAction {
  CREATE = 'create',
  EDIT = 'edit'
}

const CampaignManager: React.FC = () => {
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [drawerContent, setDrawerContent] = useState<CampaignAction>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [selectedCampaign, selectCampaign] = useState<Campaign | undefined>(
    undefined
  );
  const [error, setError] = useState<string | undefined>(undefined);
  const [success, setSuccess] = useState<boolean>(false);
  const [refreshData, setRefreshData] = useState<boolean>(true);

  const toggleDrawer = (content?: CampaignAction) => {
    setDrawerOpen(!drawerOpen);
    setDrawerContent(content);
  };
  const onCloseActionSuccess = () => setSuccess(false);

  const onSuccess = async () => {
    setDrawerOpen(false);
    setSuccess(true);
    setRefreshData(true);
  };

  const edit = (campaign: Campaign) => {
    setDrawerOpen(true);
    setDrawerContent(CampaignAction.EDIT);
    selectCampaign(campaign);
  };

  const topupSuccessMessages = new Map();
  topupSuccessMessages.set(
    CampaignAction.CREATE,
    'Die Kampagne wurde erfolgreich erstellt.'
  );
  topupSuccessMessages.set(
    CampaignAction.EDIT,
    'Die Kampagne wurde erfolgreich bearbeitet'
  );

  return (
    <>
      <div style={{ marginBottom: 30 }}>
        <Typography variant="h2" gutterBottom>
          Topups
        </Typography>
        <Button
          variant="outlined"
          size="small"
          color="primary"
          onClick={() => toggleDrawer(CampaignAction.CREATE)}
        >
          Neue Kampagne erstellen
        </Button>
      </div>
      {error && <Alert severity="error">Es ist ein Fehler aufgetreten</Alert>}

      <CampaignList
        refreshData={refreshData}
        setRefreshData={setRefreshData}
        editAction={edit}
      />
      <ActionDrawer open={drawerOpen} onClose={toggleDrawer} width={470}>
        {drawerContent === CampaignAction.CREATE && (
          <CampaignCreate onSuccess={onSuccess} />
        )}
        {drawerContent === CampaignAction.EDIT && (
          <CampaignEdit onSuccess={onSuccess} campaign={selectedCampaign!} />
        )}
      </ActionDrawer>
      <ActionFeedback
        message={topupSuccessMessages.get(drawerContent)}
        visible={success}
        onClose={onCloseActionSuccess}
        type="success"
      />
    </>
  );
};

export default CampaignManager;
