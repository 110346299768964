import React from 'react';
import {
  Theme,
  createStyles,
  makeStyles,
  withStyles
} from '@material-ui/core/styles';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Button,
  IconButton,
  Tooltip
} from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';
import { Alert, AlertTitle } from '@material-ui/lab';

import { Link } from 'react-router-dom';
import { mapToArray } from '../../../utils/helper';
import { ShopContext, CartItem } from '../ShopContext';
import { Currency } from '../../../components/primitives';
import { AppContext } from '../../AppContext';

const useStyles = makeStyles((theme: Theme) => ({
  sum: {
    borderTopStyle: 'solid',
    borderTopColor: '#444',
    borderTopWidth: 2,
    fontWeight: 700
  },
  presum: {
    borderTopStyle: 'solid',
    borderTopColor: '#444',
    borderTopWidth: 2
  },
  table: {
    '& > td': {
      padding: 5
    }
  },
  buttonRoot: {
    marginTop: 30,
    '& > *': {
      margin: theme.spacing(1)
    }
  }
}));

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    body: {
      paddingTop: 10,
      paddingBottom: 10,
      paddingLeft: 5,
      paddingRight: 5
    }
  })
)(TableCell);

interface CartProps {
  onClose: () => void;
}

export const Cart: React.FC<CartProps> = ({ onClose }) => {
  const classes = useStyles();
  return (
    <ShopContext.Consumer>
      {({
        cart,
        removeCartItem,
        calculateCartItemSum,
        calculateCartSum,
        chargeDeliveryFee,
        deliveryFee,
        deliveryFeeLimit,
        canProceedToCheckout
      }) => (
        <AppContext.Consumer>
          {({ balance }) => (
            <div>
              <Typography variant="h3">Warenkorb</Typography>
              {cart.items.size > 0 ? (
                <Table className={classes.table}>
                  <TableBody>
                    {mapToArray<CartItem>(cart.items).map(item => (
                      <TableRow key={item.productUuid}>
                        <StyledTableCell>
                          <Tooltip title="Aus dem Warenkorb entfernen">
                            <IconButton
                              size="small"
                              aria-label="Aus dem Warenkorb entfernen"
                              onClick={() => removeCartItem(item.productUuid)}
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </StyledTableCell>
                        <StyledTableCell>{`${item.quantity} x ${
                          item.text
                        }`}</StyledTableCell>
                        <StyledTableCell align="right">
                          <Currency value={item.netUnit} />
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          <Currency value={item.netUnit * item.quantity} />
                        </StyledTableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <StyledTableCell className={classes.presum} />
                      <StyledTableCell className={classes.presum} colSpan={2}>
                        Zwischensumme
                      </StyledTableCell>
                      <StyledTableCell className={classes.presum} align="right">
                        <Currency
                          value={calculateCartItemSum()}
                          wrapped={false}
                        />
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell />
                      <StyledTableCell colSpan={2}>
                        Zustellungsgebühr
                        <Typography variant="body2">
                          Zustellung ab{' '}
                          <Currency value={deliveryFeeLimit} wrapped={false} />{' '}
                          gratis!
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <Currency
                          value={chargeDeliveryFee() ? deliveryFee : 0}
                          wrapped={false}
                        />
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell className={classes.sum} />
                      <StyledTableCell className={classes.sum} colSpan={2}>
                        Summe
                      </StyledTableCell>
                      <StyledTableCell className={classes.sum} align="right">
                        <Currency value={calculateCartSum()} wrapped={false} />
                      </StyledTableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              ) : (
                <Typography variant="body1">
                  Aktuell sind keine Produkte in deinem Warenkorb
                </Typography>
              )}

              {calculateCartSum() > balance!.amount && (
                <Alert severity="error">
                  Die Summe des Warenkorbes überschreitet dein aktuelles
                  Guthaben von{' '}
                  <strong>
                    <Currency value={balance!.amount} wrapped={false} />
                  </strong>{' '}
                  !
                </Alert>
              )}

              <div className={classes.buttonRoot}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => onClose()}
                >
                  weiter Einkaufen
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!canProceedToCheckout()}
                  component={Link}
                  to="/shop/checkout"
                >
                  ZUR KASSA
                </Button>
              </div>
            </div>
          )}
        </AppContext.Consumer>
      )}
    </ShopContext.Consumer>
  );
};
