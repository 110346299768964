import React, { useState, useContext } from 'react';
import { Product, ProductType } from '../../types';
import { mapToArray, arrayToMap } from '../../utils/helper';
import { AppContext } from '../AppContext';

export interface CartItem {
  productUuid: string;
  text: string;
  quantity: number;
  netUnit: number;
  taxRate: number;
  type: ProductType;
}

export enum PaymentMethod {
  INVOICE = 'invoice',
  BALANCE = 'balance'
}

export enum OrderState {
  IN_PROGRESS = 'in_progress',
  CHECKOUT = 'checkout',
  FINISHED = 'finished'
}

interface Cart {
  items: Map<string, CartItem>;
}

interface DeliveryAddress {
  street: string;
  zip: string;
  city: string;
  country: string;
}

interface ShopContextState {
  state: OrderState;
  cart: Cart;
  deliveryAddress?: DeliveryAddress;
  deliveryFee: number;
  deliveryFeeLimit: number;
  setCart: (cart: Cart) => void;
  clearCart: () => void;
  setState: (state: OrderState) => void;
  setDeliveryAddress: (deliveryAddress: DeliveryAddress) => void;
  addCartItem: ({
    quantity,
    product
  }: {
    quantity: number;
    product: Product;
  }) => void;
  removeCartItem: (id: string) => boolean;
  feedback: string | undefined;
  setFeedback: (message: string | undefined) => void;
  calculateCartSum: () => number;
  calculateCartItemSum: () => number;
  chargeDeliveryFee: () => boolean;
  canProceedToCheckout: () => boolean;
  cartOpen: boolean;
  toggleCart: () => void;
}

const ShopContext = React.createContext<ShopContextState>(undefined!);

const loadItems = (): Map<string, CartItem> => {
  const savedCart = sessionStorage.getItem('cart');
  let items = new Map();
  if (savedCart) {
    const parsedCart: { items: Array<CartItem> } = JSON.parse(
      sessionStorage.getItem('cart')!
    );
    items = arrayToMap<CartItem>(parsedCart.items, 'productUuid');
  }
  return items;
};

const ShopProvider: React.FC = ({ children }) => {
  const [cart, setCart] = useState<Cart>({ items: loadItems() });
  const [cartOpen, setCartOpen] = useState<boolean>(false);
  const [deliveryAddress, setDeliveryAddress] = useState<
    DeliveryAddress | undefined
  >(undefined);
  const [state, setState] = useState<OrderState>(OrderState.IN_PROGRESS);
  const [feedback, setFeedback] = useState<string | undefined>(undefined);
  const deliveryFee = 490;
  const deliveryFeeLimit = 5000;

  const appContext = useContext(AppContext);
  if (!appContext) throw new Error('MissingAppContext');

  const canProceedToCheckout = (): boolean =>
    cart.items.size > 0 && calculateCartSum() < appContext.balance!.amount;

  const toggleCart = () => setCartOpen(!cartOpen);
  const addCartItem = ({
    quantity,
    product
  }: {
    quantity: number;
    product: Product;
  }): void => {
    const existingProduct = cart.items.get(product.uuid);
    if (existingProduct) {
      cart.items.set(product.uuid, {
        ...existingProduct,
        quantity: existingProduct.quantity + quantity
      });
    } else {
      cart.items.set(product.uuid, {
        productUuid: product.uuid,
        quantity,
        taxRate: product.tax_rate,
        text: product.name,
        netUnit: product.price,
        type: product.type
      });
    }
    setCart(cart);
    setFeedback(
      `${quantity} x ${product.name} wurden zum Warenkorb hinzugefügt`
    );
    setCartOpen(true);
    updateStorage();
  };
  const removeCartItem = (id: string): boolean => {
    cart.items.delete(id);
    setCart(cart);
    setFeedback(`Das Produkt wurde aus dem Warenkorb entfernt`);
    updateStorage();
    return true;
  };
  const calculateCartItemSum = (): number =>
    mapToArray<CartItem>(cart.items).reduce(
      (sum, item) => sum + item.netUnit * item.quantity,
      0
    );
  const calculateCartSum = (): number =>
    calculateCartItemSum() + (chargeDeliveryFee() ? deliveryFee : 0);
  const chargeDeliveryFee = (): boolean =>
    calculateCartItemSum() < deliveryFeeLimit &&
    mapToArray<CartItem>(cart.items).some(
      item => item.type === ProductType.SIMCARD
    );
  const clearCart = (): void => {
    sessionStorage.setItem(
      'cart',
      JSON.stringify({ items: mapToArray<CartItem>(new Map()) })
    );
    sessionStorage.setItem('state', OrderState.IN_PROGRESS);
  };
  const updateStorage = (): void => {
    console.log(cart.items);
    const items = mapToArray<CartItem>(cart.items);
    sessionStorage.setItem('cart', JSON.stringify({ items }));
    sessionStorage.setItem('state', state);
  };

  return (
    <ShopContext.Provider
      value={{
        cart,
        deliveryAddress,
        state,
        setCart,
        clearCart,
        setState,
        setDeliveryAddress,
        addCartItem,
        removeCartItem,
        calculateCartItemSum,
        calculateCartSum,
        feedback,
        setFeedback,
        canProceedToCheckout,
        chargeDeliveryFee,
        deliveryFee,
        deliveryFeeLimit,
        toggleCart,
        cartOpen
      }}
    >
      {children}
    </ShopContext.Provider>
  );
};

const ShopConsumer = ShopContext.Consumer;
export { ShopProvider, ShopConsumer, ShopContext };
