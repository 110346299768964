import React, { useState, useEffect } from 'react';
import { AppRoutes } from './AppRouting';
import DashboardIcon from '@material-ui/icons/Dashboard';
import { Customer, Balance } from '../types';
import { server } from '../utils/server';

export interface MenuItem {
  label: string;
  route: AppRoutes;
  color: string;
  icon: any;
  scopes: Array<'customer' | 'admin' | 'employee'>;
}

interface GetCustomersResult {
  rows: Array<Customer>;
  total: number;
}

interface AppContextState {
  customer?: Customer;
  balance?: Balance;
  isFetching: boolean;
  updateBalance: (newBalance: number) => void;
  activeMenuItem: MenuItem;
  setActiveMenuItem: (options: MenuItem) => void;
}

const AppContext = React.createContext<Partial<AppContextState>>({});

const AppProvider: React.FC = ({ children }) => {
  const [activeMenuItem, setActiveMenuItem] = useState<MenuItem>({
    route: AppRoutes.DASHBOARD,
    color: '#000',
    label: 'Dashboard',
    icon: <DashboardIcon />,
    scopes: ['customer', 'admin']
  });
  const [isFetching, setFetching] = useState<boolean>(true);
  const [customer, setCustomer] = useState<Customer | undefined>(undefined);
  const [balance, setBalance] = useState<Balance | undefined>(undefined);

  useEffect(() => {
    fetchCustomer().then(customer => {
      setCustomer(customer);
      if (customer) setBalance(customer.balance);
    });
  }, []);

  const fetchCustomer = async (): Promise<Customer | undefined> => {
    setFetching(true);
    try {
      const res: { data: GetCustomersResult } = await server.get(`/customers`);
      const [customer] = res.data.rows;
      setFetching(false);
      return customer;
    } catch (error) {
      setFetching(false);
      // @ts-ignore
      window.location.href('/logout');
    }
  };

  const updateBalance = (newBalance: number) => {
    if (!balance) {
      throw new Error('noBalanceAvailableInAppContext');
    }
    setBalance({ id: balance.id, amount: newBalance });
  };

  return (
    <AppContext.Provider
      value={{
        activeMenuItem,
        customer,
        balance,
        isFetching,
        updateBalance,
        setActiveMenuItem
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const AppConsumer = AppContext.Consumer;
export { AppProvider, AppConsumer, AppContext };
