import React from 'react';
import MUITable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import MUITableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '../../../theme';
import { Typography, Card, CardContent } from '@material-ui/core';
import { TableCellProps } from '@material-ui/core/TableCell';

interface Row {
  [key: string]: any;
}

interface TableHeader {
  label: string;
  align?: 'left' | 'center' | 'right';
}

interface TableProps {
  renderRow: (row: Row) => void;
  headers: Array<TableHeader>;
  rows: Array<Row>;
  noBorder?: boolean;
  noHeader?: boolean;
}

interface StyleProperties {
  noBorder?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  headerCell: {
    fontSize: 16,
    fontWeight: 600
  },
  cell: {
    fontSize: 16,
    paddingTop: 15,
    paddingBottom: 15
  },
  card: (props: StyleProperties) => ({
    border: props.noBorder ? 'none' : `1px solid ${theme.palette.divider}`
  })
}));

export const Table: React.FC<TableProps> = ({
  headers,
  rows,
  renderRow,
  noBorder = false,
  noHeader = false
}) => {
  const classes = useStyles({ noBorder });
  return (
    <Card className={classes.card} elevation={0}>
      <MUITable>
        {!noHeader && (
          <TableHead>
            <TableRow>
              {headers.map(header => (
                <MUITableCell
                  key={`header_${header.label}`}
                  className={classes.headerCell}
                  align={header.align || 'left'}
                >
                  {header.label}
                </MUITableCell>
              ))}
            </TableRow>
          </TableHead>
        )}
        <TableBody>{rows.map(row => renderRow(row))}</TableBody>
      </MUITable>
    </Card>
  );
};

export const TableCell: React.FC<TableCellProps> = ({ children, ...rest }) => {
  const classes = useStyles({});
  return (
    <MUITableCell className={classes.cell} {...rest}>
      {children}
    </MUITableCell>
  );
};
