import React, { useEffect } from 'react';
import PyroForm from 'pyro-form';
import { TextField } from '../../../components/primitives';
import { Button, Typography } from '@material-ui/core';

export interface FormData {
  email: string;
  firstname: string;
  lastname: string;
}

export interface FormErrors extends FormData {
  common?: string;
}

export interface FormProps {
  onSubmit: (data: FormData) => void;
  loading: boolean;
  errors?: FormErrors;
  data: FormData;
}

export const UserForm: React.FC<FormProps> = ({
  onSubmit,
  loading,
  errors,
  data
}) => {
  useEffect(() => {
    console.log(`render user form`, data);
  });
  return (
    <PyroForm initialValues={data} onSubmit={onSubmit} errors={errors}>
      {({ handleSubmit, hasErrors }) => (
        <form onSubmit={handleSubmit}>
          <TextField<FormData>
            name="email"
            type="text"
            label="Email-Adresse"
            fullWidth
            margin="normal"
            required
          />
          <Typography variant="body2">
            Die E-Mail wird beim Login als Benutzername verwendet.
          </Typography>
          <TextField<FormData>
            name="firstname"
            type="text"
            label="Vorname"
            fullWidth
            margin="normal"
            required
          />
          <TextField<FormData>
            name="lastname"
            type="text"
            label="Nachname"
            fullWidth
            margin="normal"
            required
          />
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={loading}
          >
            Speichern
          </Button>
        </form>
      )}
    </PyroForm>
  );
};
