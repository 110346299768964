import React, { useState, useEffect } from 'react';
import { Alert } from '@material-ui/lab';
import {
  server,
  defaultErrorMessage,
  stringifyQuery
} from '../../utils/server';
import {
  BalanceTransaction,
  BalanceTransactionType,
  Customer
} from '../../types';
import {
  DataTable,
  FormattedDate,
  Chip,
  ChipType,
  Currency
} from '../../components/primitives';
import { Loader } from '../../components/common/Loader';

interface FetchDataOptions {
  filter?: BalanceTransactionListFilter;
}

interface BalanceTransactionListProps {
  isAdmin: boolean;
}

interface BalanceTransactionListFilter {
  balanceId?: number;
  type?: BalanceTransactionType;
}

interface BalanceTransactionListResult {
  total: number;
  rows: Array<BalanceTransaction>;
}

export const BalanceTransactionsList: React.FC<BalanceTransactionListProps> = ({
  isAdmin
}) => {
  const [data, setData] = useState<Array<BalanceTransaction>>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [page, setPage] = useState<number>(0);
  const [filter, setFilter] = useState<BalanceTransactionListFilter>({});
  const [count, setCount] = useState<number>(0);
  const [loadingError, setLoadingError] = useState<string | undefined>(
    undefined
  );
  const [customers, setCustomers] = useState<Array<Customer>>([]);

  if (isAdmin) {
    useEffect(() => {
      fetchCustomers().then(setCustomers);
    }, []);
    const fetchCustomers = async (): Promise<Array<Customer>> => {
      setLoading(true);
      try {
        const res = await server.get('/customers?limit=1000');
        console.log({ data: res.data });
        setLoading(false);
        return res.data.rows;
      } catch (error) {
        setLoading(false);
        return [];
      }
    };
  }

  useEffect(() => {
    fetchData({ filter }).then(data => {
      setData(data.rows);
      setCount(data.total);
    });
  }, [page, filter]);

  const fetchData = async ({
    filter = {}
  }: FetchDataOptions): Promise<BalanceTransactionListResult> => {
    setLoading(true);
    try {
      const queryString = stringifyQuery(filter);
      const res = await server.get(
        `/balances/transactions?page=${queryString ? 0 : page}${queryString &&
          '&' + queryString}`
      );
      setLoading(false);
      console.log(res.data);
      return res.data;
    } catch (error) {
      setLoading(false);
      setLoadingError(defaultErrorMessage);
      return { total: 0, rows: [] };
    }
  };

  const handleFilterSubmit = (applyFilters: () => Array<any>): void => {
    const filterList = applyFilters();
    const filter: BalanceTransactionListFilter = {};
    console.log(filterList);
    if (filterList[1].length) {
      const customer = customers.find(
        customer => customer.business_name === filterList[1][0]
      );
      if (customer) filter.balanceId = customer.balance.id;
    }
    filter.type = filterList[2].length ? filterList[2][0] : undefined;
    setFilter(filter);
    setPage(0);
  };

  const getColumns = (customers: Array<Customer>) => [
    {
      name: 'date',
      label: 'gebucht am',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'customer',
      label: 'Kunde',
      options: {
        filter: isAdmin,
        filterType: 'dropdown' as any,
        filterOptions: {
          names: customers.map(c => c.business_name),
          fullWidth: true
        },
        customFilterListOptions: { render: (v: string) => `Kunde: ${v}` },
        sort: true
      }
    },
    {
      name: 'type',
      label: 'Type',
      options: {
        filter: true,
        filterType: 'dropdown' as any,
        filterOptions: {
          names: [BalanceTransactionType.ORDER, BalanceTransactionType.TOPUP],
          fullWidth: true
        },
        customFilterListOptions: {
          render: (v: BalanceTransactionType) => `Typ: ${v}`
        },
        sort: true
      }
    },
    {
      name: 'description',
      label: 'Buchungstext',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'amount',
      label: 'Betrag',
      options: {
        filter: false,
        sort: true
      }
    }
  ];

  const prepareRows = (rows: Array<BalanceTransaction>): Array<Array<any>> => {
    const optimizedRows = rows.map(row => ({
      date: <FormattedDate date={row.date} wrapped={false} />,
      customer: row.customer ? row.customer.business_name : '-',
      type: row.type,
      description: row.description,
      amount: (
        <Chip
          size="small"
          label={<Currency value={row.amount} wrapped={false} />}
          type={row.amount > 0 ? 'success' : 'error'}
        />
      )
    }));
    return optimizedRows.map(row => Object.values(row));
  };

  return (
    <>
      {loadingError && <Alert severity="error">{loadingError}</Alert>}
      {isLoading && <Loader active={isLoading} width={100} />}
      <DataTable
        title={''}
        data={prepareRows(data)}
        columns={getColumns(customers)}
        handleFilterSubmit={handleFilterSubmit}
        filterEnabled={true}
        searchEnabled={false}
        options={{
          page,
          count,
          onChangePage: async (page: number) => {
            setPage(page);
          }
        }}
      />
    </>
  );
};
