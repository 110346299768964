import React, { useState } from 'react';
import { AuthConsumer } from '../../auth/AuthContext';
import { UserRole } from '../../auth/types';
import { ActionDrawer, ActionFeedback } from '../../../components/patterns';
import { StockTransactionList } from './StockTransactionList';
import { VoucherPrint } from '../voucher/VoucherPrint';
import { ProductType, StockTransactionState } from '../../../types';
import { StockTransactionCancelRequest } from './StockTransactionCancelRequest';

enum StockTransactionAction {
  CANCEL_REQUEST = 'cancel-request',
  PRINT = 'print'
}

interface StockTransactionManagerProps {
  productType: ProductType;
}

const stockTransactionSuccessMessages = new Map();
stockTransactionSuccessMessages.set(
  StockTransactionAction.CANCEL_REQUEST,
  'Dein Antrag auf Stornierung ist eingegangen. Wir werden deine Anfrage überprüfen und dir so schnell wie mögich eine Antwort geben.'
);
stockTransactionSuccessMessages.set(
  StockTransactionAction.PRINT,
  'Der Voucher wurde erfolgreich ausgedruckt'
);

const StockTransactionManager: React.FC<StockTransactionManagerProps> = ({
  productType
}) => {
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [drawerContent, setDrawerContent] = useState<StockTransactionAction>();
  const [activeStockTransaction, setActiveStockTransaction] = useState<
    string | undefined
  >(undefined);
  const [success, setSuccess] = useState<boolean>(false);
  const [refreshData, setRefreshData] = useState<boolean>(true);

  const toggleDrawer = (content?: StockTransactionAction) => {
    setDrawerOpen(!drawerOpen);
    setDrawerContent(content);
  };
  const onCloseActionSuccess = () => setSuccess(false);
  const requestCancellationAction = (stockTransactionUuid: string) => {
    setDrawerOpen(true);
    setDrawerContent(StockTransactionAction.CANCEL_REQUEST);
    setActiveStockTransaction(stockTransactionUuid);
  };
  const printAction = (stockTransactionUuid: string) => {
    setDrawerOpen(true);
    setDrawerContent(StockTransactionAction.PRINT);
    setActiveStockTransaction(stockTransactionUuid);
  };

  const onSuccess = async () => {
    setDrawerOpen(false);
    setSuccess(true);
    setRefreshData(true);
  };

  return (
    <AuthConsumer>
      {({ user, isFetching }) => (
        <>
          <div>
            <StockTransactionList
              refreshData={refreshData}
              setRefreshData={setRefreshData}
              isAdmin={user.role === UserRole.ADMIN}
              printAction={printAction}
              requestCancellationAction={requestCancellationAction}
              type={productType}
            />
          </div>
          <ActionDrawer
            open={drawerOpen}
            onClose={() => toggleDrawer()}
            width={470}
          >
            {drawerContent === StockTransactionAction.PRINT && (
              <VoucherPrint
                onSuccess={onSuccess}
                stockTransactionUuid={activeStockTransaction!}
              />
            )}
            {drawerContent === StockTransactionAction.CANCEL_REQUEST && (
              <StockTransactionCancelRequest
                onSuccess={onSuccess}
                stockTransactionUuid={activeStockTransaction!}
              />
            )}
          </ActionDrawer>
          <ActionFeedback
            message={stockTransactionSuccessMessages.get(drawerContent)}
            visible={success}
            onClose={onCloseActionSuccess}
            type="success"
          />
        </>
      )}
    </AuthConsumer>
  );
};

export default StockTransactionManager;
