import React, { useState, useEffect } from 'react';
import { Typography, IconButton } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Edit as EditIcon } from '@material-ui/icons';
import {
  server,
  defaultErrorMessage,
  stringifyQuery
} from '../../../utils/server';
import { Simcard, StockItemState, Product } from '../../../types';
import {
  DataTable,
  FormattedDate,
  Chip,
  ChipType
} from '../../../components/primitives';
import { Loader } from '../../../components/common/Loader';

interface SimcardListProps {
  isAdmin?: boolean;
  refreshData: boolean;
  setRefreshData: (status: boolean) => void;
  editAction: (productUuid: string) => void;
}

interface FetchDataOptions {
  filter?: SimcardListFilter;
}

interface SimcardListFilter {
  state?: 'AVAILABLE' | 'SOLD' | 'RESERVED';
  productName?: string;
  imeiSearch?: string;
}

interface SimcardListResult {
  total: number;
  rows: Array<Simcard>;
}

export const SimcardList: React.FC<SimcardListProps> = ({
  refreshData,
  setRefreshData,
  editAction,
  isAdmin = false
}) => {
  const [data, setData] = useState<Array<Simcard>>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [page, setPage] = useState<number>(0);
  const [filter, setFilter] = useState<SimcardListFilter>({});
  const [count, setCount] = useState<number>(0);
  const [hpp, setHpp] = useState<number>(20);
  const [loadingError, setLoadingError] = useState<string | undefined>(
    undefined
  );
  const [products, setProducts] = useState<Array<Product>>([]);

  useEffect(() => {
    fetchProducts().then(products => setProducts(products));
  }, []);
  const fetchProducts = async (): Promise<Array<Product>> => {
    setLoading(true);
    try {
      const res = await server.get('/products?type=SIMCARD');
      setLoading(false);
      return res.data;
    } catch (error) {
      setLoading(false);
      return [];
    }
  };

  useEffect(() => {
    if (refreshData) {
      setRefreshData(false);
      fetchData({}).then(data => {
        setData(data.rows);
        setCount(data.total);
      });
    }
  }, [[refreshData]]);

  useEffect(() => {
    fetchData({ filter }).then(data => {
      setData(data.rows);
      setCount(data.total);
    });
  }, [page, filter]);

  const fetchData = async ({
    filter = {}
  }: FetchDataOptions): Promise<SimcardListResult> => {
    setLoading(true);
    try {
      const queryString = stringifyQuery(filter);
      const res = await server.get(
        `/stock/simcards?page=${queryString ? 0 : page}${queryString &&
          '&' + queryString}`
      );
      setLoading(false);
      console.log(res.data);
      return res.data;
    } catch (error) {
      setLoading(false);
      setLoadingError(defaultErrorMessage);
      return { total: 0, rows: [] };
    }
  };

  const handleFilterSubmit = (applyFilters: () => Array<any>): void => {
    const filterList = applyFilters();
    const filter: SimcardListFilter = {};
    filter.imeiSearch = filterList[0].length ? filterList[0][0] : undefined;
    filter.productName = filterList[2].length ? filterList[2][0] : undefined;
    filter.state = filterList[3].length ? filterList[3][0] : undefined;
    setFilter(filter);
    setPage(0);
  };

  const getColumns = (products: Array<Product>) => [
    {
      name: 'serial',
      label: 'IMEI',
      options: {
        filter: true,
        filterType: 'textField' as any,
        filterOptions: {
          fullWidth: true
        },
        customFilterListOptions: { render: (v: string) => `IMEI: ${v}` },
        sort: true
      }
    },
    {
      name: 'date',
      label: 'Importiert am',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'product',
      label: 'Produkt',
      options: {
        filter: true,
        filterType: 'dropdown' as any,
        filterOptions: { names: products.map(p => p.name), fullWidth: true },
        customFilterListOptions: { render: (v: string) => `Produkt: ${v}` },
        sort: true
      }
    },
    {
      name: 'customer',
      label: 'Kunde',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'state',
      label: 'Status',
      options: {
        filter: true,
        filterType: 'dropdown' as any,
        filterOptions: {
          names: [
            StockItemState.AVAILABLE,
            StockItemState.RESERVED,
            StockItemState.SOLD
          ],
          fullWidth: true
        },
        customFilterListOptions: {
          render: (v: StockItemState) => `Status: ${v}`
        },
        sort: true
      }
    }
  ];

  const mapSimcardStateToChipType = (
    state: 'SOLD' | 'AVAILABLE' | 'RESERVED'
  ): ChipType => {
    switch (state) {
      case 'SOLD':
        return 'error';
      case 'AVAILABLE':
        return 'success';
      case 'RESERVED':
        return 'warning';
      default:
        return 'info';
    }
  };

  const prepareRows = (rows: Array<Simcard>): Array<Array<any>> => {
    const optimizedRows = rows.map(row => ({
      serial: row.imei,
      date: <FormattedDate date={row.created_at} wrapped={false} />,
      product: row.product.name,
      customer: row.customer ? row.customer.business_name : '-',
      state: (
        <Chip
          size="small"
          label={row.state}
          type={mapSimcardStateToChipType(row.state)}
        />
      )
    }));
    return optimizedRows.map(row => Object.values(row));
  };

  return (
    <>
      {loadingError && <Alert severity="error">{loadingError}</Alert>}
      {isLoading && <Loader active={isLoading} width={100} />}
      <DataTable
        title={''}
        data={prepareRows(data)}
        columns={getColumns(products)}
        handleFilterSubmit={handleFilterSubmit}
        filterEnabled={true}
        searchEnabled={false}
        options={{
          page,
          count,
          onChangePage: async (page: number) => {
            setPage(page);
          }
        }}
      />
    </>
  );
};
