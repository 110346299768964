import React from 'react';
import PyroForm from 'pyro-form';
import { TextField } from '../../../components/primitives';
import { Button, Typography } from '@material-ui/core';

export interface ResetPasswordConfirmParameter {
  password: string;
  passwordConfirmation: string;
}

export interface ResetPasswordConfirmFormProps {
  onSubmit: (data: ResetPasswordConfirmParameter) => void;
  loading: boolean;
}

interface InitialValues {
  password: string;
  passwordConfirmation: string;
}

const initialValues: InitialValues = { password: '', passwordConfirmation: '' };

export const ResetPasswordConfirmForm: React.FC<
  ResetPasswordConfirmFormProps
> = ({ onSubmit, loading }) => {
  return (
    <PyroForm initialValues={initialValues} onSubmit={onSubmit}>
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Typography variant="h2" gutterBottom style={{ fontWeight: 700 }}>
            Neues Passwort setzen
          </Typography>
          <Typography variant="body1" gutterBottom>
            Gib bitte zweimal dein neues Passwort an. Nach der Bestätigung
            kannst du dich mit deinem neuen Passwort anmelden!
          </Typography>
          <TextField<InitialValues>
            name="password"
            type="password"
            label="Neues Passwort"
            fullWidth
            margin="normal"
          />
          <TextField<InitialValues>
            name="passwordConfirmation"
            type="password"
            label="Neues Passwort bestätigen"
            fullWidth
            margin="normal"
          />
          <Button
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
            disabled={loading}
          >
            Neues Passwort bestätigen
          </Button>
        </form>
      )}
    </PyroForm>
  );
};
