import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
} from '@material-ui/core';

interface OrderCancellationConfirmationProps {
  open: boolean;
  handleConfirm: () => void;
  handleClose: () => void;
}

export const StockTransactionCancellationConfirmation: React.FC<
  OrderCancellationConfirmationProps
> = ({ open, handleConfirm, handleClose }) => {
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">Transaktion stornieren</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Willst du die Transaktion wirklich stornieren? Bei der Stornierung
          wird der Voucher wieder zum Verkauf freigegeben.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Abbrechen
        </Button>
        <Button onClick={handleConfirm} color="primary">
          Stornierung bestätigen
        </Button>
      </DialogActions>
    </Dialog>
  );
};
