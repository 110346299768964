import React, { useState, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import ShopWrapper from './ShopWrapper';
import { Product, ProductType } from '../../types';
import { server } from '../../utils/server';
import { ShopContext } from './ShopContext';
import { Loader } from '../../components/common/Loader';
import { ProductList } from './components/ProductList';
import {
  TabNavigation,
  TabContainer
} from '../../components/primitives/TabNavigation';
import StockTransactionManager from '../stock/stock-transaction/StockTransactionManager';
import { QuantitySelection } from './components/QuantitySelection';

export const ShopSimcard: React.FC = () => {
  const [products, setProducts] = useState<Array<Product>>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [activeTab, setActiveTab] = useState<any>(0);
  const [quantitySelectionOpen, setQuantitySelectionVisibility] = useState<
    boolean
  >(false);
  const [selectedProduct, setSelectedProduct] = useState<Product | undefined>(
    undefined
  );
  const tabs = ['Einkaufen', 'Archiv'];

  useEffect(() => {
    fetchData().then(data => setProducts(data));
  }, []);

  const fetchData = async (): Promise<Array<Product>> => {
    setLoading(true);
    try {
      const res = await server.get('/products?active=1');
      setLoading(false);
      return res.data;
    } catch (error) {
      setLoading(false);
      return [];
    }
  };

  const onSelectProduct = (product: Product) => {
    setSelectedProduct(product);
    setQuantitySelectionVisibility(true);
  };

  return (
    <div>
      <Typography variant="h3">Shop Simkarten</Typography>
      <TabNavigation
        tabs={tabs}
        activeTab={activeTab}
        handleChange={setActiveTab}
      />
      {activeTab === 0 && (
        <TabContainer>
          <ShopWrapper>
            <ShopContext.Consumer>
              {({ addCartItem }) =>
                isLoading ? (
                  <Loader active={isLoading} width={100} />
                ) : (
                  <>
                    <ProductList
                      data={products.filter(
                        p => p.type === ProductType.SIMCARD
                      )}
                      headline="Simkarten"
                      onSelectProduct={onSelectProduct}
                    />
                    <QuantitySelection
                      open={quantitySelectionOpen}
                      handleClose={() => setQuantitySelectionVisibility(false)}
                      handleSelect={(quantity: number) => {
                        console.log(quantity);
                        addCartItem({ quantity, product: selectedProduct! });
                        setQuantitySelectionVisibility(false);
                      }}
                      maxQuantity={5}
                    />
                  </>
                )
              }
            </ShopContext.Consumer>
          </ShopWrapper>
        </TabContainer>
      )}
      {activeTab === 1 && (
        <TabContainer>
          <StockTransactionManager productType={ProductType.SIMCARD} />
        </TabContainer>
      )}
    </div>
  );
};
