import React from 'react';
import MUIButton, {
  ButtonProps as MUIButtonProps
} from '@material-ui/core/Button';

interface ButtonProps extends MUIButtonProps {}

const Button: React.FC<ButtonProps> = ({ children, ...rest }) => (
  <MUIButton {...rest}>{children}</MUIButton>
);

export default Button;
