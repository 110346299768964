import React, { useContext, useEffect } from 'react';
import { AuthContext } from './AuthContext';
import { RouteComponentProps, withRouter } from 'react-router-dom';

export const Logout: React.FC<RouteComponentProps<{}>> = ({ history }) => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('The wrapping context was not correctly defined');
  }

  useEffect(() => {
    context.logout();
    history.push('/login');
  });

  return null;
};

export default withRouter(Logout);
