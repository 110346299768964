import React from 'react';
import MuiChip, { ChipProps as MuiChipProps } from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/styles';
import { green, red, yellow, blue } from '@material-ui/core/colors';
import { Theme } from '../../../theme';

export type ChipType = 'success' | 'error' | 'warning' | 'info';

export interface ChipProps extends MuiChipProps {
  type: ChipType;
}

const useStyles = makeStyles((theme: Theme) => {
  return {
    success: {
      backgroundColor: green[600],
      color: '#fff'
    },
    error: {
      backgroundColor: red[600],
      color: '#fff'
    },
    warning: {
      backgroundColor: yellow[600],
      color: '#fff'
    },
    info: {
      backgroundColor: blue[600],
      color: '#fff'
    }
  };
});

export const Chip: React.FC<ChipProps> = ({ type, ...rest }) => {
  const classes = useStyles();
  return <MuiChip {...rest} className={classes[type]} />;
};
