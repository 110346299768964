import React, { useState } from 'react';
import { Theme } from '../../theme';
import { makeStyles } from '@material-ui/styles';
import Drawer from './Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppRouting from '../../pages/AppRouting';
import AppBar from './AppBar';
import { AppProvider } from '../../pages/AppContext';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex'
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(4)
  },
  contentInner: {}
}));

const Layout: React.FC = () => {
  const classes = useStyles();
  const [state, setState] = useState({ drawerOpen: true });

  const handleDrawerOpen = () => {
    setState({ drawerOpen: true });
  };

  const handleDrawerClose = () => {
    setState({ drawerOpen: false });
  };

  return (
    <AppProvider>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          title="ONLINESIM"
          drawerOpen={state.drawerOpen}
          handleDrawerOpen={handleDrawerOpen}
          drawerWidth={drawerWidth}
        />
        <Drawer
          isOpen={state.drawerOpen}
          handleDrawerClose={handleDrawerClose}
          drawerWidth={drawerWidth}
        />
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <div>
            <AppRouting />
          </div>
        </main>
      </div>
    </AppProvider>
  );
};

export default Layout;
