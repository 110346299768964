import React, { useState, useEffect } from 'react';
import { server } from '../../utils/server';
import { Typography } from '@material-ui/core';
import { Loader } from '../../components/common/Loader';
import { UserForm, FormErrors, FormData } from './components/UserForm';
import { sanitize } from '../../utils/helper';

interface UserEditProps {
  userUuid?: string;
  onSuccess: () => void;
}

const UserEdit: React.FC<UserEditProps> = ({ userUuid, onSuccess }) => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<FormErrors | undefined>(undefined);
  const [formData, setFormData] = useState<FormData | undefined>(undefined);

  useEffect(() => {
    if (userUuid) {
      getUser();
    }
  }, []);

  const getUser = async () => {
    setLoading(true);
    try {
      const res = await server.get(`users/${userUuid}`);
      setFormData({ ...res.data });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  const onSubmit = async (data: FormData) => {
    setLoading(true);
    try {
      const res = await server.put(`/users/${userUuid}`, {
        // @ts-ignore
        ...sanitize(data)
      });
      setLoading(false);
      onSuccess();
    } catch (error) {
      setLoading(false);
      setFormData(data);
      setError(error);
    }
  };

  return (
    <>
      <Typography variant="h3">Benutzer bearbeiten</Typography>
      {isLoading && <Loader active={isLoading} width={100} />}
      <>
        {formData && (
          <UserForm
            onSubmit={onSubmit}
            loading={isLoading}
            errors={error}
            data={{
              email: formData.email,
              firstname: formData.firstname,
              lastname: formData.lastname
            }}
          />
        )}
      </>
    </>
  );
};

export default UserEdit;
