import React, { useState, useEffect } from 'react';
import {
  Typography,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Button
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Loader } from '../../../components/common/Loader';
import { FormattedDate } from '../../../components/primitives';
import { SimcardAssignment } from '../../../types';
import { server } from '../../../utils/server';

interface CancelSimcardAssignmentProps {
  onSuccess: () => void;
  assignmentUuid: string;
}

export const CancelSimcardAssignment: React.FC<
  CancelSimcardAssignmentProps
> = ({ onSuccess, assignmentUuid }) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [loadingError, setLoadingError] = useState<string | undefined>(
    undefined
  );
  const [error, setError] = useState<string | undefined>(undefined);
  const [assignment, setAssignment] = useState<SimcardAssignment | undefined>(
    undefined
  );

  useEffect(() => {
    fetchData().then(setAssignment);
  }, []);

  const fetchData = async (): Promise<SimcardAssignment | undefined> => {
    setLoading(true);
    try {
      const res = await server.get(
        `/stock/simcard-assignments/${assignmentUuid}`
      );
      setLoading(false);
      return res.data;
    } catch (error) {
      setLoading(false);
      setLoadingError('Fehler beim Ladens des Vouchers');
      return undefined;
    }
  };

  const onCancelAssignment = async (assignmentUuid: string) => {
    setLoading(true);
    try {
      const res = await server.post(
        `/stock/simcard-assignments/${assignmentUuid}/cancellations`
      );
      setLoading(false);
      onSuccess();
    } catch (error) {
      setLoading(false);
      setError(error.common);
    }
  };

  return (
    <>
      <Typography variant="h3">Zuweisung stornieren</Typography>
      {loadingError && <Alert severity="error">{loadingError}</Alert>}
      {error && <Alert severity="error">{error}</Alert>}
      {isLoading && <Loader active={isLoading} width={100} />}
      {assignment && (
        <>
          <List dense>
            <ListItem key="created_at">
              <ListItemText primary={'Zugewiesen am'} />
              <ListItemSecondaryAction>
                <FormattedDate date={assignment.created_at} wrapped={false} />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem key="employee">
              <ListItemText primary={'Mitarbeiter'} />
              <ListItemSecondaryAction>
                <Typography variant="body1">
                  {assignment.created_by.firstname}{' '}
                  {assignment.created_by.lastname}
                </Typography>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem key="customer">
              <ListItemText primary={'Kunde'} />
              <ListItemSecondaryAction>
                <Typography variant="body1">
                  {assignment.customer.business_name}
                </Typography>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem key="product">
              <ListItemText primary={'Produkt'} />
              <ListItemSecondaryAction>
                <Typography variant="body1">
                  {assignment.product.name}
                </Typography>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem key="imeis">
              <ListItemText primary={'IMEIs'} />
              <ListItemSecondaryAction>
                <Typography variant="body1">
                  {assignment.imei_from} - {assignment.imei_until}
                </Typography>
              </ListItemSecondaryAction>
            </ListItem>
          </List>
          <Typography variant="body1" style={{ marginTop: 20 }}>
            Bist du dir sicher, dass du die Simkarten zuweisen stornieren
            willst. Wenn du die Zuweisung stornierst, werden alle Simkarten
            wieder zum Verkauf freigegeben.
          </Typography>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => onCancelAssignment(assignment.uuid)}
            style={{ marginTop: 20 }}
          >
            Ja, ich möchte jetzt stornieren
          </Button>
        </>
      )}
    </>
  );
};
