import React from 'react';
import PyroForm from 'pyro-form';
import { TextField, Alert, AlertType } from '../../../components/primitives';
import { Button, Typography } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';

export interface FormData {
  business_name?: string;
  salesforce_id?: string;
  uid?: string;
  address_street?: string;
  address_zip?: string;
  address_city?: string;
  contact_email?: string;
  contact_phone?: string;
  contact_firstname?: string;
  contact_lastname?: string;
}

export interface FormErrors extends FormData {
  common?: string;
}

export interface FormProps {
  onSubmit: (data: FormData) => void;
  loading: boolean;
  errors?: FormErrors;
  data: FormData;
}

export const CustomerForm: React.FC<FormProps> = ({
  onSubmit,
  loading,
  errors,
  data
}) => {
  return (
    <PyroForm initialValues={data} onSubmit={onSubmit} errors={errors}>
      {({ handleSubmit, hasErrors }) => (
        <form onSubmit={handleSubmit}>
          <TextField<FormData>
            name="business_name"
            type="text"
            label="Firma"
            fullWidth
            margin="normal"
            required
          />
          <TextField<FormData>
            name="salesforce_id"
            type="text"
            label="Salesforce ID"
            fullWidth
            margin="normal"
            required
          />
          <TextField<FormData>
            name="address_street"
            type="text"
            label="Anschrift"
            fullWidth
            margin="normal"
          />
          <TextField<FormData>
            name="address_zip"
            type="text"
            label="PLZ"
            margin="normal"
          />
          <TextField<FormData>
            name="address_city"
            type="text"
            label="Stadt"
            margin="normal"
          />
          <TextField<FormData>
            name="uid"
            type="text"
            label="UID"
            fullWidth
            margin="normal"
          />
          <TextField<FormData>
            name="contact_firstname"
            type="text"
            label="Vorname"
            margin="normal"
            required
          />
          <TextField<FormData>
            name="contact_lastname"
            type="text"
            label="Nachname"
            margin="normal"
            required
          />
          <TextField<FormData>
            name="contact_email"
            type="text"
            label="E-Mail"
            fullWidth
            margin="normal"
            required
          />
          <TextField<FormData>
            name="contact_phone"
            type="text"
            label="Telefonnummer"
            fullWidth
            margin="normal"
          />
          {errors && errors.common && (
            <Alert type={AlertType.ERROR}>
              <Typography paragraph color="inherit">
                {errors.common}
              </Typography>
            </Alert>
          )}
          <Button
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
            disabled={loading}
          >
            Speichern
          </Button>
        </form>
      )}
    </PyroForm>
  );
};
