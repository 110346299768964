import React, { useState } from 'react';
import { server } from '../../utils/server';
import { Loader } from '../../components/common/Loader';
import { BaseForm, FormErrors, FormData } from './components/BaseForm';
import { sanitize } from '../../utils/helper';
import ActionFeedback from '../../components/patterns/ActionFeedback';

interface CustomersEditProps {
  data: FormData;
  customerUuid: string;
}

const CustomersEdit: React.FC<CustomersEditProps> = ({
  data,
  customerUuid
}) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<FormErrors | undefined>(undefined);
  const [success, setSuccess] = useState<boolean>(false);
  const [formData, setFormData] = useState<FormData>(data);

  const onSubmit = async (data: FormData) => {
    setLoading(true);
    try {
      const res = await server.put(`/customers/${customerUuid}`, {
        // @ts-ignore
        ...sanitize({
          business_name: data.business_name,
          uid: data.uid,
          address_street: data.address_street,
          address_city: data.address_city,
          address_zip: data.address_zip,
          contact_email: data.contact_email,
          contact_phone: data.contact_phone,
          contact_firstname: data.contact_firstname,
          contact_lastname: data.contact_lastname
        })
      });
      setLoading(false);
      setSuccess(true);
    } catch (error) {
      setLoading(false);
      setFormData(data);
      setError(error);
    }
  };

  const onCloseActionFeedback = () => {
    setSuccess(false);
    setError(undefined);
  };

  return (
    <>
      {isLoading && <Loader active={isLoading} width={100} />}
      <>
        <BaseForm
          onSubmit={onSubmit}
          loading={isLoading}
          errors={error}
          data={formData}
        />
        <ActionFeedback
          message="Stammdaten erfolgreich gespeichert"
          visible={success}
          onClose={onCloseActionFeedback}
          type="success"
        />
        {error && (
          <ActionFeedback
            message={error.common || 'Ein Fehler ist aufgetreten'}
            type="error"
            visible={true}
            onClose={onCloseActionFeedback}
          />
        )}
      </>
    </>
  );
};

export default CustomersEdit;
