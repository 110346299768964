import React from 'react';
import {
  Route,
  RouteComponentProps,
  Switch,
  withRouter
} from 'react-router-dom';
import Dashboard from './dashboard/Dashboard';
import CustomerManager from './customer/CustomerManager';
import EmployeeList from './employee/EmployeeList';
import CustomerDashboard from './customer/CustomerDashboard';
import TopupManager from './topup/TopupManager';
import ProductManager from './product/ProductManager';
import { Checkout } from './shop/Checkout';
import ShopWrapper from './shop/ShopWrapper';
import VoucherManager from './stock/voucher/VoucherManager';
import SimcardManager from './stock/simcard/SimcardManager';
import StockTransactionManager from './stock/stock-transaction/StockTransactionManager';
import OrderWrapper from './orders/OrderWrapper';
import { ShopVoucher } from './shop/ShopVoucher';
import { ShopSimcard } from './shop/ShopSimcard';
import { CustomerBalance } from './customer/CustomerBalance';
import { Invoices } from './shop/Invoices';
import CreditNotesManager from './topup/CreditNotesManager';
import { BalanceTransactionManager } from './customer/BalanceTransactionManager';
import TopupDetails from './topup/TopupDetails';
import CampaignManager from './campaign/CampaignManager';
import { ShopRiskfree } from './riskfree/ShopRiskfree';

export enum AppRoutes {
  DASHBOARD = '/',
  CUSTOMERS = '/customers',
  EMPLOYEE = '/employees',
  PROFILE = '/profile',
  SETTINGS = '/settings',
  TOPUPS = '/topups',
  PRODUCTS = '/products',
  STOCK_VOUCHERS = '/stock-vouchers',
  STOCK_SIMCARDS = '/stock-simcards',
  STOCK_TRANSACTIONS = '/stock-transactions',
  SHOP_VOUCHERS = '/shop/vouchers',
  SHOP_SIMCARDS = '/shop/simcard',
  SHOP_CHECKOUT = '/shop/checkout',
  ORDERS = '/orders',
  BALANCE = '/balance',
  INVOICES = '/invoices',
  CREDITNOTES = '/creditnotes',
  BALANCE_TRANSACTIONS = '/balance-transactions',
  ACCEPT_POLICIES = '/accept-policies',
  CAMPAIGNS = '/campaigns',
  RISKFREE = '/riskfree'
}

class AppRouting extends React.PureComponent<RouteComponentProps<{}>> {
  public render() {
    return (
      <Switch>
        <Route path={AppRoutes.DASHBOARD} exact component={Dashboard} />
        <Route path={AppRoutes.EMPLOYEE} exact component={EmployeeList} />
        <Route path={AppRoutes.CAMPAIGNS} exact component={CampaignManager} />
        <Route path={AppRoutes.CUSTOMERS} exact component={CustomerManager} />
        <Route
          path={`${AppRoutes.CUSTOMERS}/:customerId`}
          component={CustomerDashboard}
        />
        <Route path={AppRoutes.TOPUPS} exact component={TopupManager} />
        <Route path={AppRoutes.PRODUCTS} exact component={ProductManager} />
        <Route
          path={AppRoutes.STOCK_VOUCHERS}
          exact
          component={VoucherManager}
        />
        <Route
          path={AppRoutes.STOCK_SIMCARDS}
          exact
          component={SimcardManager}
        />
        <Route
          path={AppRoutes.STOCK_TRANSACTIONS}
          exact
          component={StockTransactionManager}
        />
        <Route path={AppRoutes.RISKFREE} exact component={ShopRiskfree} />
        <Route path={AppRoutes.SHOP_VOUCHERS} exact component={ShopVoucher} />
        <Route path={AppRoutes.SHOP_SIMCARDS} exact component={ShopSimcard} />
        <Route
          path={AppRoutes.SHOP_CHECKOUT}
          exact
          render={() => (
            <ShopWrapper showCart={false}>
              <Checkout />
            </ShopWrapper>
          )}
        />
        <Route path={AppRoutes.ORDERS} exact component={OrderWrapper} />
        <Route path={AppRoutes.BALANCE} exact component={CustomerBalance} />
        <Route path={AppRoutes.INVOICES} exact component={Invoices} />
        <Route
          path={AppRoutes.CREDITNOTES}
          exact
          component={CreditNotesManager}
        />
        <Route
          path={AppRoutes.BALANCE_TRANSACTIONS}
          exact
          component={BalanceTransactionManager}
        />
        <Route
          path={`${AppRoutes.TOPUPS}/:uuid`}
          exact
          component={TopupDetails}
        />
      </Switch>
    );
  }
}

export default withRouter(AppRouting);
