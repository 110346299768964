import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Typography } from '@material-ui/core';
import Button from '../../../components/primitives/Button';
import { TopupTransaction } from '../types';

interface RemoveDialogProps {
  open: boolean;
  handleClose: (open: boolean) => void;
  handleAgree: () => void;
  transaction?: TopupTransaction;
}

const RemoveDialog: React.FC<RemoveDialogProps> = ({
  open,
  handleClose,
  handleAgree,
  transaction
}) => {
  return (
    <Dialog
      open={open}
      onClose={() => handleClose(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {`Möchtest du die Provision von ${transaction &&
          transaction.customer.business_name} über ${transaction &&
          transaction.amount} löschen? `}
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          variant="body1"
          color="textPrimary"
          id="alert-dialog-description"
        >
          Sollten du diese Provision löschen, wird sie nicht auf das Konto des
          Kundens gutgeschrieben.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => handleClose(false)}
          color="default"
        >
          Abbrechen
        </Button>
        <Button
          variant="outlined"
          onClick={() => handleAgree()}
          color="primary"
          autoFocus
        >
          Jetzt entfernen
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RemoveDialog;
