import React, { useState } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MUISwitch from '@material-ui/core/Switch';
import { PyroField, PyroFormValues } from 'pyro-form';

interface SwitchProps {
  checked: boolean;
  label: string;
  name: string;
}

export const Switch: React.FC<SwitchProps> = ({
  checked = true,
  label,
  name
}) => {
  const [value, setValue] = useState<boolean>(checked);
  return (
    <PyroField<PyroFormValues> name={name}>
      {({ core, meta }) => (
        <FormControlLabel
          control={
            <MUISwitch
              {...core}
              checked={value}
              onChange={(
                _event: React.SyntheticEvent<HTMLInputElement>,
                checked: boolean
              ) => {
                core.onChange(checked);
                setValue(checked);
              }}
              value={value}
              color="primary"
            />
          }
          label={label}
        />
      )}
    </PyroField>
  );
};
