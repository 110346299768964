import React, { useState } from 'react';
import { Loader } from '../../components/common/Loader';
import { server, stringifyQuery } from '../../utils/server';
import Button from '../../components/primitives/Button';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment, { Moment } from 'moment';

interface TopupCreateProps {
  onSuccess: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1)
      }
    },
    btn: {
      margin: theme.spacing(1),
      width: '100%'
    }
  })
);

interface FormData {
  from: Date | null;
  until: Date | null;
}

const InvoicesExport: React.FC<TopupCreateProps> = ({ onSuccess }) => {
  const [error, setError] = useState<string | undefined>(undefined);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<FormData>({
    from: new Date(),
    until: new Date()
  });
  const classes = useStyles();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    try {
      const filter = {
        from: formData.from
          ? moment(formData.from).format('YYYY-MM-DD')
          : undefined,
        until: formData.until
          ? moment(formData.until).format('YYYY-MM-DD')
          : undefined
      };
      const queryString = stringifyQuery(filter);

      const res = await server.get(
        `/invoices/export${queryString && '?' + queryString}`,
        {
          responseType: 'blob'
        }
      );
      setLoading(false);
      const url = window.URL.createObjectURL(res.data);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `invoices-export-${Date.now()}.csv`);
      document.body.appendChild(link);
      link.click();
      onSuccess();
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  return (
    <>
      <Typography variant="h3">Rechnungen exportieren</Typography>
      {isLoading ? (
        <Loader active={isLoading} width={100} />
      ) : (
        <form onSubmit={handleSubmit}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
              margin="normal"
              id="from"
              label="Datum von"
              format="DD.MM.YYYY"
              value={formData.from}
              fullWidth
              onChange={(date: Moment | null) =>
                setFormData({
                  ...formData,
                  from: date ? date.toDate() : null
                })
              }
              KeyboardButtonProps={{
                'aria-label': 'change date'
              }}
            />

            <KeyboardDatePicker
              margin="normal"
              id="until"
              label="Datum bis"
              format="DD.MM.YYYY"
              value={formData.until}
              fullWidth
              onChange={(date: Moment | null) =>
                setFormData({
                  ...formData,
                  until: date ? date.toDate() : null
                })
              }
              KeyboardButtonProps={{
                'aria-label': 'change date'
              }}
            />
          </MuiPickersUtilsProvider>
          <Button
            variant="outlined"
            color="primary"
            type="submit"
            className={classes.btn}
          >
            Jetzt exportieren
          </Button>
        </form>
      )}
    </>
  );
};

export default InvoicesExport;
