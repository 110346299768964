import React, { useState } from 'react';
import Fab from '@material-ui/core/Fab';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '../../theme';
import AddIcon from '@material-ui/icons/Add';
import { AuthConsumer } from '../auth/AuthContext';
import { UserRole } from '../auth/types';
import ActionFeedback from '../../components/patterns/ActionFeedback';
import ActionDrawer from '../../components/patterns/ActionDrawer';
import CustomerCreate from './CustomersCreate';
import CustomerList from './CustomersList';
import { SimcardAssign } from '../stock/simcard/SimcardAssign';

const useStyles = makeStyles((theme: Theme) => ({
  fab: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  }
}));

enum CustomerAction {
  ASSIGN = 'assign',
  ADD = 'add'
}

const successMessages = new Map();
successMessages.set(
  CustomerAction.ASSIGN,
  'Die Simkarten wurde erfolgreich dem Kunden zugewiesen.'
);
successMessages.set(CustomerAction.ADD, 'Der Kunde wurde erfolgreich angelegt');

const CustomerManager: React.FC = () => {
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [drawerContent, setDrawerContent] = useState<CustomerAction>();
  const [activeCustomer, setActiveCustomer] = useState<string | undefined>(
    undefined
  );
  const [success, setSuccess] = useState<boolean>(false);
  const [refreshData, setRefreshData] = useState<boolean>(true);
  const classes = useStyles();

  const toggleDrawer = (content?: CustomerAction) => {
    setDrawerOpen(!drawerOpen);
    setDrawerContent(content);
  };
  const onCloseActionSuccess = () => setSuccess(false);
  const assignSimcards = (customerUuid: string) => {
    setDrawerOpen(true);
    setDrawerContent(CustomerAction.ASSIGN);
    setActiveCustomer(customerUuid);
  };

  const onSuccess = async () => {
    setDrawerOpen(false);
    setSuccess(true);
    setRefreshData(true);
  };

  return (
    <AuthConsumer>
      {({ user }) => (
        <>
          <CustomerList
            refreshData={refreshData}
            setRefreshData={setRefreshData}
            assignSimcards={assignSimcards}
          />
          {user.role === UserRole.ADMIN && (
            <Fab
              className={classes.fab}
              color="secondary"
              onClick={() => toggleDrawer(CustomerAction.ADD)}
            >
              <AddIcon />
            </Fab>
          )}
          <ActionDrawer open={drawerOpen} onClose={toggleDrawer} width={470}>
            {drawerContent === CustomerAction.ADD && (
              <CustomerCreate onSuccess={onSuccess} />
            )}
            {drawerContent === CustomerAction.ASSIGN && (
              <SimcardAssign
                onSuccess={onSuccess}
                selectedCustomer={activeCustomer}
              />
            )}
          </ActionDrawer>
          <ActionFeedback
            message={successMessages.get(drawerContent)}
            visible={success}
            onClose={onCloseActionSuccess}
            type="success"
          />
        </>
      )}
    </AuthConsumer>
  );
};

export default CustomerManager;
